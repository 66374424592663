import { setUser as sentrySetUser } from '@sentry/react';
import { useCallback, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useSWR from 'swr';

import { Loading } from 'SRC/components/Loading/Loading';
import { canOverrideEnvVariables, OVERRIDABLE_ENV_VARS } from 'SRC/config';
import {
  ACCESS_TOKEN_ID,
  fetchUser,
  SCOPES_ID,
  USER_ME,
} from 'SRC/fetch/api/users';
import { FetchError } from 'SRC/fetch/fetch';
import { sentryErrorCapture } from 'SRC/fetch/sentryErrorCapture';
import { useClearSWRCache } from 'SRC/hooks/useClearSWRCache';
import { useLdClientIdentify } from 'SRC/hooks/useFeatureFlags/useLdClientIdentify';
import { IdentityContext } from 'SRC/hooks/useIdentity/context/IdentityContext';
import {
  canDo,
  getCompany,
  isTwoFactorProtected,
} from 'SRC/hooks/useIdentity/context/utils';
import { useLocalStorage } from 'SRC/hooks/useStorage';

const locationsNotToRedirect = new Set([
  'join',
  'redirecting',
  'reset-password',
  'forgot-password',
  'login',
  'signup',
  'oauth',
  'sso',
]);

const shouldRedirect = (pathname: string) =>
  !locationsNotToRedirect.has(pathname.split('/')[1]);

export function IdentityProvider({ children }) {
  const clearSWRCache = useClearSWRCache();
  const [token, setToken, removeToken] = useLocalStorage<string | null>(
    ACCESS_TOKEN_ID,
    null,
    true,
  );
  const [scopes, setScopes, removeScopes] = useLocalStorage<string[] | null>(
    SCOPES_ID,
    null,
  );

  const [location] = useState(useLocation());
  const navigate = useNavigate();
  const doneRedirect = useRef(false);

  const logOut = useCallback(() => {
    removeToken();
    removeScopes();
    // Clears whole session storage used by useSessionStorage
    sessionStorage.clear();
    if (canOverrideEnvVariables) {
      Object.keys(localStorage)
        .filter((key) => {
          return !OVERRIDABLE_ENV_VARS.some((variable) => variable === key);
        })
        .forEach((key) => localStorage.removeItem(key));
    } else {
      localStorage.clear();
    }
    clearSWRCache();
    sentrySetUser(null);
  }, [clearSWRCache, removeScopes, removeToken]);

  const { isLoading, data, mutate } = useSWR(
    token && scopes?.includes('profile:read') ? [USER_ME, token] : null,
    fetchUser,
    {
      onSuccess: ({
        firstName,
        lastName,
        sessionPrimerAccountId,
        email,
        accountRoles,
      }) => {
        sentrySetUser({
          id: sessionPrimerAccountId,
          email,
          accountRoles,
          username: `${firstName} ${lastName}`,
        });

        if (doneRedirect.current) {
          return;
        }
        doneRedirect.current = true;
        if (shouldRedirect(location.pathname)) {
          navigate(location, { replace: true });
        }
      },
      onError: sentryErrorCapture((err: FetchError) => {
        // Log out if the error is 401
        if (err.status === 401) {
          logOut();
        }
      }),
    },
  );

  const user = data ?? null;

  const company = getCompany(user);

  useLdClientIdentify(user, company);

  const logIn = useCallback(
    async (token: string, scopes: string[] = []) => {
      setToken(token);
      setScopes(scopes);
    },
    [setScopes, setToken],
  );

  const logTwoFactorIn = useCallback(
    (token: string, scopes: string[] = []) => {
      setToken(token);
      setScopes(scopes);
    },
    [setScopes, setToken],
  );

  const logTransientIn = useCallback(
    (token: string, scopes: string[] = []) => {
      setToken(token);
      setScopes(scopes);
    },
    [setScopes, setToken],
  );

  const canDoBound = useCallback(
    (expression: any) => canDo(scopes, user?.accountRoles ?? null, expression),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [scopes, user?.id],
  );

  return (
    <IdentityContext.Provider
      value={{
        loggedIn: !!user,
        logIn,
        logTwoFactorIn,
        logTransientIn,
        logOut,
        isTwoFactorProtected,
        user,
        company,
        canDo: canDoBound,
        refreshUser: mutate,
        token: token ?? null,
        scopes: scopes ?? null,
      }}
    >
      {isLoading ? <Loading page /> : children}
    </IdentityContext.Provider>
  );
}
