import { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components';

import Space, { OfType } from '../layout/Space';
import Theme from '../theme';
import { Label } from '../typography/Text';
import Title from '../typography/Title';

export type Style =
  | 'zero'
  | 'extra-compact'
  | 'compact'
  | 'regular'
  | 'breathable'
  | 'listing'
  | 'pill'
  | 'pill-compact';
export type Corners = 'small' | 'regular' | 'none' | 'pill';
export type Elevation = 'flat' | 'level1' | 'pill';
export type TitleStyl = 'box' | 'section';

type OwnProps = {
  children?: any;
  styl?: Style;
  corners?: Corners;
  elevation?: Elevation;
  onClick?: JSX.IntrinsicElements['div']['onClick'];
  interactive?: boolean;
  style?: CSSProperties;

  /**
   * Useful mainly for `elevation: flat` where on hover the interaction can differ greatly
   * Only applied if `onClick` or `interactive` is set
   */
  customInteractionCSS?: string;

  stripeColor?: string;
  bgColor?: string;
  borderColor?: string | false;
};

const defaultOwnProps: OwnProps = {
  styl: 'regular',
  corners: 'regular',
  elevation: 'level1',
  bgColor: Theme.colorLight,
  borderColor: 'transparent',
};

const Root = styled.div<OwnProps & { _elevation?: string }>`
  display: block;
  position: relative;
  overflow: hidden;

  ${(props) => props.bgColor && `background: ${props.bgColor};`};

  ${(props) =>
    props.styl === 'extra-compact' &&
    `padding: ${Theme.paddingColumn4} ${Theme.paddingRow12};`};

  ${(props) =>
    props.styl === 'compact' &&
    `padding: ${Theme.paddingColumn8} ${Theme.paddingRow12};`};

  ${(props) =>
    props.styl === 'regular' &&
    `padding: ${Theme.paddingColumn12} ${Theme.paddingRow12};`};

  ${(props) =>
    props.styl === 'pill' &&
    `padding: ${Theme.paddingColumn8} ${Theme.paddingRow20};`};

  ${(props) =>
    props.styl === 'pill-compact' &&
    `padding: ${Theme.paddingColumn4} ${Theme.paddingRow12};`};

  ${(props) =>
    props.styl === 'breathable' &&
    `padding: ${Theme.paddingColumn20} ${Theme.paddingRow20};`};

  ${(props) =>
    props.styl === 'listing' &&
    `padding: ${Theme.paddingColumn24} ${Theme.paddingRow24};`};

  ${(props) => props.styl === 'zero' && `padding: 0;`};

  ${(props) =>
    props.corners === 'small' &&
    `border-radius: ${Theme.roundedCornersSmall};`};

  ${(props) =>
    props.corners === 'regular' &&
    `border-radius: ${Theme.roundedCornersRegular};`};

  ${(props) =>
    props.corners === 'pill' && `border-radius: ${Theme.roundedCornersPill};`};

  ${(props) => props.borderColor && `border: 1px solid ${props.borderColor};`};

  ${(props) =>
    props._elevation === 'level1' && `box-shadow: ${Theme.shadowCard};`}
  ${(props) =>
    props._elevation === 'pill' && `box-shadow: ${Theme.shadowCardPill};`}

  ${(props) =>
    (props.onClick || props.interactive) &&
    `
    cursor: pointer;
    
    &:hover {
      ${props.customInteractionCSS ?? 'box-shadow: none;'};
    }
    `};

  ${(props) =>
    (props.onClick || props.interactive) &&
    props._elevation === 'flat' &&
    `
    &:hover {
      ${props.customInteractionCSS ?? ''};
    }
    `};
`;

const Stripe = styled.div<{ bg?: string }>`
  display: block;
  width: 3px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  ${(props) => `background: ${props.bg};`};
`;

export default function Card(props: OwnProps) {
  const {
    children,
    stripeColor,
    elevation = defaultOwnProps.elevation,
    styl = defaultOwnProps.styl,
    corners = defaultOwnProps.corners,
    bgColor = defaultOwnProps.bgColor,
    borderColor = defaultOwnProps.borderColor,
    ...otherProps
  } = props;

  return (
    <Root
      {...otherProps}
      _elevation={elevation}
      styl={styl}
      corners={corners}
      bgColor={bgColor}
      borderColor={borderColor}
    >
      {stripeColor && <Stripe bg={stripeColor} />}
      {children}
    </Root>
  );
}

export function ContentCard(props: {
  children: ReactNode;
  styl?: Style;
  title?: ReactNode;
  subtitle?: ReactNode;
  titleStyl?: TitleStyl;
  titlePosition?: 'outside' | 'inside';
  disabled?: boolean;
  beforeTitle?: ReactNode;
  afterTitle?: ReactNode;
  style?: CSSProperties;
  titleSpace?: OfType;
}) {
  const {
    children,
    styl,
    title,
    subtitle,
    titleStyl,
    titlePosition = 'inside',
    beforeTitle,
    afterTitle,
    disabled,
    titleSpace = 'col12',
    ...otherProps
  } = props;

  const titleComponent =
    title || beforeTitle || afterTitle ? (
      <>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            alignContent: 'center',
          }}
        >
          {!!beforeTitle && beforeTitle}
          {title && (
            <Title
              styl={titleStyl || 'section'}
              sentiment={disabled ? 'labelIco' : undefined}
              wrap
            >
              {title}
            </Title>
          )}
          {!!afterTitle && afterTitle}
        </div>
        {subtitle && (
          <div>
            <Space of='col4' />
            <Label>{subtitle}</Label>
          </div>
        )}
        <Space of={titleSpace} />
      </>
    ) : null;

  return (
    <div style={{ width: '100%' }}>
      {titlePosition === 'outside' && titleComponent}
      <Card
        styl={styl || 'regular'}
        corners='regular'
        elevation='level1'
        {...otherProps}
      >
        {titlePosition === 'inside' && titleComponent}
        {children}
      </Card>
    </div>
  );
}

export function SectionCard(props: {
  children: ReactNode;
  styl?: Style;
  title?: ReactNode;
  titleStyl?: 'box' | 'section';
  disabled?: boolean;
  beforeTitle?: ReactNode;
  afterTitle?: ReactNode;
  subtitle?: ReactNode;
  titleSpace?: OfType;
  style?: CSSProperties;
}) {
  const {
    children,
    styl,
    title,
    titleStyl,
    beforeTitle,
    afterTitle,
    subtitle,
    disabled,
    titleSpace = 'col12',
    ...otherProps
  } = props;

  const titleComponent =
    title || beforeTitle || afterTitle ? (
      <>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            alignContent: 'center',
          }}
        >
          {!!beforeTitle && beforeTitle}
          {title && (
            <Title
              styl={titleStyl || 'section'}
              sentiment={disabled ? 'labelIco' : undefined}
              wrap
            >
              {title}
            </Title>
          )}
          {!!afterTitle && afterTitle}
        </div>
        {subtitle && (
          <>
            <Space of='col4' />
            <Label>{subtitle}</Label>
          </>
        )}
        <Space of={titleSpace} />
      </>
    ) : null;

  const style = otherProps.style ?? {};

  if (!style.overflow) {
    style.overflow = 'visible';
  }

  return (
    <div>
      {titleComponent}
      <Card
        styl={styl || 'zero'}
        corners='none'
        elevation='flat'
        bgColor='transparent'
        {...otherProps}
        style={style}
      >
        {children}
      </Card>
    </div>
  );
}

export const InnerInverseCard = (props: {
  className?: string;
  children: ReactNode;
  styl?: Style;
  style?: CSSProperties;
  onClick?: () => void;
}) => <Card {...props} elevation='flat' bgColor={Theme.colorBackground} />;

export function Pill(props: Omit<OwnProps, 'corners' | 'elevation' | 'styl'>) {
  return (
    <Card
      styl='pill'
      {...props}
      elevation='pill'
      corners='pill'
      style={{ overflow: 'visible' }}
    />
  );
}
