import { useSWRConfig } from 'swr';

// hack to fix skipped useSWRInfinite cache clear https://github.com/vercel/swr/issues/2281
export const useClearSWRCache = () => {
  const { mutate, cache } = useSWRConfig();

  return async () => {
    await Promise.all(
      [...cache.keys()].map((key) =>
        mutate(key, undefined, { revalidate: false }),
      ),
    );
  };
};
