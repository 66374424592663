import { Toast } from '@primer-io/goat';
import { ReactNode } from 'react';

import { ToastContainer } from 'SRC/components/Toast';

interface ToastProviderProps {
  children: ReactNode;
}

export const ToastProvider = ({ children }: ToastProviderProps) => (
  <Toast.Provider duration={5000}>
    {children}
    <ToastContainer />
    <Toast.Viewport />
  </Toast.Provider>
);
