import { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';

const RootStyled = styled.main`
  width: 100%;
  overflow: auto;
  padding-bottom: 80px;
`;

const PageWrapper: FC<PropsWithChildren> = ({ children }) => {
  return <RootStyled id='component-PageLayout--content'>{children}</RootStyled>;
};

export default PageWrapper;
