import { isBoolean } from 'lodash-es';
import { ReactNode } from 'react';
import { ValidationRule } from 'react-hook-form';

import Labelled from 'LEGACY/components/Labelled';
import { Label } from 'LEGACY/typography/Text';

type OwnProps = {
  label: string;
  htmlFor?: string;
  onClick?: () => void;
  disabled?: boolean;
  readOnly?: boolean;
  tooltipContent?: string;
  required?: string | ValidationRule<boolean>;
  children?: ReactNode;
  labelFwdProps?: Record<string, unknown>;
  labelPosition?:
    | 'top left'
    | 'top right'
    | 'center left'
    | 'center right'
    | 'bottom left'
    | 'bottom right';
  layout?: 'row' | 'col';
  space?: 'col8' | 'col12' | 'col24' | 'row8' | 'row12' | 'row24';
};

export default function FormItem(props: OwnProps) {
  const {
    label,
    tooltipContent,
    required,
    children,
    disabled,
    htmlFor,
    readOnly,
    ...otherProps
  } = props;

  let requiredLabel;
  if (isBoolean(required) || typeof required === 'string') {
    requiredLabel = <Label>{required ? 'Required' : 'Optional'}</Label>;
  }

  const disabledMerged = disabled ?? readOnly;

  return (
    <Labelled
      label={label}
      styl='formLabel'
      labelWidth='100%'
      tooltipContent={tooltipContent}
      extraContent={requiredLabel}
      {...otherProps}
      labelFwdProps={{
        ...(otherProps.labelFwdProps ?? {}),
        disabled: disabledMerged,
        interactive: true,
        interactiveStyl: 'none',
        htmlFor,
      }}
    >
      {children}
    </Labelled>
  );
}
