import {
  Button,
  Card,
  Checkbox,
  Field,
  Form,
  IconButton,
  Input,
  Label,
  Validation,
} from '@primer-io/goat';
import { Eye, EyeSlash } from '@primer-io/goat-icons';
import { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { linkTo, pathTo } from 'SRC/components/RoutesMain/routing';
import { appEnv } from 'SRC/config';
import { useIdentity, isTwoFactorProtected } from 'SRC/hooks/useIdentity';
import { useLocalStorage } from 'SRC/hooks/useStorage';

import { useCreateSession } from '../api/auth';
import { Header } from '../components';
import { getRedirect } from '../utils';

const StyledCard = styled(Card)`
  width: 360px;
`;

const CenterLink = styled(Link)`
  text-align: center;
`;

const isLocal = appEnv === 'local';
const defaultPassword = isLocal ? 'Password1234' : '';

type FormState = {
  password: string;
  remember: boolean;
  username: string;
};

type LastLogin = {
  at: string;
  username: string;
};

export function LoginPassword() {
  const navigate = useNavigate();
  const location = useLocation();

  const [showPassword, setShowPassword] = useState(false);
  const [lastLogin, setLastLogin] = useLocalStorage<LastLogin | null>(
    'last-login',
  );
  const { logIn, logTwoFactorIn } = useIdentity();

  const {
    trigger: createSession,
    error,
    isMutating,
  } = useCreateSession({
    onSuccess({ accessToken, scopes }) {
      if (isTwoFactorProtected(scopes)) {
        logTwoFactorIn(accessToken, scopes);
        return navigate(pathTo.loginMfa);
      }

      logIn(accessToken, scopes);
      navigate(getRedirect(location.search) || '/');
    },
  });

  if (!location.state?.username) {
    navigate(pathTo.login, { replace: true });
    return null;
  }

  return (
    <StyledCard>
      <Header>Welcome back</Header>

      <Form
        onSubmit={(_, values: FormState) => {
          const { password, remember, username } = values;
          setLastLogin(remember ? { at: new Date().toJSON(), username } : null);
          createSession({ username, password });
        }}
      >
        <Field>
          <Label>
            Email address
            <Input.Root>
              <Input.Text
                data-testid='input-username'
                defaultValue={location.state.username}
                disabled
                name='username'
                required
                type='email'
              />
            </Input.Root>
          </Label>
        </Field>

        <Field>
          <Label>
            Password
            <Input.Root>
              <Input.Text
                autoComplete='current-password'
                autoFocus
                data-testid='input-password'
                defaultValue={defaultPassword}
                name='password'
                required
                type={showPassword ? 'text' : 'password'}
              />
              <IconButton
                aria-label={`${showPassword ? 'Hide' : 'Show'} password`}
                icon={showPassword ? EyeSlash : Eye}
                onClick={() => setShowPassword((s) => !s)}
              />
            </Input.Root>
          </Label>
          <Validation if='valueMissing'>Required</Validation>
          {!!error && (
            <Validation data-testid='invalid-no-match' sentiment='negative'>
              The email address and password do not match
            </Validation>
          )}
          <Link to={linkTo.forgotPassword()}>Forgot password</Link>
        </Field>

        <Label>
          <Checkbox
            defaultChecked={
              !!lastLogin?.at &&
              +new Date() - +new Date(lastLogin.at) < 30 * 24 * 60 * 60 * 1000
            }
            name='remember'
          />
          Remember me for 30 days
        </Label>

        <Button
          data-testid='button-submit'
          disabled={isMutating}
          loading={isMutating}
          type='submit'
        >
          Log in
        </Button>
      </Form>

      <CenterLink to={linkTo.login()}>Not you? Go back</CenterLink>
    </StyledCard>
  );
}
