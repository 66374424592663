import { Dialog, Icon, Sidebar, Table } from '@primer-io/goat';
import { ChevronRight, Refresh } from '@primer-io/goat-icons';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useSwitchSession } from 'MODULES/auth/api/auth';
import { Company, useIdentity } from 'SRC/hooks/useIdentity';

import styles from '../AppLayout.module.scss';

interface AppSwitchAccountProps {
  accounts: Company[];
}
export const AppSwitchAccount: FC<
  React.PropsWithChildren<AppSwitchAccountProps>
> = ({ accounts }) => {
  const [showSwitchAccount, setShowSwitchAccount] = useState(false);
  const navigate = useNavigate();
  const { logIn } = useIdentity();
  const { trigger: switchAccount } = useSwitchSession({
    onSuccess(response) {
      logIn(response.accessToken, response.scopes);
      setShowSwitchAccount(false);
      navigate({ pathname: '/' });
    },
  });
  return (
    <Dialog.Root open={showSwitchAccount} onOpenChange={setShowSwitchAccount}>
      <Dialog.Trigger as={Sidebar.Item}>
        <Icon as={Refresh} />
        Switch account
      </Dialog.Trigger>
      <Dialog.Content closeLabel='Close Dialog'>
        <Dialog.Header>Choose an account</Dialog.Header>
        <Dialog.Body>
          <Table.Root interactive hover className={styles.appSwitchTable}>
            <Table.Head>
              <Table.Row>
                <Table.Cell>Merchant name</Table.Cell>
                <Table.Cell>Account ID</Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {accounts.map((el) => (
                <Table.Row
                  key={el.id}
                  as='a'
                  onClick={() => switchAccount({ id: el.id })}
                >
                  <Table.Cell>{el.companyName}</Table.Cell>
                  <Table.Cell>{el.id}</Table.Cell>
                  <Table.Cell>
                    <Icon as={ChevronRight} />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table.Root>
        </Dialog.Body>
      </Dialog.Content>
    </Dialog.Root>
  );
};
