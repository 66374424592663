import { Button, Card, Field, Input, Label, space } from '@primer-io/goat';
import styled from 'styled-components';

import { Header } from '../components';

const P = styled.p`
  margin: 0;
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${space(2)};
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${space(4)};
`;

type Props = {
  companyName?: string;
  email: string;
  inviter: string;
  redirectUrl: string;
};

export function JoinSSO({ companyName, email, inviter, redirectUrl }: Props) {
  return (
    <Card>
      <Title>
        <Header>Welcome to Primer</Header>
        <P>
          {inviter} has invited you to join {companyName || 'their team'} on
          Primer
        </P>
      </Title>

      <Info>
        <Field style={{ placeItems: 'stretch' }}>
          <Label>
            Email address
            <Input.Root>
              <Input.Text
                data-testid='input-username'
                defaultValue={email}
                disabled
                name='username'
              />
            </Input.Root>
          </Label>
        </Field>
        <Button as='a' href={redirectUrl}>
          Create account
        </Button>
      </Info>
    </Card>
  );
}
