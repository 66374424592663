import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { toast } from 'SRC/components/Toast';
import { useIdentity } from 'SRC/hooks/useIdentity';

import { useConfirmSession, useCreateSession } from '../api/auth';
import {
  usePostResetPassword,
  useQueryForgotPasswordToken,
} from '../api/password';
import ResetPasswordCard from '../components/forgot_password/cards/ResetPasswordCard';
import { LoginTwoFactorCard } from '../components/login_invite/cards/LoginTwoFactorCard';

export function ResetPassword() {
  //////////////////////////////////////////////////////////////////////////////////////////////////
  // Basics
  const { loggedIn, logOut, logIn, logTwoFactorIn, isTwoFactorProtected } =
    useIdentity();
  const { token } = useParams<{ token: string }>();
  const navigate = useNavigate();
  const hasLoggedOut = useRef(false);
  const mfaTries = useRef(0);
  const totp = useRef('');
  const [needsNewTwoFactor, setNeedsNewTwoFactor] = useState(false);

  const afterLogIn = () => {
    navigate({ pathname: '/' });
    toast.informative({ description: 'Your password has been changed' });
  };
  const unsuccessfullAutoLogin = () => {
    navigate({ pathname: '/login' });
    toast.informative({ description: 'Your password has been changed' });
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////
  // Queries & Mutations
  const { data: dataToken, error: dataError } = useQueryForgotPasswordToken(
    token!,
  );
  const statusToken = dataToken ? 'success' : dataError ? 'error' : '';
  const error = dataError
    ? {
        httpCode: dataError?.status,
        primerErrorId: dataError?.payload?.errorId,
      }
    : null;

  const { mfaEnabledOnAccount, userEmail } = dataToken ?? {};

  // Reset password mutation
  const {
    trigger,
    error: resetError,
    isMutating: resetMutating,
  } = usePostResetPassword();
  const statusReset = resetError ? 'error' : resetMutating ? 'loading' : '';

  // MFA login
  const { trigger: confirmSession, error: twoFactorError } = useConfirmSession({
    onSuccess: async (response) => {
      logIn(response.accessToken, response.scopes);
      afterLogIn();
    },
    onError: () => {
      mfaTries.current = mfaTries.current + 1;
    },
  });

  // Base login
  const { trigger: createSession } = useCreateSession({
    async onSuccess(response) {
      if (isTwoFactorProtected(response.scopes)) {
        logTwoFactorIn(response.accessToken, response.scopes);

        // Let's try to use the MFA code given during password reset for login as well
        try {
          await confirmSession({
            totp: totp.current,
          });
        } catch {
          // If it fails, promp for new MFA code
          setNeedsNewTwoFactor(true);
          return;
        }
      } else {
        logIn(response.accessToken, response.scopes);
        afterLogIn();
      }
    },
    onError: unsuccessfullAutoLogin,
  });

  //////////////////////////////////////////////////////////////////////////////////////////////////
  // Callbacks
  const onSubmit = async (values) => {
    if (!userEmail) {
      throw new Error('User email is not defined');
    }

    const { password, mfaTotp } = values;

    totp.current = mfaTotp;

    await trigger({
      newPassword: password,
      mfaTotp,
      token: token!,
    });
    await createSession({
      username: userEmail,
      password,
    });
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////
  // Logout current user if any
  useEffect(() => {
    if (loggedIn && !hasLoggedOut.current) {
      hasLoggedOut.current = true;
      logOut();
    }
  });

  //////////////////////////////////////////////////////////////////////////////////////////////////
  // Render

  if (needsNewTwoFactor) {
    return (
      <LoginTwoFactorCard
        formProps={{
          onSubmit: confirmSession,
          externalError:
            !!twoFactorError && mfaTries.current !== 1
              ? 'Verification failed, your code may be invalid'
              : undefined,
        }}
        extraContent='Please provide a new code in order to login.'
      />
    );
  }

  if (statusToken === 'error') {
    if (
      error?.httpCode === 400 ||
      error?.primerErrorId === 'PasswordResetTokenExpired'
    ) {
      return <ResetPasswordCard status='requestExpired' />;
    }

    if (
      error?.httpCode === 410 ||
      error?.primerErrorId === 'PasswordResetTokenUsed'
    ) {
      return <ResetPasswordCard status='requestAlreadyAccepted' />;
    }

    return <ResetPasswordCard status='requestNotFound' />;
  }

  if (statusToken === 'success') {
    return (
      <ResetPasswordCard
        status='requestFound'
        formProps={{
          onSubmit,
          email: userEmail,
          mfaEnabled: mfaEnabledOnAccount,
          loading: statusReset === 'loading',
          externalError:
            statusReset === 'error'
              ? 'Something went wrong, please try again'
              : undefined,
        }}
      />
    );
  }

  return <ResetPasswordCard status='loadingRequest' />;
}
