import { ReactNode } from 'react';

export const contactStrings = {
  heading: 'Contact Support',
  close: 'Close',
  link: 'Support Portal',
  buttonLink: 'Primer’s Support Portal',
  linkUrl: 'https://primerapi.atlassian.net/servicedesk/customer/portal/11',
  paragraph1: (link: ReactNode) => (
    <>Navigate to our {link} to submit your inquiry</>
  ),
  paragraph2:
    'If you don’t have access, please contact your account administrator for assistance.',
  error: (link: ReactNode) => (
    <span>
      It looks like something went wrong, please try again. If the problem
      persists, please raise a ticket on our {link}
    </span>
  ),
};
