import { MutationFetcher } from 'swr/mutation';

import { RoleId } from 'MODULES/auth/const/roles';
import { accountsApiUrl } from 'SRC/config';
import { del, get, post } from 'SRC/fetch/fetch';

export const INVITATIONS_PATH = '/invitations';
export const INVITATION_PATH = '/invitation-summaries';

export type Invite = {
  createdAt: string;
  email: string;
  expirationDate: string;
  id: string;
  inviterUserId: string;
  inviteeDetails?: {
    firstName?: string;
    lastName?: string;
    createdVia?: string;
  };
  inviteeRefundLimits: {
    daily: number;
    weekly: number;
  };
  inviterDetails: unknown;
  primerAccountDetails: {
    companyName: string;
    companyColor: string;
    companyLogoUrl: string;
  };
  primerAccountId: string;
  status: 'ACCEPTED' | 'PENDING' | 'REVOKED';
  userRoles: RoleId[];
};

export type InviteDetail = {
  primerAccountId: string;
  inviteCode: string;
  email: string;
  nextStep: string;
  inviteeDetails: {
    firstName?: string;
    lastName?: string;
    createdVia?: string;
  };
  inviterDetails: {
    firstName?: string;
    lastName?: string;
  };
  primerAccountDetails: {
    companyName: string;
    companyColor: string;
    companyLogoUrl: string;
  };
  type: string;
};

export type QueryInvitesResponse = {
  data: Invite[];
};

type postCreateInvitationPayload = {
  email: string;
  firstName?: string;
  lastName?: string;
  refundLimits: {
    currencyCode: string;
    daily?: number | undefined;
    weekly?: number | undefined;
  };
  userRoles: RoleId[];
};

type postAcceptInviteNewUserPayload = {
  inviteCode: string;
  payload: {
    firstName?: string;
    lastName?: string;
    password: string;
    passwordConfirmation: string;
  };
};

type postAcceptInviteExistingUserPayload = {
  inviteCode: string;
};

export type postAcceptInviteResponse = {
  id: string;
  primerAccountId: string;
  email: string;
  expirationDate: string;
  status: string;
  inviteeDetails: {
    firstName: string;
    lastName: string;
    createdVia: string;
  };
  inviterDetails: {
    firstName: string;
    lastName: string;
  };
  primerAccountDetails: {
    companyName: string;
    companyColor: string;
    companyLogoUrl: string;
  };
  userRoles: RoleId[];
  inviteeRefundLimits: {
    daily: number;
    weekly: number;
  };
  inviteeUserId: string;
  inviterUserId: string;
  acceptedAt: string;
  revokedByUserId: string;
  revokedAt: string;
  type: string;
  createdAt: string;
  updatedAt: string;
};

export const getInvitations = () => {
  return get<QueryInvitesResponse>(
    accountsApiUrl + INVITATIONS_PATH,
    {},
    { auth: true },
  );
};

export const getInvitation = (id: string) => {
  return get<InviteDetail>(
    accountsApiUrl + INVITATION_PATH + `/${id}`,
    {},
    { auth: false },
  );
};

export const postCreateInvitation: MutationFetcher<
  unknown,
  string,
  postCreateInvitationPayload
> = (_, { arg: body }) => {
  return post(
    accountsApiUrl + INVITATIONS_PATH,
    { body: JSON.stringify(body) },
    { auth: true },
  );
};

export const postAcceptInviteNewUser: MutationFetcher<
  postAcceptInviteResponse,
  string[],
  postAcceptInviteNewUserPayload
> = (_, { arg: { inviteCode, payload } }) => {
  return post(
    accountsApiUrl + `/invitation-summaries/${inviteCode}/accept`,
    { body: JSON.stringify(payload) },
    { auth: false },
  );
};

export const postAcceptInviteExistingUser: MutationFetcher<
  postAcceptInviteResponse,
  string[],
  postAcceptInviteExistingUserPayload
> = (_, { arg: { inviteCode } }) => {
  return post(
    accountsApiUrl + `/invitation-summaries/${inviteCode}/accept`,
    {},
    { auth: true },
  );
};

export const delRevokeInvite: MutationFetcher<unknown, string, string> = (
  _,
  { arg: id },
) => {
  return del(accountsApiUrl + `/invitations/${id}`, {}, { auth: true });
};
