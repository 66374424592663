import { withSentryReactRouterV6Routing } from '@sentry/react';
import { FC, lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import Oauth from 'MODULES/apps/screens/Oauth';
import { ForgotPassword } from 'MODULES/auth/scenes/ForgotPassword';
import { JoinRoot } from 'MODULES/auth/scenes/JoinRoot';
import { Login } from 'MODULES/auth/scenes/Login';
import { LoginAccounts } from 'MODULES/auth/scenes/LoginAccounts';
import { LoginMfa } from 'MODULES/auth/scenes/LoginMfa';
import { LoginPassword } from 'MODULES/auth/scenes/LoginPassword';
import { LogOut } from 'MODULES/auth/scenes/LogOut';
import { OAuth } from 'MODULES/auth/scenes/OAuth';
import { ResetPassword } from 'MODULES/auth/scenes/ResetPassword';
import { SSO } from 'MODULES/auth/scenes/SSO';
import { AppLayout } from 'SRC/components/AppLayout/AppLayout';
import { AuthLayout } from 'SRC/components/AuthLayout/AuthLayout';
import { NavRoutes } from 'SRC/components/NavRoutes/NavRoutes';
import {
  LoggedAndAuthorized,
  NotLogged,
} from 'SRC/components/Router/IsAuthorized';
import { useCurrencyInformation } from 'SRC/hooks/useCurrencyInformation';
import { useFeatureFlags } from 'SRC/hooks/useFeatureFlags';
import { useIdentity } from 'SRC/hooks/useIdentity';
import { permissions } from 'SRC/permissions';
import { useUserAnalytics } from 'UTILS/analytics';

import { generateSplatRoute, linkTo, pathTo } from './routing';
import { Loading } from '../Loading/Loading';

const LoginInternal = lazy(() =>
  import('MODULES/auth/scenes/LoginInternal').then((module) => ({
    default: module.LoginInternal,
  })),
);
const CheckoutRoot = lazy(() => import('MODULES/checkout'));
const DevelopersRoot = lazy(() => import('MODULES/developers'));
const HomeDashboard = lazy(() =>
  import('MODULES/home').then((module) => ({ default: module.HomeDashboard })),
);
const PaymentServiceRoot = lazy(() =>
  import('MODULES/home/scenes/PaymentServiceRoot').then((module) => ({
    default: module.PaymentServiceRoot,
  })),
);

const PrimerEtlRoot = lazy(() =>
  import('MODULES/primerEtl').then((module) => ({
    default: module.PrimerEtl,
  })),
);
const IntegrationsRoot = lazy(() =>
  import('MODULES/integrations').then((module) => ({
    default: module.IntegrationsRoot,
  })),
);
const InternalToolsRoot = lazy(() => import('MODULES/internalTools'));
const ObservabilityRoot = lazy(() =>
  import('MODULES/observability').then((module) => ({
    default: module.Observability,
  })),
);
const MonitorsRoot = lazy(() =>
  import('MODULES/monitors').then((module) => ({
    default: module.MonitorsRoot,
  })),
);
const PaymentsRoot = lazy(() =>
  import('MODULES/payments').then((module) => ({
    default: module.PaymentsRoot,
  })),
);
const Reconciliation = lazy(() =>
  import('MODULES/reconciliation').then((module) => ({
    default: module.Reconciliation,
  })),
);

const AccountSettings = lazy(() => import('MODULES/settings'));
const WorkflowsRoot = lazy(() => import('MODULES/workflows'));
const Changelog = lazy(() => import('MODULES/changelog'));

const SentryRoutes = withSentryReactRouterV6Routing(Routes);

export const RoutesMain: FC<React.PropsWithChildren<unknown>> = () => {
  const { user, loggedIn } = useIdentity();
  const { reconciliation: isReconciliationEnabled, primerEtl } =
    useFeatureFlags();
  useUserAnalytics();
  const { isLoading } = useCurrencyInformation();

  if (isLoading) {
    return <Loading page />;
  }

  return (
    <SentryRoutes>
      <Route
        element={
          loggedIn ? <AppLayout navRoutes={<NavRoutes />} /> : <AuthLayout />
        }
      >
        <Route path={pathTo.loginAccounts} element={<LoginAccounts />} />
        <Route
          path={pathTo.loginInternal}
          element={
            <Suspense fallback={<Loading page />}>
              <LoginInternal />
            </Suspense>
          }
        />
        <Route
          path={pathTo.loginMfa}
          element={
            <NotLogged>
              <LoginMfa />
            </NotLogged>
          }
        />
        <Route
          path={pathTo.loginPassword}
          element={
            <NotLogged>
              <LoginPassword />
            </NotLogged>
          }
        />
        <Route
          path={pathTo.login}
          element={
            <NotLogged>
              <Login />
            </NotLogged>
          }
        />
        <Route path={pathTo.join} element={<JoinRoot />} />
        <Route path={pathTo.oauth} element={<OAuth />} />
        <Route path={pathTo.sso} element={<SSO />} />
        <Route
          path={pathTo.resetPassword}
          element={
            <NotLogged>
              <ResetPassword />
            </NotLogged>
          }
        />
        <Route
          path={pathTo.forgotPassword}
          element={
            <NotLogged>
              <ForgotPassword />
            </NotLogged>
          }
        />
        <Route
          path={pathTo.root}
          element={
            <LoggedAndAuthorized>
              <Suspense fallback={<Loading page />}>
                <HomeDashboard />
              </Suspense>
            </LoggedAndAuthorized>
          }
        />
        <Route
          path={pathTo.paymentServices}
          element={
            <LoggedAndAuthorized>
              <Suspense fallback={<Loading page />}>
                <PaymentServiceRoot />
              </Suspense>
            </LoggedAndAuthorized>
          }
        />

        <Route
          path={generateSplatRoute(pathTo.developers)}
          element={
            <LoggedAndAuthorized for={permissions.developers}>
              <Suspense fallback={<Loading page />}>
                <DevelopersRoot />
              </Suspense>
            </LoggedAndAuthorized>
          }
        />
        <Route
          path={generateSplatRoute(pathTo.payments)}
          element={
            <LoggedAndAuthorized for={permissions.payments}>
              <Suspense fallback={<Loading page />}>
                <PaymentsRoot />
              </Suspense>
            </LoggedAndAuthorized>
          }
        />
        <Route
          path={generateSplatRoute(pathTo.workflowsV1)}
          element={
            user?.canAccessWorkflowsV1 ? (
              <LoggedAndAuthorized for={permissions.workflows}>
                <Suspense fallback={<Loading page />}>
                  <WorkflowsRoot />
                </Suspense>
              </LoggedAndAuthorized>
            ) : (
              <Navigate to={pathTo.root} replace />
            )
          }
        />
        <Route
          path={generateSplatRoute(pathTo.integrations)}
          element={
            <LoggedAndAuthorized for={permissions.integrations}>
              <Suspense fallback={<Loading page />}>
                <IntegrationsRoot />
              </Suspense>
            </LoggedAndAuthorized>
          }
        />
        <Route
          path={generateSplatRoute(pathTo.primerEtl)}
          element={
            primerEtl ? (
              <LoggedAndAuthorized for={permissions.primerEtl}>
                <Suspense fallback={<Loading page />}>
                  <PrimerEtlRoot />
                </Suspense>
              </LoggedAndAuthorized>
            ) : (
              <Navigate to={pathTo.root} replace />
            )
          }
        />
        <Route
          path={generateSplatRoute(pathTo.reconciliation)}
          element={
            isReconciliationEnabled ? (
              <LoggedAndAuthorized for={permissions.reconciliation}>
                <Suspense fallback={<Loading page />}>
                  <Reconciliation />
                </Suspense>
              </LoggedAndAuthorized>
            ) : (
              <Navigate to={pathTo.root} replace />
            )
          }
        />
        <Route
          path={pathTo.checkout}
          element={
            <LoggedAndAuthorized for={permissions.checkout}>
              <Suspense fallback={<Loading page />}>
                <CheckoutRoot />
              </Suspense>
            </LoggedAndAuthorized>
          }
        />
        <Route
          path={generateSplatRoute(pathTo.observability)}
          element={
            <LoggedAndAuthorized for={permissions.observability}>
              <Suspense fallback={<Loading page />}>
                <ObservabilityRoot />
              </Suspense>
            </LoggedAndAuthorized>
          }
        />
        <Route
          path={generateSplatRoute(pathTo.monitorsTab)}
          element={
            <LoggedAndAuthorized for={permissions.monitors}>
              <Suspense fallback={<Loading page />}>
                <MonitorsRoot />
              </Suspense>
            </LoggedAndAuthorized>
          }
        />
        <Route
          path={generateSplatRoute(pathTo.internalTools)}
          element={
            <LoggedAndAuthorized for={permissions.internalTools}>
              <Suspense fallback={<Loading page />}>
                <InternalToolsRoot />
              </Suspense>
            </LoggedAndAuthorized>
          }
        />
        <Route
          path={linkTo.changelog()}
          element={
            <LoggedAndAuthorized for={permissions.workflows}>
              {user?.canAccessWorkflowsV1 && user?.canAccessChangelogs ? (
                <Suspense fallback={<Loading page />}>
                  <Changelog />
                </Suspense>
              ) : (
                <Navigate to='/' replace />
              )}
            </LoggedAndAuthorized>
          }
        />

        <Route
          path={pathTo.settings}
          element={
            <Navigate to={linkTo.settingsTabs({ tab: 'profile' })} replace />
          }
        />

        <Route
          path={pathTo.settingsTabs}
          element={
            <LoggedAndAuthorized for={permissions.settings}>
              <Suspense fallback={<Loading page />}>
                <AccountSettings />
              </Suspense>
            </LoggedAndAuthorized>
          }
        />

        <Route path={pathTo.Oauth} element={<Oauth />} />
        <Route path={pathTo.logout} element={<LogOut />} />
        <Route path='*' element={<Navigate to='/' replace />} />
      </Route>
    </SentryRoutes>
  );
};
