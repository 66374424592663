import { color, space } from '@primer-io/goat';
import { useEffect, useRef, useState } from 'react';
import { useMatch } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';

import { linkTo } from 'SRC/components/RoutesMain/routing';
import { useBeamer } from 'UTILS/beamer';

const Root = styled.div`
  position: relative;

  .beamer_icon {
    background-color: ${color('blue-100')} !important;
    color: ${color('blue-900')} !important;
    font-size: 10px !important;
    padding: ${space(0.5)} ${space(1)} !important;
    border-radius: 8px !important;
    line-height: ${space(3)} !important;
    min-width: ${space(4)} !important;
    display: flex !important;
    justify-content: center !important;
  }
`;

const GlobalStyle = createGlobalStyle<{ x: number; y: number }>`
    #beamerLastPostTitle {
        transform: translate(${({ x }) => x}px, ${({ y = 720 }) =>
          y}px) !important;
        pointer-events: none !important;
        
        .beamerClose {
            pointer-events: all !important;
        }
    }
`;

export const BeamerAlert = () => {
  const match = useMatch(linkTo.changelog());
  const [pos, setPos] = useState({ x: 180, y: 720 });
  const alertRef = useRef<HTMLDivElement>(null);
  const { init, destroy } = useBeamer();

  useEffect(() => {
    if (alertRef.current) {
      const { x, y } = alertRef.current.getBoundingClientRect();
      setPos({ x: x + 15, y: y - 25 });
    }

    init();

    return () => {
      destroy();
    };
  }, [init, destroy]);

  if (match) {
    return null;
  }

  return (
    <>
      <GlobalStyle {...pos} />
      <Root data-beamer-click={false} id='beamer-alert' ref={alertRef} />
    </>
  );
};
