import { useCallback, useLayoutEffect, useRef, useState } from 'react';

import { noop } from 'UTILS/noop';

/**
 * @note Values saved must be JSON serialisable.
 */
export const useLocalStorage = <T>(
  key: string,
  initialValue?: T,
  raw?: boolean,
) => {
  if (typeof window === 'undefined') {
    return [initialValue as T, noop, noop] as const;
  }
  if (!key) {
    throw new Error('useLocalStorage key may not be falsy');
  }

  const deserializer = raw ? (value) => value : JSON.parse;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const initializer = useRef((key: string) => {
    try {
      const serializer = raw ? String : JSON.stringify;
      const localStorageValue = localStorage.getItem(key);
      if (localStorageValue !== null) {
        return deserializer(localStorageValue);
      } else {
        initialValue && localStorage.setItem(key, serializer(initialValue));
        return initialValue;
      }
    } catch {
      // If user is in private mode or has storage restriction
      // localStorage can throw. JSON.parse and JSON.stringify
      // can throw, too.
      return initialValue;
    }
  });

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [state, setState] = useState<T | undefined>(() =>
    initializer.current(key),
  );

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useLayoutEffect(() => setState(initializer.current(key)), [key]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const set = useCallback(
    (newState: T) => {
      try {
        if (typeof newState === 'undefined') {
          return;
        }

        const value =
          raw && typeof newState === 'string'
            ? newState
            : JSON.stringify(newState);

        localStorage.setItem(key, value);
        setState(newState);
      } catch {
        // If user is in private mode or has storage restriction
        // localStorage can throw. Also JSON.stringify can throw.
      }
    },
    [key, raw],
  );

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const remove = useCallback(() => {
    try {
      localStorage.removeItem(key);
      setState(undefined);
    } catch {
      // If user is in private mode or has storage restriction
      // localStorage can throw.
    }
  }, [key, setState]);

  return [state, set, remove] as const;
};

export const useSessionStorage = <T>(key: string, initialValue?: T) => {
  if (typeof window === 'undefined') {
    return [initialValue as T, noop, noop] as const;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const initializer = useRef((key: string) => {
    try {
      const sessionStorageValue = sessionStorage.getItem(key);
      if (typeof sessionStorageValue !== 'string') {
        sessionStorage.setItem(key, JSON.stringify(initialValue));
        return initialValue;
      } else {
        return JSON.parse(sessionStorageValue || 'null');
      }
    } catch {
      // If user is in private mode or has storage restriction
      // sessionStorage can throw. JSON.parse and JSON.stringify
      // can throw, too.
      return initialValue;
    }
  });

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [state, setState] = useState<T>(() => initializer.current(key));

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useLayoutEffect(() => setState(initializer.current(key)), [key]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const set = useCallback(
    (newState: T) => {
      try {
        if (typeof newState === 'undefined') {
          return;
        }
        const value = JSON.stringify(newState);

        sessionStorage.setItem(key, value);
        setState(newState);
      } catch {
        // If user is in private mode or has storage restriction
        // localStorage can throw. Also JSON.stringify can throw.
      }
    },
    [key, setState],
  );

  return [state, set] as const;
};
