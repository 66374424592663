import useSWR from 'swr';

import {
  fetchObsMe,
  OBSERVABILITY_ACCOUNTS,
  ObsUpsellEventEnum,
} from 'SRC/fetch/api/observability';

export function useFetchObsMe() {
  const { data, error, isLoading } = useSWR(OBSERVABILITY_ACCOUNTS, fetchObsMe);

  // Fallback to "true" when there's no data available so that there's no
  // UI elements disappearing when the data finishes fetching.
  const dismissedUpgradedBanner =
    data?.userEvents?.includes(ObsUpsellEventEnum.DISMISSED_UPGRADE_BANNER) ??
    true;
  const dismissedRecommendedMonitorModal =
    data?.userEvents?.includes(
      ObsUpsellEventEnum.DISMISSED_RECOMMENDED_MONITORS_MODAL,
    ) ?? true;

  return {
    config: {
      showUpgradeBanner: !dismissedUpgradedBanner,
      showRecommendedMonitors: !dismissedRecommendedMonitorModal,
    },
    data,
    error,
    isLoading,
  };
}
