import { color, Icon, Sidebar } from '@primer-io/goat';
import {
  LogOut,
  Message,
  Settings,
  SettingsSolid,
} from '@primer-io/goat-icons';
import { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { PrimerIcon } from 'LEGACY/app/PrimerIcon';
import { AppEnvironmentConfig } from 'SRC/components/AppLayout/components/AppEnvironmentConfig';
import { AppSidebarLink } from 'SRC/components/AppLayout/components/AppSidebarLink';
import { AppSwitchAccount } from 'SRC/components/AppLayout/components/AppSwitchAccount';
import { useSetOpenContactDialog } from 'SRC/components/ContactSupport';
import { linkTo } from 'SRC/components/RoutesMain/routing';
import { useIdentity } from 'SRC/hooks/useIdentity';

import styles from '../AppLayout.module.scss';

interface AppSidebarProps {
  children?: ReactNode;
}
export const AppSidebar: FC<React.PropsWithChildren<AppSidebarProps>> = ({
  children,
}) => {
  const setOpenContactDialog = useSetOpenContactDialog();
  const { company, user } = useIdentity();

  const accounts = user?.primerAccountList ?? [];

  return (
    <Sidebar.Root className={styles.sidebar}>
      <Sidebar.Content>
        <Sidebar.Header>
          <Link to={linkTo.root()}>
            <PrimerIcon />
          </Link>
          <Sidebar.Lock
            ariaLabelFloating='Lock sidebar'
            ariaLabelLocked='Hide sidebar'
          />
        </Sidebar.Header>
        <Sidebar.Items>{children}</Sidebar.Items>
      </Sidebar.Content>
      <Sidebar.Profile
        data-testid='sidebar-profile-user'
        avatar={
          company?.companyLogoUrl ? (
            <img alt='company logo' src={company.companyLogoUrl} />
          ) : (
            company?.companyName?.[0] || '?'
          )
        }
        avatarBgColor={company?.companyColor ?? color('gray-900')}
        company={company?.companyName}
        name={[user?.firstName, user?.lastName].filter(Boolean).join(' ')}
      >
        <AppEnvironmentConfig>
          <Sidebar.Item>
            <Icon as={Settings} />
            Environment config
          </Sidebar.Item>
        </AppEnvironmentConfig>
        {accounts.length > 1 && <AppSwitchAccount accounts={accounts} />}

        <AppSidebarLink
          to='settings'
          icon={Settings}
          iconActive={SettingsSolid}
        >
          Account settings
        </AppSidebarLink>

        <Sidebar.Item
          onClick={() => setOpenContactDialog(true)}
          data-testid='sidebar-contact-support'
        >
          <Icon as={Message} />
          Contact support
        </Sidebar.Item>

        <AppSidebarLink
          data-testid='sidebar-logout'
          to='logout'
          requiredPermission={null as never}
          icon={LogOut}
        >
          Log out
        </AppSidebarLink>
      </Sidebar.Profile>
    </Sidebar.Root>
  );
};
