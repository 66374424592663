import { Button } from '@primer-io/goat';
import { Refresh } from '@primer-io/goat-icons';

import errorLoadingPaymentsImage from 'SRC/assets/error.svg';

import { errorStrings } from './strings';
import { ContactSupportLink } from '../ContactSupport';
import { EmptyPage } from '../EmptyPage/EmptyPage';

export const ErrorPage = () => (
  <EmptyPage
    image={errorLoadingPaymentsImage}
    title={errorStrings.title}
    extraText={errorStrings.message(<ContactSupportLink />)}
    cta={
      <Button variant='outlined' onClick={() => window.location.reload()}>
        <Button.Icon as={Refresh} />
        {errorStrings.reloadButtonLabel}
      </Button>
    }
    centerVertically
  />
);
