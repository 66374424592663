import {
  init as sentryInit,
  reactRouterV6BrowserTracingIntegration,
  replayIntegration,
} from '@sentry/react';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import moment from 'moment';
import { StrictMode, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import { defaultFeatureFlags } from 'SRC/hooks/useFeatureFlags/FeatureFlags';
import { initAnalytics } from 'UTILS/analytics';
import { isProduction } from 'UTILS/env';

import { App } from './App';
import { appEnv, launchDarklyClientId } from './config';
import './styles';

if (isProduction()) {
  sentryInit({
    dsn: 'https://f3b0dc8a02ab43eeba2239aa57508bdf@o515749.ingest.sentry.io/5865067',
    integrations: [
      reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      replayIntegration(),
      // TODO: Enable feedback integration when we are ready to use it
      // Sentry.feedbackIntegration({
      //   colorScheme: 'light',
      //   isEmailRequired: true,
      //   isNameRequired: true,
      //   showBranding: false,
      // }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,
    environment: appEnv,
    ignoreErrors: [
      /^ResizeObserver/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
    ],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

moment.updateLocale('en', {
  // @ts-ignore
  longDateFormat: {
    lll: 'MMM D YYYY LT UTC',
  },
});

initAnalytics();

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: launchDarklyClientId,
    deferInitialization: false,
    flags: defaultFeatureFlags,
    options: {
      sendEvents: false,
      streaming: appEnv !== 'test' && appEnv !== 'local',
    },
  });
  const container = document.getElementById('root');
  const root = createRoot(container!);
  root.render(
    <StrictMode>
      <LDProvider>
        <App />
      </LDProvider>
    </StrictMode>,
  );
})();
