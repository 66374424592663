import { ReactNode } from 'react';
import styled from 'styled-components';

import { Sentiment } from 'LEGACY/_utils';
import { NativeLink } from 'LEGACY/typography/Link';
import Text, { Highlight } from 'LEGACY/typography/Text';

import Card from './Card';
import Icon from './Icon';
import type { SupportedIconName } from './Icon';
import Space from '../layout/Space';

type OwnProps = {
  title?: string;
  type?: unknown;
  iconName?: SupportedIconName;
  iconSentiment?: Sentiment;
  action?: JSX.Element;
  bare?: boolean;
  description?: ReactNode;
};

const defaultNoDataProps: OwnProps = {
  title: 'Ehm...',
  description: (
    <span>
      It looks like there&apos;s nothing here yet.
      <br />
      We are waiting for initial data. Please try again later.
    </span>
  ),
  iconName: 'Activity',
};

const defaultNoMatchProps: OwnProps = {
  title: 'No match',
  description: (
    <span>
      It looks like there&apos;s nothing here.
      <br />
      Try tweaking any filter or search parameters.
    </span>
  ),
  iconName: 'Sliders',
};

const defaultGeneralErrorProps: OwnProps = {
  title: 'Oops',
  description: (
    <span>
      We’ve encountered an error. This may be a network issue, so please try
      again.
      <br />
      If the problem persists, please contact{' '}
      <NativeLink href='mailto:support@primer.io'>support@primer.io</NativeLink>
      .
    </span>
  ),
  iconName: 'Meh',
};

const defaultUnauthorizedProps: OwnProps = {
  title: 'Unauthorized ',
  description: (
    <span>
      You don't have permission to view this section. Ask your account
      administrator for access.
      <br />
      If you believe this is an error, please contact{' '}
      <NativeLink href='mailto:support@primer.io'>support@primer.io</NativeLink>
      .
    </span>
  ),
  iconName: 'Lock',
};

const Content = styled.div`
  max-width: 800px;
  text-align: center;
  margin: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const EmptyState = (props: OwnProps) => {
  const {
    title,
    description,
    type,
    action,
    bare,
    iconName,
    iconSentiment,
    ...otherProps
  } = props;

  const CardContent = (
    <div data-testid='component-emptystate' data-emptystatetype={type}>
      <Content {...(bare ? otherProps : {})}>
        {iconName && (
          <>
            <Icon
              name={iconName}
              sentiment={iconSentiment || 'branding'}
              size={50}
            />
            <Space of='col12' />
          </>
        )}
        <Highlight bold>{title}</Highlight>
        {description && (
          <Text sentiment='labelIco' wrap>
            {description}
          </Text>
        )}
        {action && (
          <>
            <Space of='col12' />
            {action}
          </>
        )}
      </Content>
    </div>
  );

  return bare ? (
    CardContent
  ) : (
    <Card styl='listing' {...otherProps}>
      {CardContent}
    </Card>
  );
};

export const EmptyStateNoData = (props: OwnProps) => {
  const combinedProps = {
    ...defaultNoDataProps,
    ...props,
    type: 'no-data',
  };

  return <EmptyState {...combinedProps} />;
};

export const EmptyStateNoMatch = (props: OwnProps) => {
  const combinedProps = {
    ...defaultNoMatchProps,
    ...props,
    type: 'no-match',
  };

  return <EmptyState {...combinedProps} />;
};

export const EmptyStateGeneralError = (props: OwnProps) => {
  const combinedProps = {
    ...defaultGeneralErrorProps,
    ...props,
    type: 'general-error',
  };

  return <EmptyState {...combinedProps} />;
};

export const EmptyStateUnauthorized = (props: OwnProps) => {
  const combinedProps = {
    ...defaultUnauthorizedProps,
    ...props,
    type: 'unauthorized',
  };

  return <EmptyState {...combinedProps} />;
};

export default EmptyState;
