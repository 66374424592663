import { createContext, useContext, useEffect } from 'react';

import { noop } from 'UTILS/noop';

type FloatingContext = {
  float(): void;
  reset(): void;
};

const context = createContext<FloatingContext>({
  float: noop,
  reset: noop,
});

export const FloatingSidebarProvider = context.Provider;

/**
 * Temporarily sets the Sidebar to floating.
 * Resets to previous value on cleanup.
 */
export function useFloatingSidebarEffect() {
  const { float, reset } = useContext(context);

  useEffect(() => {
    float();
    return reset;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
}
