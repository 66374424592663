import { shouldAddXPrimerBranchHeader, xPrimerBranch } from 'SRC/config';
import { ACCESS_TOKEN_ID } from 'SRC/fetch/api/users';
import { getLocalStorage } from 'UTILS/getLocalStorage';

interface HeadersParams {
  auth: boolean;
  init?: RequestInit;
  url?: string;
}

/**
 * Constructs headers for HTTP requests.
 *
 * Automatically determines the 'Content-Type' based on the presence of FormData in the init parameter.
 * If FormData is detected, 'Content-Type' is not explicitly set, allowing the browser to handle it.
 * Otherwise, 'Content-Type' is set to 'application/json' if not already specified.
 * If authentication is required, it adds an 'Authorization' header with a bearer token.
 * Additionally, for specific URLs, it appends a custom 'x-primer-branch' header.
 */
export function getHeaders({ auth, init = {}, url }: HeadersParams): Headers {
  const headers = new Headers(init.headers);

  const isFormData = init.body instanceof FormData;
  if (!isFormData) {
    if (!headers.has('Content-Type')) {
      headers.append('Content-Type', 'application/json');
    }
    headers.append('accept', '*/*');
  }

  if (auth) {
    const token = getLocalStorage().getItem(ACCESS_TOKEN_ID) || '';
    if (token) {
      headers.append('Authorization', `Bearer ${token}`);
    }
  }

  if (url && shouldAddXPrimerBranchHeader(url)) {
    headers.append('x-primer-branch', xPrimerBranch);
  }

  return headers;
}
