import { AppTopbar, Sidebar } from '@primer-io/goat';
import { FC, ReactNode, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { AppSidebar } from 'SRC/components/AppLayout/components/AppSidebar';
import { EnvStripe } from 'SRC/components/EnvStripe/EnvStripe';
import { useIdentity } from 'SRC/hooks/useIdentity';

import styles from './AppLayout.module.scss';
import { FloatingSidebarProvider } from './useFloatingSidebar';

interface AppLayoutProps {
  navRoutes?: ReactNode;
}
const context = 'APP_LAYOUT';
export const AppLayout: FC<React.PropsWithChildren<AppLayoutProps>> = ({
  navRoutes,
}) => {
  const { company } = useIdentity();
  const { float, floating, reset, setUserPreference } = useFloatingSidebar();

  useEffect(() => {
    document.title = [company?.companyName, 'Primer']
      .filter(Boolean)
      .join(' | ');
  }, [company?.companyName]);

  return (
    <div className={styles.container}>
      <div className={styles.envbar}>
        <EnvStripe context={context} />
      </div>
      <FloatingSidebarProvider value={{ float, reset }}>
        <Sidebar floating={floating} onFloatingChange={setUserPreference}>
          <AppTopbar>
            <AppSidebar>{navRoutes}</AppSidebar>
            <AppTopbar.Root className={styles.topbar}>
              <Sidebar.Trigger className={styles.trigger} />
            </AppTopbar.Root>
            <div id='route-content' className={styles.content}>
              <Outlet />
            </div>
          </AppTopbar>
        </Sidebar>
      </FloatingSidebarProvider>
    </div>
  );
};

// kept in this file to prevent being used in other places by mistake
function useFloatingSidebar() {
  // "floating" is kept as two booleans:
  // this is the current value, which can be temporarily changed with `useFloatingSidebarEffect`
  const [floating, setFloating] = useState(false);
  // this should only change on user interaction, and resets on every app reload
  const [userPreference, setUserPreference] = useState(false);

  return {
    float: () => setFloating(true),
    floating,
    reset: () => setFloating(userPreference),
    setUserPreference(floating: boolean) {
      setFloating(floating);
      setUserPreference(floating);
    },
  };
}
