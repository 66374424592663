import {
  borderRadius,
  Button,
  color,
  space,
  typography,
} from '@primer-io/goat';
import { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';

import EmptyState from 'LEGACY/components/EmptyState';
import { linkTo, pathTo } from 'SRC/components/RoutesMain/routing';
import { toast } from 'SRC/components/Toast';
import { Company, useIdentity } from 'SRC/hooks/useIdentity';

import {
  useForwardOAuthInvite,
  useForwardOAuthLogin,
  useForwardOAuthRegister,
} from '../api/oauth';

interface Data {
  accessToken: string;
  isInternalUser: boolean;
  primerAccounts: Company[];
  scopes: string[];
}

export function OAuth() {
  const { action } = useParams<{ action: string }>();
  const { search } = useLocation();
  const navigate = useNavigate();
  const [errorId, setErrorId] = useState<string>();
  const { logIn, logTransientIn } = useIdentity();

  const config = useMemo(
    () => ({
      search,
      onError: (err) => setErrorId(err.primerErrorId || ''),
      onSuccess({ accessToken, isInternalUser, primerAccounts, scopes }: Data) {
        if (isInternalUser) {
          logTransientIn(accessToken, scopes);
          return navigate(pathTo.loginInternal, { state: { internal: true } });
        }

        if (action === 'invite') {
          const company = primerAccounts?.find((a) => a.companyName);
          const toastMessage =
            'Invite accepted' + (company ? ` Welcome to ${company}!` : '');

          toast.positive({
            description: toastMessage,
          });
        }

        logIn(accessToken, scopes);
        navigate(pathTo.root);
      },
    }),
    [action, logIn, logTransientIn, navigate, search],
  );

  const { trigger: forwardInvite } = useForwardOAuthInvite(config);
  const { trigger: forwardLogin } = useForwardOAuthLogin(config);
  const { trigger: forwardRegister } = useForwardOAuthRegister(config);

  useEffect(() => {
    // eslint-disable-next-line default-case
    switch (action) {
      case 'login':
        forwardLogin();
        break;

      case 'register':
        forwardRegister();
        break;

      case 'invite':
        forwardInvite();
        break;
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (errorId) {
    return (
      <ErrorContainer data-testid='component-Errors'>
        <Sticker />
        <Message>
          <TitleLarge>A sign in error occurred</TitleLarge>
          <OAuthError errorId={errorId} />
        </Message>
        <Button as={Link} to={linkTo.login()}>
          Back to login
        </Button>
      </ErrorContainer>
    );
  }

  return <EmptyState title='Processing' bare />;
}

function OAuthError({ errorId }: { errorId: string }) {
  switch (errorId) {
    case 'UserNotCreatedWithSocialAuth':
      return (
        <P>
          The authentication method selected does not match our records.
          <br />
          Contact your account admin to check the users tab under account
          settings to confirm the authentication method you should be using.
          <br />
          If the problem persists please contact{' '}
          <a href='mailto:support@primer.io'>support@primer.io</a>
        </P>
      );

    case 'UnableToFetchSocialProfile':
    case 'CouldNotFetchGoogleProfile':
      return (
        <P>
          You could not be signed in as we were unable to fetch your social
          profile.
          <br />
          Please try again. If the problem persists please contact{' '}
          <a href='mailto:support@primer.io'>support@primer.io</a>
        </P>
      );

    case 'UnableToFetchSSOProfile':
      return (
        <P>
          You could not be signed in as we were unable to fetch your SSO
          profile.
          <br />
          Please try again. If the problem persists please contact{' '}
          <a href='mailto:support@primer.io'>support@primer.io</a>
        </P>
      );

    case 'NoGroupsFoundForUserOnEnvironment':
      return (
        <P>
          You do not have the requisite permissions to access this account.
          <br />
          Contact the Primer Security Team via their{' '}
          <a href='https://primerapi.atlassian.net/servicedesk/customer/portal/4'>
            IAM Form
          </a>{' '}
          for access
        </P>
      );

    case 'IncompatibleGroupsFound':
      return (
        <P>
          You have conflicting permissions.
          <br />
          Contact the Primer Security Team to make sure your Primer Support
          groups are not conflicting
        </P>
      );

    default:
      return null;
  }
}

const ErrorContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  gap: ${space(6)};
  place-items: center;
  text-align: center;
`;

const Message = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${space(2)};
`;

const TitleLarge = styled.h1`
  all: unset;
  ${typography('title-large')}
`;

const P = styled.p`
  all: unset;
`;

// TODO: replace with Goat's when it's out
const Sticker = () => (
  <Box>
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='8'
      height='22'
      viewBox='0 0 8 22'
      fill='currentColor'
    >
      <path d='M7.33843 2.50854C7.51093 1.69698 6.99463 0.901951 6.18307 0.729449C5.37151 0.556948 4.57648 1.07325 4.40398 1.8848L1.90904 13.6226C1.73654 14.4341 2.25284 15.2292 3.0644 15.4017C3.87595 15.5742 4.67098 15.0579 4.84349 14.2463L7.33843 2.50854ZM1.81693 21.2705C2.83023 21.4859 3.8254 20.8397 4.04079 19.8264C4.25617 18.8131 3.60989 17.8179 2.5966 17.6025C1.5833 17.3871 0.588117 18.0334 0.372734 19.0467C0.15735 20.06 0.803626 21.0552 1.81693 21.2705Z' />
    </svg>
  </Box>
);

const Box = styled.div`
  ${typography('header-medium')};
  background-color: ${color('coral-500')};
  border: 1px solid ${color('white')};
  border-radius: ${borderRadius('small')};
  box-shadow:
    0 0 0 1px ${color('gray-400')},
    -2px 2px 0 1px ${color('gray-400')};
  box-sizing: border-box;
  color: ${color('white')};
  display: inline-flex;
  height: 40px;
  padding: ${space(1)};
  place-content: center;
  place-items: center;
  transform: rotateZ(12deg);
  width: 40px;
`;
