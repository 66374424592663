import { H1 } from '@primer-io/goat';
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';
import { ReactNode } from 'react';

import { PageContainer } from 'SRC/components/PageContainer';
import { isLocal } from 'UTILS/env';

import styles from './ErrorBoundary.module.scss';
import { ErrorPage } from './ErrorPage';

const createMessageLog = (
  error: Error,
  componentStack: string | null,
  eventId?: string,
) => {
  return {
    react: {
      error: {
        name: error.name,
        message: error.message,
        stack: componentStack,
      },
      eventId,
    },
  };
};

const isLazyLoadingError = (error: Error | null) =>
  error &&
  error.message &&
  (error.message.includes('Failed to fetch dynamically imported module') ||
    error.message.includes('Importing a module script failed'));

const setReloadAttemptCookie = () => {
  const d = new Date();
  d.setTime(d.getTime() + 60 * 1000); // Cookie expires in 1 minute
  const expires = 'expires=' + d.toUTCString();
  document.cookie = 'reloadAttempt=1; ' + expires + '; path=/';
};

const getReloadAttemptCookie = (): boolean => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; reloadAttempt=`) || [];
  if (parts.length === 2) {
    return parts.pop()?.split(';').shift() === '1';
  }
  return false;
};

const handleError = (error: Error, componentStack: string, eventId: string) => {
  const toLog = createMessageLog(error, componentStack, eventId);

  const logMessage = [
    'react_error_boundary',
    error.name,
    error.message,
    eventId,
  ]
    .filter((v) => v)
    .join(' | ');

  if (isLocal()) {
    console.error(logMessage, toLog);
  }

  if (!isLocal() && isLazyLoadingError(error) && !getReloadAttemptCookie()) {
    setReloadAttemptCookie();
    window.location.reload();
  }
};

interface ErrorBoundaryProps {
  children: ReactNode;
}
export function ErrorBoundary({ children }: ErrorBoundaryProps) {
  return (
    <SentryErrorBoundary
      beforeCapture={(scope, error) => {
        if (isLazyLoadingError(error)) {
          scope.setLevel('warning');
        }
      }}
      fallback={({ error, componentStack }) =>
        isLocal() ? (
          <PageContainer className={styles.main}>
            <H1>⚠️ You have encountered an error. ⚠️</H1>
            <pre>{error.toString()}</pre>
            <pre>{componentStack}</pre>
          </PageContainer>
        ) : (
          <PageContainer className={styles.main}>
            <ErrorPage />
          </PageContainer>
        )
      }
      onError={handleError}
    >
      {children}
    </SentryErrorBoundary>
  );
}
