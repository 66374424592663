import List from 'LEGACY/layout/List';
import { Footnote } from 'LEGACY/typography/Text';

type OwnProps = {
  helpMessages?: string[];
};

export default function Helpers(props: OwnProps) {
  const { helpMessages, ...otherProps } = props;

  if (!helpMessages || helpMessages.length === 0) {
    return null;
  }

  return (
    <List separator='' {...otherProps}>
      {helpMessages.map((helper, idx) => (
        <Footnote key={idx} wrap>
          {helper}
        </Footnote>
      ))}
    </List>
  );
}
