import qs from 'query-string';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { toast } from 'SRC/components/Toast';
import { getStateFromStorage, OauthState } from 'SRC/hooks/useIdentity';

// this works for both apps and integrations, it will be put in a right folder shortly

// This components intercepts a redirect to our dashboard and
// 1. Parses the URL
// 2. Redirects to the page the user left our dashboard from
const Oauth = () => {
  const location = useLocation();

  const formatData = (
    fetchedState: OauthState,
    parsedUrl: Record<any, any>,
  ) => {
    const { code, state: nonce } = parsedUrl;
    const mergeObject = { ...fetchedState, code, nonce };
    const search = qs.stringify(mergeObject);
    window.opener.document.location.href = mergeObject.url + `?${search}`;
    window.close();
  };

  useEffect(() => {
    if (!location.search) {
      return;
    }
    const parsedUrl = qs.parse(location.search);

    const fetchedState = getStateFromStorage(parsedUrl.state as string);

    if (parsedUrl && !fetchedState) {
      toast.negative({ description: `Something went wrong, please try again` });
      return;
    }

    formatData(fetchedState, parsedUrl);
  }, []);

  return null;
};

export default Oauth;
