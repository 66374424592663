import { setUser as sentrySetUser } from '@sentry/react';
import { MutationFetcher } from 'swr/mutation';

import { accountsApiUrl } from 'SRC/config';
import { post } from 'SRC/fetch/fetch';
import { Company } from 'SRC/hooks/useIdentity';

export const LOGIN = 'auth/login';
export const LOGIN_CONFIRM = `${LOGIN}/confirm`;
export const LOGIN_SWITCH = `${LOGIN}/switch`;

export type SessionLoginResponse = {
  accessToken: string;
  isInternalUser: boolean;
  /**
   * This property seems to never be returned by the API
   */
  primerAccounts: string[];
  scope: string;
  scopes: string[];
  sessionPrimerAccountId: Company['id'];
  tokenType: 'bearer';
};

type CreateSessionPayload = {
  username: string;
  password: string;
};

type ConfirmSessionPayload = {
  totp: string;
};

type SwitchSessionPayload = {
  id: string;
};

export const postCreateSession: MutationFetcher<
  SessionLoginResponse,
  string,
  CreateSessionPayload
> = (_url, { arg }) => {
  const params = new URLSearchParams();
  params.append('username', arg.username);
  params.append('password', arg.password);
  params.append('grant_type', 'password');

  return post<SessionLoginResponse>(
    `${accountsApiUrl}/${LOGIN}`,
    {
      body: params,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
    { auth: false },
  ).then((response) => {
    sentrySetUser({ sessionPrimerAccountId: response.sessionPrimerAccountId });
    return response;
  });
};

export const postConfirmSession: MutationFetcher<
  SessionLoginResponse,
  string,
  ConfirmSessionPayload
> = (_url, { arg }) => {
  return post<SessionLoginResponse>(
    `${accountsApiUrl}/${LOGIN_CONFIRM}`,
    {
      body: JSON.stringify(arg),
    },
    { auth: true },
  ).then((response) => {
    sentrySetUser({ sessionPrimerAccountId: response.sessionPrimerAccountId });
    return response;
  });
};

export const postSwitchSession: MutationFetcher<
  SessionLoginResponse,
  string,
  SwitchSessionPayload
> = (_url, { arg }) => {
  return post<SessionLoginResponse>(
    `${accountsApiUrl}/${LOGIN_SWITCH}/${arg.id}`,
    {},
    { auth: true },
  ).then((response) => {
    sentrySetUser({ sessionPrimerAccountId: response.sessionPrimerAccountId });
    return response;
  });
};
