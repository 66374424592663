import { generatePath } from 'react-router-dom';

import { SettingsTab } from 'MODULES/settings/data/settingsTab';

/*
 * Use this function to create routes which will be defined in RoutesMain.tsx
 */
function createRoute<Params = unknown>(path: string) {
  return {
    path,
    link: (params?: Params) => generatePath(path, params ?? {}),
  };
}

function createNestedRoute<Params = unknown>(path: string, nestedPath: string) {
  return {
    path: nestedPath,
    link: (params?: Params) => generatePath(path + nestedPath, params ?? {}),
  };
}

// prettier-ignore
const routes = {
  root: createRoute('/'),

  Oauth: createRoute('/connect/oauth'),

  resetPassword: createRoute<{ token: string }>('/reset-password/:token'),
  forgotPassword: createRoute('/forgot-password'),
  login: createRoute('/login'),
  loginAccounts: createRoute('/login/accounts'),
  loginInternal: createRoute('/login/internal'),
  loginMfa: createRoute('/login/mfa'),
  loginPassword: createRoute('/login/password'),
  logout: createRoute('/logout'),
  join: createRoute<{ inviteCode: string }>('/join/:inviteCode'),
  redirecting: createRoute('/redirecting'),
  oauth: createRoute<{ action: string }>('/oauth/:action'),
  sso: createRoute<{ action: string }>('/sso/:action'),

  paymentServices: createRoute<{paymentService: string, tab?: string}>('/payment-services/:paymentService/:tab?'),

  developers: createRoute<{activeTab: string}>('/developers/:activeTab'),
  developersWebhookDetailAction: createNestedRoute<{activeTab: string; id: string; action: string }>('/developers/:activeTab','/:id/:action'),
  developersWebhookDetail: createNestedRoute<{activeTab: string; id: string }>('/developers/:activeTab','/:id'),

  payments: createRoute('/payments'),
  paymentsDetail: createNestedRoute<{ id: string, tab?: string }>('/payments', '/:id/:tab?'),

  settings: createRoute('/settings'),
  settingsTabs: createRoute<{tab: SettingsTab}>('/settings/:tab'),

  workflowsV1: createRoute('/workflows-v1'),
  workflowsLogsV1: createNestedRoute('/workflows-v1','/runs'), // show all logs of all workflows
  appsV1: createNestedRoute('/workflows-v1', '/apps'),
  workflowsDetailV1: createNestedRoute<{ id: string | undefined }>('/workflows-v1/','/:id'),
  workflowsLogDetailsV1: createNestedRoute<{ id: string; execution: string }>('/workflows-v1/', '/:id/runs/:execution'),

  integrations: createRoute('/integrations'),
  integrationsAccounts: createNestedRoute<{ id: string }>('/integrations','/:id/accounts'),

  observability: createRoute('/observability'),
  observabilityDashboard: createNestedRoute<{ id: string, tab?: string, chartId?: string}>('/observability', '/:id/:tab?/:chartId?'),

  monitors: createRoute('/monitors'),
  monitorsTab: createRoute('/monitors/:tab?'),
  monitorsEvents: createRoute('/monitors/events'),
  monitorsEventDetails: createNestedRoute<{ id: string, tab?: string }>('/monitors/events', '/:id'),

  primerEtl: createRoute('/primer-etl'),
  primerEtlDestination: createNestedRoute<{id: string}>('/primer-etl','/destinations/:id'),

  checkout: createRoute('/checkout'),

  reconciliation: createRoute<{ tab?: string }>('/reconciliation/:tab?'),
  reconciliationDetails: createNestedRoute<{ id: string }>('/reconciliation/transactions', '/:id'),

  changelog: createRoute('/changelog'),

  // internaltools
  internalTools: createRoute('/internal'),
  internalToolsWebsiteSync: createNestedRoute('/internal','/website-sync'),
} as const;

type RouteId = keyof typeof routes;

// Use with <Route /> or <Page />
export const pathTo = Object.fromEntries(
  Object.entries(routes).map(([id, route]) => [id, route.path]),
) as Record<RouteId, string>;

// Use with <RouterLink />
export const linkTo = Object.keys(routes).reduce((acc, key) => {
  acc[key] = routes[key].link;
  return acc;
}, {}) as {
  [R in RouteId]: (typeof routes)[R]['link'];
};

export function generateSplatRoute(path: string) {
  return `${path}/*`;
}
