import { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components';

import { getSentimentColors } from 'LEGACY/_utils';
import Theme from 'LEGACY/theme';
import { Label } from 'LEGACY/typography/Text';

import Card, { Style } from './Card';
import Icon from './Icon';
import type { SupportedIconName } from './Icon';

const ContentRoot = styled.div<{ renderIcon?: boolean }>`
  display: grid;
  grid-template-columns: ${(props) => (props.renderIcon ? '24px 1fr' : '1fr')};
  column-gap: ${Theme.dividerRow12};
  justify-items: stretch;
  align-items: stretch;
`;

type OwnProps = {
  children?: ReactNode;
  /**
   * Whether to use default icon associated with `sentiment`
   *
   * @default false
   */
  icon?: boolean;

  /**
   * Provide custom icon to display
   */
  iconName?: SupportedIconName;

  /**
   * @default attentive
   */
  sentiment?: 'positive' | 'negative' | 'attentive' | 'important' | 'neutral';
  styl?: Style;
  style?: CSSProperties;
};

const sentimentToIconName = {
  positive: 'CheckCircle',
  negative: 'AlertCircle',
  attentive: 'AlertTriangle',
  important: 'Info',
  neutral: undefined,
} as const;

export default function Callout(props: OwnProps) {
  const {
    icon = false,
    iconName,
    sentiment = 'attentive',
    children,
    ...otherProps
  } = props;

  const resolvedIconName =
    iconName ?? (icon ? sentimentToIconName[sentiment] : undefined);
  const content =
    typeof children === 'string' ? (
      <Label sentiment='regular' wrap>
        {children}
      </Label>
    ) : (
      children
    );
  const sentimentColors = getSentimentColors(sentiment);

  return (
    <Card
      elevation='flat'
      bgColor={
        sentiment === 'neutral' ? Theme.colorBackground : sentimentColors.bg
      }
      {...otherProps}
    >
      <ContentRoot renderIcon={!!resolvedIconName}>
        {resolvedIconName && (
          <Icon name={resolvedIconName} sentiment={sentiment} size={24} />
        )}
        {content}
      </ContentRoot>
    </Card>
  );
}
