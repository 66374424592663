import { Icon, Sidebar } from '@primer-io/goat';
import { GoatIcon } from '@primer-io/goat-icons';
import { ComponentProps, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { linkTo } from 'SRC/components/RoutesMain/routing';
import { useIdentity } from 'SRC/hooks/useIdentity';
import { permissions } from 'SRC/permissions';

type SidebarLinkProps = ComponentProps<(typeof Sidebar)['Item']> &
  (
    | {
        to: keyof typeof linkTo & keyof typeof permissions;
        requiredPermission?: never;
        icon: GoatIcon;
        iconActive?: GoatIcon;
      }
    | {
        to: keyof typeof linkTo;
        requiredPermission: keyof typeof permissions;
        icon: GoatIcon;
        iconActive?: GoatIcon;
      }
    | {
        to: string & {};
        requiredPermission: keyof typeof permissions;
        icon: GoatIcon;
        iconActive?: GoatIcon;
      }
  );

export function AppSidebarLink({
  requiredPermission,
  to,
  children,
  icon,
  iconActive = icon,
  ...rest
}: SidebarLinkProps) {
  const { canDo } = useIdentity();
  const { pathname } = useLocation();

  const href = to in linkTo ? linkTo[to]() : to;

  const isActive = useMemo(
    () => new RegExp(`^${href}(/.*|$)`).test(pathname),
    [href, pathname],
  );

  return (
    <Sidebar.Item
      {...rest}
      as={Link}
      active={isActive}
      disabled={
        requiredPermission ? !canDo(permissions[requiredPermission]) : undefined
      }
      to={href}
    >
      {icon && <Icon as={isActive ? iconActive : icon} />}
      {children}
    </Sidebar.Item>
  );
}
