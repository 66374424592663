import { Link as RouterLink } from 'react-router-dom';

import Button from 'LEGACY/components/Button';
import GoogleButton from 'LEGACY/components/social/GoogleButton';
import Errors from 'LEGACY/form/Errors';
import useForm, { FormInput } from 'LEGACY/hooks/useForm';
import Space from 'LEGACY/layout/Space';
import Link from 'LEGACY/typography/Link';
import { linkTo } from 'SRC/components/RoutesMain/routing';

import { OrSeparator } from '../../OrSeparator';

export type OwnProps = {
  onSubmit?: (data: { password: string }) => void;
  defaultUsername?: string;
  defaultPassword?: string;
  usernameDisabled?: boolean;
  externalError?: {
    message: string;
    source: 'GOOGLE' | 'API';
  };
  loading?: boolean;
  onGoogleClick?: () => void;
  createdVia?: string;
  /**
   * @default LOGIN
   */
  flow?: 'LOGIN' | 'INVITE';
};

export default function LoginForm({
  onSubmit,
  defaultUsername = '',
  defaultPassword = '',
  usernameDisabled = false,
  externalError,
  loading = false,
  onGoogleClick,
  createdVia,
  flow = 'LOGIN',
}: OwnProps) {
  const { renderForm, formErrors, formState } = useForm({
    onSubmit,
    defaultValues: {
      username: defaultUsername,
      password: defaultPassword,
    },
  });
  const { isSubmitting } = formState;

  const renderGoogle =
    flow === 'LOGIN' || (flow === 'INVITE' && createdVia === 'GOOGLE');

  const renderEmail =
    flow === 'LOGIN' || (flow === 'INVITE' && createdVia !== 'GOOGLE');

  return renderForm(
    <>
      {renderGoogle && <GoogleButton onClick={() => onGoogleClick?.()} />}

      {renderGoogle && externalError?.source === 'GOOGLE' && (
        <>
          <Space of='col8' />
          <Errors
            errorMessages={[externalError.message]}
            data-testid='component-Errors'
          />
        </>
      )}

      {renderGoogle && renderEmail && (
        <>
          <Space of='col24' />

          <OrSeparator />

          <Space of='col24' />
        </>
      )}

      {renderEmail && (
        <>
          <FormInput
            label='Email'
            name='username'
            errors={formErrors.username}
            required
            omitRequiredLabel
            isSubmitting={loading || isSubmitting}
            disabled={usernameDisabled}
            styl='large'
            autoComplete='username'
            formItemProps={{ labelFwdProps: { styl: 'regular' } }}
            data-testid='input-username'
          />
          <Space of='col24' />
          <FormInput
            label='Password'
            name='password'
            type='password'
            errors={formErrors.password}
            required
            omitRequiredLabel
            isSubmitting={loading || isSubmitting}
            styl='large'
            autoComplete='current-password'
            formItemProps={{ labelFwdProps: { styl: 'regular' } }}
            data-testid='input-password'
            extraContent={
              <>
                <Space of='col4' />
                <RouterLink to={linkTo.forgotPassword()}>
                  <Link styl='label'>Forgot password?</Link>
                </RouterLink>
              </>
            }
          />
          <Space of='col32' />
          <Button
            type='submit'
            disabled={isSubmitting}
            loading={loading || isSubmitting}
            grow
            size='large'
            data-testid='button-submit'
            rightIcon='ArrowRight'
          >
            Log in
          </Button>
          {externalError?.source === 'API' && (
            <>
              <Space of='col8' />
              <Errors
                errorMessages={[externalError.message]}
                data-testid='component-Errors'
              />
            </>
          )}
        </>
      )}
    </>,
  );
}
