import { CSSProperties, forwardRef, ReactNode } from 'react';
import styled from 'styled-components';

import { getSentimentColors, Sentiment } from '../_utils';
import Theme from '../theme';

export type TextSize =
  | 'overline'
  | 'footnote'
  | 'label'
  | 'regular'
  | 'highlight';

type OwnProps = {
  children?: ReactNode;
  color?: string;
  onClick?: JSX.IntrinsicElements['div']['onClick'];
  /**
   * @deprecated replaced by `size`
   */
  styl?: TextSize;
  size?: TextSize;
  sentiment?: Sentiment;
  style?: CSSProperties;
  bold?: boolean;
  wrap?: boolean;
  maxWrapLines?: number;
  inline?: boolean;
  interactive?: boolean;
  title?: string;
  /**
   * @default link
   */
  interactiveStyl?: 'none' | 'link';
  disabled?: boolean;
  code?: boolean;
  contrast?: boolean;

  /**
   * @default undefined - inherits from CSS/parent/etc...
   * Value definitions: https://developer.mozilla.org/en-US/docs/Web/CSS/text-align
   */
  align?: 'left' | 'right' | 'center' | 'start' | 'end' | 'justify';
};

const defaultOwnProps: OwnProps = {
  bold: false,
  sentiment: 'none',
  wrap: false,
  inline: false,
  interactive: false,
  disabled: false,
  code: false,
  contrast: false,
};

const Root = styled.div<OwnProps & { _wrap?: boolean; _align?: string }>`
  ${(props) =>
    props.inline
      ? 'display: inline-block;'
      : props.maxWrapLines! > 1
        ? 'display: -webkit-box;'
        : 'display: block;'}
  ${(props) =>
    props._wrap &&
    `
  white-space: normal;
  word-wrap: break-word;
  `};
  ${(props) =>
    !props._wrap &&
    `
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  `};

  ${(props) =>
    props.maxWrapLines! > 1 &&
    `
  -webkit-box-orient: vertical;
  -webkit-box-align: start !important;
  -webkit-line-clamp: ${props.maxWrapLines};
  overflow: hidden;
  text-overflow: ellipsis;
  `};

  font-family: ${(props) =>
    props.code ? Theme.fontFamilyCode : Theme.fontFamily};
  ${(props) =>
    props.bold
      ? `font-weight: ${Theme.fontWeights.bold};`
      : `font-weight: ${Theme.fontWeights.regular};`};
  ${(props) => `font-size: ${Theme.fontSizes[props.size!].fontSize}`};
  ${(props) => `line-height: ${Theme.fontSizes[props.size!].lineHeight}`};
  ${(props) =>
    `color: ${
      props.contrast
        ? getSentimentColors(props.sentiment).ct
        : getSentimentColors(props.sentiment).fg
    }`};
  ${(props) =>
    props.size === 'footnote' &&
    props.sentiment === 'none' &&
    `color: ${Theme.colorLabelIco}`};
  ${(props) =>
    props.size === 'label' &&
    props.sentiment === 'none' &&
    !props.bold &&
    `color: ${Theme.colorLabelIco}`};

  ${(props) =>
    props.interactive &&
    !props.disabled &&
    `
  cursor: pointer;

  ${
    props.interactiveStyl !== 'none' &&
    `
    &:hover {
      color: ${getSentimentColors(props.sentiment).fgHover || 'inherit'};
      text-decoration: underline;
    }
  `
  }
  `};

  ${(props) => props.interactive && props.disabled && 'cursor: default;'};

  // Did this way so that we lock uppercase just to the overline size, which is too small to be used with non uppercase variant
  // and we don't want other text variants to be allowed uppercase (hence no uppercase prop)
  ${(props) => props.size === 'overline' && 'text-transform: uppercase;'}

  ${(props) => props._align && `text-align: ${props._align};`}
`;

export const Text = forwardRef<HTMLDivElement, OwnProps>(
  function TextComponent(props, ref) {
    const { children, ...otherProps } = props;
    const mergedProps = {
      ...defaultOwnProps,
      ...otherProps,
    };

    const { wrap, styl, size, align, ...otherMergedProps } = mergedProps;

    // For time being, until `styl` is totally removed from usage, it needs to go first
    const sizeAdjusted = styl ?? size ?? 'regular';

    if (sizeAdjusted === 'overline') {
      otherMergedProps.bold = true;
    }

    return (
      <Root
        ref={ref}
        {...otherMergedProps}
        size={sizeAdjusted}
        _wrap={wrap}
        _align={align}
      >
        {children}
      </Root>
    );
  },
);

export default Text;

type SizeConsumed = Omit<OwnProps, 'size'>;

export const Overline = forwardRef<HTMLDivElement, SizeConsumed>(
  (props, ref) => <Text ref={ref} {...props} size='overline' />,
);

export const Footnote = forwardRef<HTMLDivElement, SizeConsumed>(
  (props: SizeConsumed, ref) => <Text ref={ref} {...props} size='footnote' />,
);

export const Label = forwardRef<HTMLDivElement, SizeConsumed>((props, ref) => (
  <Text ref={ref} {...props} size='label' />
));

export const Highlight = forwardRef<HTMLDivElement, SizeConsumed>(
  (props, ref) => <Text ref={ref} {...props} size='highlight' />,
);

export const FormLabel = forwardRef<
  HTMLLabelElement,
  SizeConsumed & { htmlFor?: string }
>((props, ref) => {
  const { htmlFor, inline, ...otherProps } = props;
  return (
    <label
      ref={ref}
      htmlFor={htmlFor}
      style={{
        display: inline ? 'inline-block' : 'block',
      }}
    >
      <Text size='label' {...otherProps} inline={inline} sentiment='regular' />
    </label>
  );
});
