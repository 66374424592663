import { forwardRef, isValidElement, CSSProperties, ReactNode } from 'react';
import styled, { keyframes, css } from 'styled-components';

import Icon from 'LEGACY/components/Icon';
import type { SupportedIconName } from 'LEGACY/components/Icon';
import { Space } from 'LEGACY/layout';
import Theme from 'LEGACY/theme';

// Inspired by
// https://animista.net/play/attention/pulsate/pulsate-bck
const pulsateBck = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
`;

type Style =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'danger'
  | 'branding'
  | 'negative'
  | 'interactive';

type Size = 'large' | 'regular';

type OwnProps = {
  children?: ReactNode;
  /**
   * @default button
   */
  type?: 'submit' | 'button' | 'reset';

  /**
   * @default primary
   */
  styl?: Style;

  style?: CSSProperties;

  /**
   * @default regular
   */
  size?: Size;

  /**
   * @deprecated Use `styl` prop please
   */
  sentiment?: Style;

  onClick?: JSX.IntrinsicElements['button']['onClick'];
  loading?: boolean;
  disabled?: boolean;

  /**
   * Whether the button grows in size automatically
   *
   * @default false
   */
  grow?: boolean;

  /**
   * @default false
   */
  inline?: boolean;

  leftIcon?: SupportedIconName;
  rightIcon?: SupportedIconName;
  link?: string;
  target?: string;
  download?: boolean;
};

const ButtonRaw = styled.button<
  OwnProps & { colors: ReturnType<typeof getColors>; $loading?: boolean }
>`
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;

  display: ${(props) => (props.inline ? 'inline-flex' : 'flex')};
  margin: 0;
  padding: 0 18px;

  width: ${(props) => (props.grow ? '100%' : 'auto')};
  height: ${(props) => (props.size === 'large' ? '48px' : '38px')};

  outline: none;
  border-width: ${(props) => (props.styl === 'tertiary' ? '0' : '1px')};
  border-style: solid;
  border-color: ${(props) => props.colors!.border};
  border-radius: 4px;

  background-color: ${(props) =>
    props.styl === 'tertiary' ? 'transparent' : props.colors!.background};
  color: ${(props) => props.colors!.color};

  font-family: ${Theme.fontFamily};
  font-size: ${(props) => (props.size === 'large' ? '18px' : '16px')};
  line-height: ${(props) => (props.size === 'large' ? '28px' : '24px')};
  font-weight: 500;
  cursor: pointer;
  white-space: nowrap;

  transition: box-shadow ${Theme.animationTransitionRegular};

  ${(props) =>
    props.$loading &&
    css`
      animation: ${pulsateBck} 1s ease-in-out infinite both;
    `}

  &:hover,
  &:focus {
    box-shadow: ${(props) =>
      Theme.shadowButtonHover(props.colors!.shadowHover)};
    color: ${(props) => props.colors!.color};

    ${(props) =>
      props.styl === 'tertiary' &&
      `
        color: ${Theme.colorDark};
        background-color: ${Theme.colorNeutralBg};
       
      `}
  }

  &:active:not([disabled]) {
    transform: translateY(1px);
    box-shadow: none;
    color: ${(props) => props.colors!.color};
  }

  &:disabled,
  &:disabled:hover,
  &:disabled:focus {
    box-shadow: none;
    cursor: default;

    ${(props) =>
      props.styl === 'primary' &&
      `
        color: ${Theme.colorLight};
        border-width: 1px;
        border-color: ${Theme.colorDark};
        border-style: solid;
        background-color: ${Theme.colorDark};
        opacity: 0.4;
      `}

    ${(props) =>
      props.styl === 'secondary' &&
      `
        color: ${Theme.colorLabelIco};
        border-width: 1px;
        border-style: solid;
        border-color: ${Theme.colorBorder};
        background-color: ${Theme.colorLight};
        
        & .children {
          opacity: 0.4;
        }
      `}

    ${(props) =>
      props.styl === 'tertiary' &&
      `
        color: ${Theme.colorNeutralFg};
        border-width: 0px;
        background-color: transparent;
        opacity: 0.4;
      `}

    ${(props) =>
      props.styl === 'danger' &&
      `
        color: ${Theme.colorLight};
        border-width: 1px;
        border-style: solid;
        border-color: ${Theme.colorNegativeFg};
        background-color: ${Theme.colorNegativeFg};
        opacity: 0.4;
      `}
  }

  > span {
    flex-grow: 1;
    text-align: center;
  }
`;

const Button = forwardRef<HTMLButtonElement, OwnProps>(
  (props: OwnProps, ref) => {
    const {
      type = 'button',
      size = 'regular',
      loading = false,
      disabled = false,
      grow = false,
      inline = false,
      leftIcon,
      rightIcon,
      children,
      link,
      target,
      download,
      sentiment,
      ...otherProps
    } = props;

    let styl = props.styl ?? 'primary';

    // Handling backwards compatibility for `sentiment` prop
    if (sentiment === 'branding') {
      styl = 'primary';
    } else if (sentiment === 'negative') {
      styl = 'danger';
    }

    const colors = getColors(styl);
    const isAnchorTag = !!link;

    return (
      <ButtonRaw
        type={!isAnchorTag ? type : undefined}
        styl={styl}
        inline={inline}
        grow={grow}
        size={size}
        disabled={loading || disabled}
        $loading={loading}
        colors={colors}
        {...otherProps}
        ref={ref}
        {...(isAnchorTag
          ? ({
              as: 'a',
              isAnchorTag: true,
              href: link,
              target,
              download,
            } as any)
          : {})}
      >
        {leftIcon &&
          (isValidElement(leftIcon) ? (
            leftIcon
          ) : (
            <>
              <Icon name={leftIcon} color={colors!.color} />
              <Space of='row8' />
            </>
          ))}
        {!leftIcon && rightIcon && grow && (
          <>
            <Icon name='Internal/Empty' />
            <Space of='row8' />
          </>
        )}

        <span className='children'>{children}</span>

        {leftIcon && !rightIcon && grow && (
          <>
            <Space of='row8' />
            <Icon name='Internal/Empty' />
          </>
        )}
        {rightIcon && (
          <>
            <Space of='row8' />
            <Icon name={rightIcon} color={colors!.color} />
          </>
        )}
      </ButtonRaw>
    );
  },
);

Button.displayName = 'Button';
export default Button;

const getColors = (styl: Style) => {
  switch (styl) {
    case 'primary':
      return {
        color: Theme.colorLight,
        background: Theme.colorDark,
        shadowHover: Theme.colorPlaceholder,
        border: Theme.colorDark,
      };
    case 'secondary':
      return {
        color: Theme.colorDark,
        border: Theme.colorDark,
        shadowHover: Theme.colorPlaceholder,
        background: Theme.colorLight,
      };
    case 'tertiary':
      return {
        color: Theme.colorDark,
      };
    case 'danger':
      return {
        color: Theme.colorLight,
        background: Theme.colorNegativeFg,
        border: Theme.colorNegativeFg,
        shadowHover: Theme.colorNegativeBg,
      };
    default:
      return null;
  }
};
