import { ConnectionDataMapped } from './types';

export const staticData = {
  PaymentCardConnection: {
    id: 'PAYMENT_CARD',
    internalVersion: 1,
    $searchable: ' payment_card card  ',
    isVerified: false,
    isDiscoverable: false,
    supportsMetadataMappings: false,
    oauthUrl: null,
    isConfigurable: false,
    isBeta: false,
    hasBetaPilot: false,
    primaryType: 'PAYMENT_METHOD',
    supportsMultipleConfigs: false,
    externalLinks: {
      website: '',
    },
    copy: {
      name: 'Card',
      description: '',
      instructions: {
        configurableFields: null,
        webhookSetup: null,
        additional: [],
      },
    },
    visuals: {
      assets: {
        logo: 'payment-card',
        icon: 'payment-card',
        preview: '',
        banner: '',
      },
      colors: {
        main: '#8F6AEA',
        contrast: '#fff',
        background: '#8F6AEA',
      },
    },
    compatibility: {
      markets: [],
      currencies: [],
      features: [],
    },
    provides: [],
    configurableFields: [],
  },
  ThreeDSConnection: {
    id: '3DS',
    internalVersion: 1,
    $searchable: ' 3ds 3ds  ',
    isVerified: false,
    primaryType: 'OTHER',
    isDiscoverable: false,
    supportsMetadataMappings: false,
    oauthUrl: null,
    isConfigurable: false,
    isBeta: false,
    hasBetaPilot: false,
    supportsMultipleConfigs: false,
    externalLinks: {
      website: '',
    },
    copy: {
      name: '3DS',
      description: '',
      instructions: {
        configurableFields: null,
        webhookSetup: null,
        additional: [],
      },
    },
    visuals: {
      assets: {
        logo: '3DS',
        icon: '3DS',
        preview: '',
        banner: '',
      },
      colors: {
        main: '#000',
        contrast: '#fff',
        background: '#000',
      },
    },
    compatibility: {
      markets: [],
      currencies: [],
      features: [],
    },
    provides: [],
    configurableFields: [],
  },
  UnknownConnection: {
    id: '__UNKNOWN__',
    isVerified: false,
    internalVersion: 1,
    $searchable: ' unknown  ',
    paymentMethodId: null,
    primaryType: 'OTHER',
    supportsMultipleConfigs: false,
    oauthUrl: null,
    isConfigurable: false,
    isDiscoverable: false,
    isBeta: false,
    supportsMetadataMappings: false,
    externalLinks: {
      website: '',
    },
    copy: {
      name: 'Unknown',
      description: '',
      instructions: {
        configurableFields: null,
        webhookSetup: null,
        additional: [],
      },
    },
    visuals: {
      assets: {
        logo: '',
        icon: '',
        preview: '',
        banner: '',
      },
      colors: {
        main: '',
        contrast: '',
        background: '',
      },
    },
    compatibility: {
      markets: [],
      currencies: [],
      features: [],
    },
    provides: [],
    configurableFields: [],
  },
} satisfies Record<string, ConnectionDataMapped>;
