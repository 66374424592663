import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Card from 'LEGACY/components/Card';
import EmptyState from 'LEGACY/components/EmptyState';
import Space from 'LEGACY/layout/Space';
import Theme from 'LEGACY/theme';
import { Text } from 'LEGACY/typography/Text';
import { SectionTitle } from 'LEGACY/typography/Title';
import { linkTo } from 'SRC/components/RoutesMain/routing';
import { namePartsToFullName } from 'UTILS/nameEmail';

import { commonCardHeight, commonCardWidth } from './constants';
import type { OwnProps as LoginFormProps } from '../forms/LoginForm';
import LoginForm from '../forms/LoginForm';
import type { OwnProps as NewUserFormProps } from '../forms/NewUserForm';
import { NewUserForm } from '../forms/NewUserForm';

type OwnProps = {
  status:
    | 'loadingInvite'
    | 'inviteFound'
    | 'inviteExpired'
    | 'inviteNotFound'
    | 'inviteAlreadyAccepted';
  inviterDetails?: {
    firstName?: string;
    lastName?: string;
  };
  companyName?: string;
} & (
  | { type?: 'existingUser'; formProps?: LoginFormProps }
  | { type?: 'newUser'; formProps?: NewUserFormProps }
);

const CenteredOverlay = styled.div`
  background: ${Theme.colorLight};
  position: absolute;
  left: 10%;
  top: 0px;
  width: 80%;
  height: 100%;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export function InviteCard({
  type,
  formProps,
  inviterDetails,
  companyName,
  status,
}: OwnProps) {
  const name = namePartsToFullName(inviterDetails ?? {}, 'Someone');

  return (
    <Card
      style={{
        width: commonCardWidth,
        minHeight:
          status === 'inviteFound' && type === 'existingUser'
            ? undefined
            : commonCardHeight,
        position: 'relative',
      }}
      styl='breathable'
    >
      {status === 'inviteFound' && (
        <>
          <SectionTitle>Welcome to Primer!</SectionTitle>
          <Space of='col12' />
          <Text wrap>
            {name} has invited you to join{' '}
            {companyName ? `the ${companyName}` : 'their'} team.
          </Text>
          {type === 'existingUser' && (
            <Text wrap>Log in to accept the invite.</Text>
          )}
          <Space of='col12' />
          {type === 'existingUser' && (
            <LoginForm {...formProps} flow='INVITE' />
          )}
          {type === 'newUser' && <NewUserForm {...formProps} />}
        </>
      )}

      {status === 'loadingInvite' && (
        <CenteredOverlay>
          <EmptyState
            title='Welcome to Primer!'
            description='Processing...'
            iconName='Activity'
            bare
          />
        </CenteredOverlay>
      )}

      {status === 'inviteNotFound' && (
        <CenteredOverlay>
          <EmptyState
            title='Invite not found'
            description={
              <span>
                We could not find a matching invite.
                <br />
                Please check the invite link or ask to be invited again.
              </span>
            }
            iconName='Frown'
            bare
          />
        </CenteredOverlay>
      )}

      {status === 'inviteExpired' && (
        <CenteredOverlay>
          <EmptyState
            title='Invite expired'
            description={
              <span>
                Unfortunately this invite expired.
                <br />
                Please ask to be invited again.
              </span>
            }
            iconName='Clock'
            bare
          />
        </CenteredOverlay>
      )}

      {status === 'inviteAlreadyAccepted' && (
        <CenteredOverlay>
          <EmptyState
            title='Invite already used'
            description={
              <span>
                This invite has already been used.
                <br />
                Did you{' '}
                <Link to={linkTo.forgotPassword()}>
                  forget your password
                </Link>{' '}
                or are you
                <br />
                trying to <Link to={linkTo.login()}>log in?</Link>
              </span>
            }
            iconName='CheckCircle'
            bare
          />
        </CenteredOverlay>
      )}
    </Card>
  );
}
