import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';

import {
  getForwardSso,
  SSO_CALLBACK,
  SsoLoginResponse,
} from 'SRC/fetch/api/auth/auth';
import {
  getForwardOAuthInvite,
  getForwardOAuthRegister,
  OAUTH_CALLBACK_REGISTER,
  OAUTH_LINK,
  getForwardOAuthLogin,
  OAUTH_CALLBACK_INVITE,
  OAUTH_CALLBACK_LOGIN,
  postOAuthLink,
  OAuthLoginResponse,
} from 'SRC/fetch/api/auth/oauth';
import { FetchError } from 'SRC/fetch/fetch';

export const useForwardSso = (config: {
  action?: string;
  search?: string;
  onSuccess: (r: SsoLoginResponse) => void;
  onError: (e: FetchError) => void;
}) => {
  const key =
    config.action && config.search
      ? [SSO_CALLBACK, config.action, config.search]
      : null;
  return useSWR(key, (key) => getForwardSso(key[1], key[2]), {
    onSuccess: config.onSuccess,
    onError: config.onError,
  });
};

export const useGetOAuthLink = () =>
  useSWRMutation(OAUTH_LINK, postOAuthLink, {});

export const useForwardOAuthInvite = (config: {
  onSuccess: (r: OAuthLoginResponse) => void;
  onError: (e: FetchError) => void;
  search: string;
}) => {
  return useSWRMutation(
    [OAUTH_CALLBACK_INVITE, config.search],
    () => getForwardOAuthInvite(config.search),
    {
      onSuccess: config.onSuccess,
      onError: config.onError,
    },
  );
};

export const useForwardOAuthLogin = (config: {
  onSuccess: (r: OAuthLoginResponse) => void;
  onError: (e: FetchError) => void;
  search: string;
}) => {
  return useSWRMutation(
    [OAUTH_CALLBACK_LOGIN, config.search],
    () => getForwardOAuthLogin(config.search),
    {
      onSuccess: config.onSuccess,
      onError: config.onError,
    },
  );
};

export const useForwardOAuthRegister = (config: {
  onSuccess: (r: OAuthLoginResponse) => void;
  onError: (e: FetchError) => void;
  search: string;
}) => {
  return useSWRMutation(
    [OAUTH_CALLBACK_REGISTER, config.search],
    () => getForwardOAuthRegister(config.search),
    {
      onSuccess: config.onSuccess,
      onError: config.onError,
    },
  );
};
