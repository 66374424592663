import { MutationFetcher } from 'swr/mutation';

import { accountsApiUrl } from 'SRC/config';
import { get, post } from 'SRC/fetch/fetch';
import { Company } from 'SRC/hooks/useIdentity';

export const OAUTH_LINK = 'social/auth';
export const OAUTH_CALLBACK_INVITE = `${OAUTH_LINK}/callback/invite`;
export const OAUTH_CALLBACK_LOGIN = `${OAUTH_LINK}/callback/login`;
export const OAUTH_CALLBACK_REGISTER = `${OAUTH_LINK}/callback/register`;

type GetOauthLinkPayload =
  | { initiator: 'LOGIN' }
  | { initiator: 'INVITE'; inviteCode: string }
  | { initiator: 'REGISTER' };

type GetOauthLinkResponse = {
  redirectUrl: string;
};

export type OAuthLoginResponse = {
  accessToken: string;
  isInternalUser: boolean;
  scopes: string[];
  primerAccounts: Company[];
};

export const postOAuthLink: MutationFetcher<
  GetOauthLinkResponse,
  string,
  GetOauthLinkPayload
> = (_url, { arg }) => {
  return post<GetOauthLinkResponse>(
    `${accountsApiUrl}/${OAUTH_LINK}`,
    {
      body: JSON.stringify(arg),
    },
    { auth: false },
  );
};

export const getForwardOAuthInvite = (search: string) => {
  return get<OAuthLoginResponse>(
    `${accountsApiUrl}/${OAUTH_CALLBACK_INVITE}${search}`,
    {},
    { auth: false },
  );
};

export const getForwardOAuthLogin = (search: string) => {
  return get<OAuthLoginResponse>(
    `${accountsApiUrl}/${OAUTH_CALLBACK_LOGIN}${search}`,
    {},
    { auth: false },
  );
};

export const getForwardOAuthRegister = (search: string) => {
  return get<OAuthLoginResponse>(
    `${accountsApiUrl}/${OAUTH_CALLBACK_REGISTER}${search}`,
    {},
    { auth: false },
  );
};
