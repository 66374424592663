import useSWRMutation from 'swr/mutation';

import { toast } from 'SRC/components/Toast';
import { AUTH_OPTIONS, postAuthOptions } from 'SRC/fetch/api/auth/auth';
import {
  postConfirmSession,
  LOGIN,
  LOGIN_CONFIRM,
  LOGIN_SWITCH,
  postSwitchSession,
  postCreateSession,
  SessionLoginResponse,
} from 'SRC/fetch/api/auth/login';
import { FetchError } from 'SRC/fetch/fetch';
import { useClearSWRCache } from 'SRC/hooks/useClearSWRCache';

export const useAuthOptions = () => {
  return useSWRMutation(AUTH_OPTIONS, postAuthOptions, {
    onError: () => {
      toast.negative({
        title: 'Error',
        description: 'Unable to process email address.',
      });
    },
  });
};

export const useCreateSession = ({
  onSuccess,
  onError,
}: {
  onSuccess: (r: SessionLoginResponse) => void;
  onError?: (e: FetchError) => void;
}) => {
  return useSWRMutation(LOGIN, postCreateSession, {
    onSuccess,
    onError,
  });
};

export const useConfirmSession = ({
  onSuccess,
  onError,
}: {
  onSuccess: (r: SessionLoginResponse) => void;
  onError?: (e: FetchError) => void;
}) => {
  return useSWRMutation(LOGIN_CONFIRM, postConfirmSession, {
    onSuccess,
    onError,
  });
};

export const useSwitchSession = ({
  onSuccess,
}: {
  onSuccess: (r: SessionLoginResponse) => void;
}) => {
  const clearSWRCache = useClearSWRCache();
  return useSWRMutation(LOGIN_SWITCH, postSwitchSession, {
    onSuccess: (response) => {
      onSuccess?.(response);
      clearSWRCache();
    },
    onError: () => {
      toast.negative({
        title: 'Error',
        description: 'Unable to switch account.',
      });
    },
  });
};
