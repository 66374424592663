import { css } from 'styled-components';

import Theme from 'LEGACY/theme';

export const textRegular = css`
  font-family: ${Theme.fontFamily};
  font-size: ${Theme.fontSizes.regular.fontSize};
  line-height: ${Theme.fontSizes.regular.lineHeight};
  white-space: normal;
  word-wrap: break-word;
`;
