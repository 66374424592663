export function getRedirect(search: string) {
  if (!search) {
    return;
  }

  const redirectTo = new URLSearchParams(search).get('redirectTo');

  if (!redirectTo) {
    return;
  }

  try {
    return decodeURI(redirectTo);
  } catch {
    return redirectTo;
  }
}
