import { appEnv } from 'SRC/config';

export const ENVIRONMENT = {
  local: 'local',
  dev: 'dev',
  staging: 'staging',
  sandbox: 'sandbox',
  production: 'production',
  demo: 'demo',
} as const;

type EnvironmentType = keyof typeof ENVIRONMENT;

const EnvironmentMerchantApiUrl = {
  local: 'http://localhost:8085',
  dev: 'https://api.dev.core.primer.io',
  staging: 'https://api.staging.core.primer.io',
  sandbox: 'https://api.sandbox.core.primer.io',
  demo: 'https://api.sandbox.core.primer.io', // Yes this is correct ;)
  production: 'https://api.production.core.primer.io',
} as const satisfies Record<EnvironmentType, string>;

const UrlToEnvironment = {
  'dev-dashboard.primer.io': ENVIRONMENT.dev,
  'staging-dashboard.primer.io': ENVIRONMENT.staging,
  'sandbox-dashboard.primer.io': ENVIRONMENT.sandbox,
  'dashboard.primer.io': ENVIRONMENT.production,
  'demo-dashboard.primer.io': ENVIRONMENT.demo,
} as const satisfies Record<string, EnvironmentType>;

export const APP_ENV = appEnv as EnvironmentType;

export function getEnvironmentMerchantApiUrl(
  env: EnvironmentType = APP_ENV,
): (typeof EnvironmentMerchantApiUrl)[keyof typeof EnvironmentMerchantApiUrl] {
  return EnvironmentMerchantApiUrl[env];
}

export function getUrlEnvironment(): EnvironmentType {
  // https://developer.mozilla.org/en-US/docs/Web/API/Location
  const hostname = window.location.hostname;
  return UrlToEnvironment[hostname] ?? ENVIRONMENT.local;
}

// shorthand functions, using env variable and not URL env intentionally
export const isLocal = () => APP_ENV === ENVIRONMENT.local;
export const isDevelopment = () => APP_ENV === ENVIRONMENT.dev;
export const isStaging = () => APP_ENV === ENVIRONMENT.staging;
export const isSandbox = () => APP_ENV === ENVIRONMENT.sandbox;
export const isProduction = () => APP_ENV === ENVIRONMENT.production;
