import { MutationFetcher } from 'swr/mutation';

import { RoleId } from 'MODULES/auth/const/roles';
import { accountsApiUrl } from 'SRC/config';
import { del, get, put } from 'SRC/fetch/fetch';
import { User } from 'SRC/hooks/useIdentity';

export const fetchUser = () =>
  get<User>(accountsApiUrl + '/users/me', {}, { auth: true });

export const updatePassword: MutationFetcher<
  unknown,
  string,
  {
    currentPassword: string;
    password: string;
    passwordConfirmation: string;
  }
> = (_url, { arg }) =>
  put(
    `${accountsApiUrl}/users/me/password`,
    { body: JSON.stringify(arg) },
    { auth: true },
  );

export type QueryUsersResponse = {
  accountRoles: RoleId[];
  createdVia: 'EMAIL' | 'GOOGLE' | 'SSO';
  displayName: string;
  email: string;
  id: string;
  mfaEnabled: boolean;
};
export const fetchUsers = () =>
  get<{ data: QueryUsersResponse[] }>(
    accountsApiUrl + '/users',
    {},
    { auth: true },
  );

export const fetchUserById = (id: string) => () =>
  get<User>(accountsApiUrl + `/users/${id}`, {}, { auth: true });

export const updateUserRoles: MutationFetcher<
  unknown,
  string,
  {
    id: string;
    roles: RoleId[];
    refundLimits?: {
      currencyCode: string;
      daily?: number;
      weekly?: number;
    };
  }
> = (_url, { arg }) => {
  const { id, ...rest } = arg;
  return put<User>(
    accountsApiUrl + `/users/${id}/roles`,
    { body: JSON.stringify(rest) },
    { auth: true },
  );
};

export const deleteUser: MutationFetcher<unknown, string, { id: string }> = (
  _url,
  { arg },
) => del(accountsApiUrl + `/users/${arg.id}`, {}, { auth: true });
export const USER_ME = 'users/me';
export const USERS = 'users/';
export const ACCESS_TOKEN_ID = 'accessToken';
export const SCOPES_ID = 'scopes';
