import styled from 'styled-components';

import Callout from 'LEGACY/components/Callout';
import Card from 'LEGACY/components/Card';
import Icon from 'LEGACY/components/Icon';
import { Space } from 'LEGACY/layout';
import Theme from 'LEGACY/theme';
import { Text } from 'LEGACY/typography/Text';
import { SectionTitle } from 'LEGACY/typography/Title';

import { commonCardWidth } from './constants';
import type { OwnProps as FormProps } from '../forms/LoginTwoFactorForm';
import { LoginTwoFactorForm } from '../forms/LoginTwoFactorForm';

type OwnProps = {
  formProps: FormProps;
  extraContent?: any;
};

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export function LoginTwoFactorCard({ formProps, extraContent }: OwnProps) {
  return (
    <Card
      style={{
        width: commonCardWidth,
        padding: `calc(2 * ${Theme.paddingRow20})`,
      }}
      styl='breathable'
    >
      <TitleWrapper>
        <SectionTitle wrap>Two-Factor Authentication</SectionTitle>
        <Icon name='Shield' sentiment='branding' size={60} />
      </TitleWrapper>

      <Space of={extraContent ? 'col24' : 'col48'} />

      {extraContent && (
        <>
          {typeof extraContent === 'string' ? (
            <Callout sentiment='important'>{extraContent}</Callout>
          ) : (
            extraContent
          )}
          <Space of='col24' />
        </>
      )}

      <LoginTwoFactorForm {...formProps} />

      <Space of='col48' />

      <Text wrap>
        Open the authentication app on your device to view your code.
      </Text>
    </Card>
  );
}
