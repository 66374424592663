import { forwardRef, useState } from 'react';
import styled from 'styled-components';

import Theme from 'LEGACY/theme';
import { hasControlledProps, hasControlledValue } from 'UTILS/types';

export const ToggleLabel = styled.label<{
  disabled: boolean;
  checked?: boolean;
  width: string;
  height: string;
}>`
  align-items: center;
  background-color: ${Theme.colorPlaceholder};
  border-radius: ${Theme.roundedCornersPill};
  border: 3px solid ${Theme.colorPlaceholder};
  cursor: pointer;
  display: inline-flex;
  height: ${(props) => props.height};
  position: relative;
  transition: 0.1s;
  width: ${(props) => props.width};

  ${({ checked }) =>
    checked &&
    `background-color: ${Theme.colorPositiveMg};
    border-color: ${Theme.colorPositiveMg};`}

  ${({ disabled }) =>
    disabled &&
    `
  opacity: 0.4;
  cursor: default;`}
`;

const ToggleInput = styled.input`
  height: 0;
  width: 0;
  visibility: hidden;
`;

export const ToggleButton = styled.span<{ checked?: boolean }>`
  position: absolute;
  left: 0;
  width: 18px;
  height: 18px;
  border-radius: ${Theme.roundedFull};
  transition: 0.1s;
  background: ${Theme.colorLight};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  ${({ checked }) => checked && `left: 100%; transform: translateX(-100%);`}
`;

interface CommonToggleProps {
  /**
   * @default 48px
   */
  width?: string;

  /**
   * @default 24px
   */
  height?: string;
}
interface TogglePropsControlled extends CommonToggleProps {
  checked: boolean;

  onChange: (newChecked: boolean) => void;

  /**
   * @default false
   */
  disabled?: boolean;
  name?: string;
}

interface TogglePropsUncontrolled extends CommonToggleProps {
  checked?: null | undefined;

  onChange?: (newChecked: boolean) => void;

  /**
   * @default false
   */
  disabled?: boolean;

  /**
   * @default false
   */
  defaultChecked?: boolean;
  name?: string;
}

type ToggleProps = TogglePropsControlled | TogglePropsUncontrolled;

const Toggle = forwardRef<HTMLInputElement, ToggleProps>(
  function Toggle(props, ref) {
    const {
      checked: controlledChecked,
      onChange,
      disabled = false,
      name,
      width = '48px',
      height = '22px',
    } = props;

    const defaultCheckedValue = !hasControlledProps<
      TogglePropsControlled,
      TogglePropsUncontrolled
    >(props, props.checked)
      ? props.defaultChecked
      : undefined;

    const [uncontrolledChecked, setUncontrolledChecked] =
      useState(defaultCheckedValue);

    const checked = hasControlledValue(controlledChecked)
      ? controlledChecked
      : uncontrolledChecked;

    // Cannot pass undefined for checked input value as we do for uncontrolled
    const checkedInputValue = hasControlledValue(controlledChecked)
      ? { checked: controlledChecked }
      : {};

    return (
      <ToggleLabel
        height={height}
        width={width}
        checked={checked ?? false}
        disabled={disabled}
      >
        <ToggleInput
          ref={ref}
          name={name}
          type='checkbox'
          defaultChecked={defaultCheckedValue}
          {...checkedInputValue}
          disabled={disabled}
          onChange={(e) => {
            const newChecked = e.target.checked;

            if (!hasControlledValue(controlledChecked)) {
              setUncontrolledChecked(newChecked);
            }
            onChange?.(newChecked);
          }}
        />
        <ToggleButton checked={checked ?? false} />
      </ToggleLabel>
    );
  },
);

export default Toggle;
