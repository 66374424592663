import { PAYMENT_METHOD_MAPPINGS } from 'SRC/hooks/useConnectionsInfo/config/mappings';

interface GenericPaymentMethodData {
  id: string;
  icon: string;
  name: string;
  isBeta: boolean;
  paymentMethodId?: string;
  modifyPaymentMethodName?: boolean;
}

export function createGenericPaymentMethodData(
  config = {} as GenericPaymentMethodData,
) {
  if (!config || !config.id) {
    return null;
  }

  const { id, icon, name, paymentMethodId, modifyPaymentMethodName, isBeta } =
    config;

  const paymentMethodName =
    modifyPaymentMethodName && paymentMethodId
      ? PAYMENT_METHOD_MAPPINGS[paymentMethodId] ||
        name.replace(/(.*) via.*/, '$1')
      : name;

  return {
    id,
    $searchable: '',
    paymentMethodId: paymentMethodId ?? null,
    primaryType: 'PAYMENT_METHOD',
    isVerified: false,
    isConfigurable: false,
    isDiscoverable: false,
    isBeta,
    supportsMultipleConfigs: false,
    supportsMetadataMappings: false,
    externalLinks: {},
    copy: {
      name: paymentMethodName,
      description: '',
      instructions: {
        configurableFields: null,
        webhookSetup: null,
        additional: [],
      },
    },
    visuals: {
      colors: {
        main: '#FFFFFF', // Default to white, replace as needed
        contrast: '#000000', // Default to black, replace as needed
        background: '#FFFFFF', // Default to white, replace as needed
      },
      assets: {
        logo: icon, // Assuming the icon as the logo for simplicity
        icon,
        preview: null,
        banner: null,
      },
    },
    compatibility: {
      markets: [],
      currencies: [],
      features: [],
    },
    provides: [],
    configurableFields: [],
    oauthUrl: null,
    internalVersion: 1, // Assuming a default version
  };
}
