import { Tooltip } from '@primer-io/goat';
import { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components';

import Icon from 'LEGACY/components/Icon';
import {
  Footnote,
  FormLabel,
  Label,
  Overline,
  Text,
} from 'LEGACY/typography/Text';

import Tag from './Tag';
import Space from '../layout/Space';

const TEXT_COMPONENTS = {
  footnote: Footnote,
  label: Label,
  formLabel: FormLabel,
  text: Text,
  overline: Overline,
};

type OwnProps = {
  label: string;
  children?: ReactNode;

  /**
   * @default label
   */
  styl?: 'label' | 'footnote' | 'formLabel' | 'text' | 'tag' | 'overline';

  /**
   * @default col
   */
  layout?: 'row' | 'col';

  /**
   * @default `center left` for `row`
   * @default `top left` for `col`
   */
  labelPosition?:
    | 'top left'
    | 'top right'
    | 'center left'
    | 'center right'
    | 'bottom left'
    | 'bottom right';

  /**
   * @default left
   */
  labelTextAlign?: 'left' | 'right' | 'center';

  /**
   * @default auto
   */
  labelWidth?: string;

  labelFwdProps?: { style?: CSSProperties } & Record<string, unknown>;
  style?: CSSProperties;
  /**
   * @default undefined - aka no space
   */
  space?:
    | 'col4'
    | 'col8'
    | 'col12'
    | 'col16'
    | 'col24'
    | 'row8'
    | 'row12'
    | 'row24';

  tooltipContent?: string;
  extraContent?: any;
};

const Root = styled.div<{ direction?: string; align?: string }>`
  display: flex;
  flex-direction: ${(props) => props.direction};
  align-items: ${(props) => props.align};
`;

const textAlignToFlexDirection = {
  left: 'row',
  right: 'row-reverse',
};

const LabelContainer = styled.div<{
  labelTextAlign?: string;
  labelWidth?: string;
}>`
  display: flex;
  align-items: center;
  flex-direction: ${(props) =>
    textAlignToFlexDirection[props.labelTextAlign!] ?? 'row'};
  width: ${(props) => props.labelWidth ?? 'min-content'};
`;

export default function Labelled(props: OwnProps) {
  const {
    label,
    styl,
    layout,
    labelTextAlign,
    labelWidth,
    children,
    space,
    tooltipContent,
    extraContent,
    ...otherProps
  } = props;

  const labelFwdProps = props.labelFwdProps ?? {};
  labelFwdProps.style = labelFwdProps.style ?? {};
  labelFwdProps.style.textAlign = labelTextAlign ?? 'left';
  labelFwdProps.style.display = 'flex-inline';
  labelFwdProps.style.alignItems = 'center';

  const TextComponent = TEXT_COMPONENTS[styl!] ?? Label;

  const tooltipPrerendered = tooltipContent && (
    <>
      <Space of='row8' inline />
      <Tooltip>
        <Tooltip.Trigger>
          <Icon name='Info' size={16} nudge={3} />
        </Tooltip.Trigger>
        <Tooltip.Content side='top'>{tooltipContent}</Tooltip.Content>
      </Tooltip>
    </>
  );

  const labelPrerendered =
    typeof label === 'string' ? (
      styl === 'tag' ? (
        <>
          <Tag text={label} sentiment='regular' {...labelFwdProps} />
          {tooltipPrerendered}
        </>
      ) : (
        <TextComponent {...labelFwdProps}>
          <span>{label}</span>
          {tooltipPrerendered}
        </TextComponent>
      )
    ) : (
      label
    );

  let direction =
    {
      row: 'row',
      col: 'column',
    }[layout!] ?? 'column';

  const labelPosition =
    props.labelPosition ?? (direction === 'row' ? 'center left' : 'top left');
  const [y, x] = labelPosition.split(' ');

  const combo = `${direction} ${labelPosition}`;

  if (['column center left', 'column center right'].includes(combo)) {
    console.error('Invalid prop combination for component <Labelled />', combo);
    return null;
  }

  const align = {
    'row top left': 'flex-start',
    'row top right': 'flex-start',
    'row center left': 'center',
    'row center right': 'center',
    'row bottom left': 'flex-end',
    'row bottom right': 'flex-end',
    'column top left': 'flex-start',
    'column top right': 'flex-end',
    // Invalid combination
    // 'column center left': '',
    // 'column center right': '',
    'column bottom left': 'flex-start',
    'column bottom right': 'flex-end',
  }[combo];

  if (direction === 'row' && x === 'right') {
    direction = 'row-reverse';
  }
  if (direction === 'column' && y === 'bottom') {
    direction = 'column-reverse';
  }

  return (
    <Root {...otherProps} direction={direction} align={align}>
      <LabelContainer labelWidth={labelWidth} labelTextAlign={labelTextAlign}>
        {labelPrerendered}

        {extraContent && (
          <>
            <div style={{ flex: 1 }} />
            {extraContent}
          </>
        )}
      </LabelContainer>

      {space && <Space of={space} />}
      {children}
    </Root>
  );
}
