import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';

import { toast } from 'SRC/components/Toast';
import {
  INVITATION_PATH,
  getInvitation,
  postAcceptInviteExistingUser,
  postAcceptInviteNewUser,
} from 'SRC/fetch/api/auth/invites';
import { useIdentity } from 'SRC/hooks/useIdentity';

import { JoinSSO } from './JoinSSO';
import {
  useAuthOptions,
  useConfirmSession,
  useCreateSession,
  useSwitchSession,
} from '../api/auth';
import { useGetOAuthLink } from '../api/oauth';
import { InviteCard } from '../components/login_invite/cards/InviteCard';
import { LoginTwoFactorCard } from '../components/login_invite/cards/LoginTwoFactorCard';

export function JoinRoot() {
  //////////////////////////////////////////////////////////////////////////////////////////////////
  // Basics
  const {
    loggedIn,
    logOut,
    logIn,
    logTwoFactorIn,
    isTwoFactorProtected,
    user,
    refreshUser,
  } = useIdentity();
  const { inviteCode = '' } = useParams<{ inviteCode: string }>();
  const navigate = useNavigate();
  const [twoFactor, setTwoFactor] = useState(false);

  //////////////////////////////////////////////////////////////////////////////////////////////////
  // Queries & Mutations
  const { data: dataInvite, error: errorInvite } = useSWR(
    [INVITATION_PATH, inviteCode],
    ([_key, id]) => getInvitation(id),
    {
      onError: (error) => {
        toast.negative({
          description: error.payload?.error?.description,
        });
      },
      onSuccess: (data) => {
        if (loggedIn && data.email === user?.email) {
          acceptExistingUserInvite({
            inviteCode,
          });
        } else if (loggedIn && data.email !== user?.email) {
          logOut();
        }
      },
    },
  );

  const { trigger: acceptNewUserInvite } = useSWRMutation(
    [INVITATION_PATH],
    postAcceptInviteNewUser,
    {
      onError: (error) => {
        toast.negative({
          description: error.payload?.error?.description,
        });
      },
    },
  );

  const { trigger: acceptExistingUserInvite } = useSWRMutation(
    [INVITATION_PATH],
    postAcceptInviteExistingUser,
    {
      onError: (error) => {
        toast.negative({
          description: error.payload?.error?.description,
        });
      },
      onSuccess: () => {
        onSuccessfulInviteAccept({ switch: true });
      },
    },
  );

  const { trigger: getAuthOptions, ...authOptionsMutation } = useAuthOptions();

  const {
    primerAccountId,
    email: emailInvited,
    nextStep,
    inviteeDetails,
    inviterDetails,
    primerAccountDetails: company,
  } = dataInvite ?? {};

  if (
    emailInvited &&
    !authOptionsMutation.isMutating &&
    !authOptionsMutation.data
  ) {
    getAuthOptions({
      initiator: 'INVITE',
      userEmail: emailInvited,
      inviteCode,
    });
  }

  const isExistingUser =
    !loggedIn && dataInvite && nextStep !== 'ACCEPT_INVITATION_CREATE_USER';

  const isNewUser =
    !loggedIn && dataInvite && nextStep === 'ACCEPT_INVITATION_CREATE_USER';

  const ssoProvider = authOptionsMutation.data?.redirectUrl;

  //////////////////////////////////////////////////////////////////////////////////////////////////
  // Switching to new company
  const { trigger: switchSession } = useSwitchSession({
    onSuccess: (response) => logIn(response.accessToken, response.scopes),
  });

  //////////////////////////////////////////////////////////////////////////////////////////////////
  // General callback
  const onSuccessfulInviteAccept = async (
    options: { switch?: boolean } = {},
  ) => {
    if (options.switch && primerAccountId) {
      await switchSession({ id: primerAccountId });
    }

    navigate('/');
    toast.positive({
      description: `Invite accepted. Welcome to the ${
        company?.companyName ?? ''
      } team!`,
    });
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////
  // Google related

  const { trigger: getOAuthLink } = useGetOAuthLink();

  const onGoogleClick = () =>
    getOAuthLink({ initiator: 'INVITE', inviteCode }).then(
      (r) => r && window.location.assign(r.redirectUrl),
    );

  //////////////////////////////////////////////////////////////////////////////////////////////////
  // Existing user that was not logged in
  // After login auto accept the invite
  const { trigger: createSessionAndAccept, error: logInErrorWhenAccept } =
    useCreateSession({
      async onSuccess(response) {
        if (isTwoFactorProtected(response.scopes)) {
          setTwoFactor(true);
          logTwoFactorIn(response.accessToken, response.scopes);
          return;
        }
        logIn(response.accessToken, response.scopes);
        refreshUser();
      },
    });

  const { trigger: confirmSessionAndAccept, error: twoFactorErrorWhenAccept } =
    useConfirmSession({
      onSuccess: async (response) => {
        logIn(response.accessToken, response.scopes);
        refreshUser();
      },
    });

  //////////////////////////////////////////////////////////////////////////////////////////////////
  // New user
  // Accept invite with filled in data from form and auto log in
  const { trigger: createSessionAfterAccept } = useCreateSession({
    onSuccess: (response) => {
      logIn(response.accessToken, response.scopes);
      onSuccessfulInviteAccept();
    },
  });

  const onNewUserFormSubmit = async (formData) => {
    if (!emailInvited) {
      throw new Error('User email is not defined');
    }
    try {
      await acceptNewUserInvite({
        inviteCode,
        payload: {
          firstName: formData.firstName,
          lastName: formData.lastName,
          password: formData.password,
          passwordConfirmation: formData.password,
        },
      });
      await createSessionAfterAccept({
        username: emailInvited,
        password: formData.password,
      });
    } catch {}
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////
  // Render
  if (authOptionsMutation.isMutating) {
    return <InviteCard status='loadingInvite' />;
  }

  if (twoFactor) {
    return (
      <LoginTwoFactorCard
        formProps={{
          onSubmit: confirmSessionAndAccept,
          externalError: twoFactorErrorWhenAccept
            ? 'Verification failed, your code may be invalid'
            : undefined,
        }}
      />
    );
  }

  if (errorInvite) {
    if (
      errorInvite?.httpCode === 400 ||
      errorInvite?.primerErrorId === 'UserInviteExpired'
    ) {
      return <InviteCard status='inviteExpired' />;
    }

    if (
      errorInvite?.httpCode === 423 ||
      errorInvite?.primerErrorId === 'UserInviteAlreadyAccepted'
    ) {
      return <InviteCard status='inviteAlreadyAccepted' />;
    }

    return <InviteCard status='inviteNotFound' />;
  }

  if (isExistingUser) {
    return (
      <InviteCard
        status='inviteFound'
        type='existingUser'
        inviterDetails={inviterDetails}
        companyName={company?.companyName}
        formProps={{
          onSubmit(formData) {
            if (!emailInvited) {
              throw new Error('User email is not defined');
            }
            createSessionAndAccept({ ...formData, username: emailInvited });
          },
          defaultUsername: emailInvited,
          usernameDisabled: true,
          externalError: logInErrorWhenAccept
            ? { message: 'Incorrect email or password', source: 'API' }
            : undefined,
          onGoogleClick,
          createdVia: inviteeDetails?.createdVia,
        }}
      />
    );
  }

  if (ssoProvider && emailInvited) {
    return (
      <JoinSSO
        companyName={company?.companyName}
        email={emailInvited}
        inviter={[inviterDetails?.firstName, inviterDetails?.lastName]
          .filter(Boolean)
          .join(' ')}
        redirectUrl={ssoProvider}
      />
    );
  }

  if (isNewUser) {
    return (
      <InviteCard
        status='inviteFound'
        type='newUser'
        inviterDetails={inviterDetails}
        companyName={company?.companyName}
        formProps={{
          onSubmit: onNewUserFormSubmit,
          email: emailInvited,
          firstName: inviteeDetails?.firstName,
          lastName: inviteeDetails?.lastName,
          onGoogleClick,
        }}
      />
    );
  }

  return <InviteCard status='loadingInvite' />;
}
