import { useCallback, useMemo, useState } from 'react';

import {
  MerchantList,
  MerchantListProps,
} from 'LEGACY/app/merchant/MerchantList';
import Card from 'LEGACY/components/Card';
import Pagination from 'LEGACY/components/Pagination';
import SearchInput from 'LEGACY/components/SearchInput';
import { Space } from 'LEGACY/layout';
import Title from 'LEGACY/typography/Title';
import { createEscapedRegExp } from 'UTILS/string';

const MERCHANTS_PER_PAGE = 10;

export function ChooseAccountCard({
  merchants,
  ...otherProps
}: MerchantListProps) {
  const [searchValue, setSearchValue] = useState('');
  const [filteredMerchants, setFilteredMerchants] = useState(merchants);
  const [currentPage, setCurrentPage] = useState(0);

  const isPaginationActive = filteredMerchants.length > MERCHANTS_PER_PAGE;

  const handleSearch = useCallback(
    (searchValue: string) => {
      setSearchValue(searchValue);
      setCurrentPage(0);

      const regex = createEscapedRegExp(searchValue, 'i');

      setFilteredMerchants(
        merchants.filter(
          (merchant) => regex.test(merchant.id) || regex.test(merchant.name),
        ),
      );
    },
    [merchants],
  );

  const currentMerchantsList = useMemo(
    () =>
      filteredMerchants.slice(
        MERCHANTS_PER_PAGE * currentPage,
        Math.min(
          MERCHANTS_PER_PAGE * (currentPage + 1),
          filteredMerchants.length,
        ),
      ),
    [filteredMerchants, currentPage],
  );

  return (
    <Card styl='listing'>
      <Title styl='section'>Choose an account</Title>
      <Space of='col24' />
      <SearchInput
        defaultValue={searchValue}
        onSearch={handleSearch}
        placeholder='Search'
      />
      <Space of='col24' />
      <MerchantList {...otherProps} merchants={currentMerchantsList} />
      {isPaginationActive && (
        <Pagination
          currentPage={currentPage}
          itemsPerPage={MERCHANTS_PER_PAGE}
          totalItemCount={filteredMerchants.length}
          setCurrentPage={setCurrentPage}
        />
      )}
    </Card>
  );
}
