import { useEffect, useState } from 'react';

import {
  WorkflowSource,
  WorkflowStatus,
} from 'MODULES/workflows/types/workflowSource';
import { accountsApiUrl } from 'SRC/config';
import { post } from 'SRC/fetch/fetch';
import { isDevelopment, isStaging } from 'UTILS/env';

import { PostMonitor } from './monitors';
import { NetworkTokenizationConfig } from './network-tokenization';
import {
  BatchReprocessingStatus,
  ReconciliationTransactionType,
} from './reconciliation';

const PRODUCT_EVENTS = `/product-events`;

/**
 * Product events to track user actions
 */
export type ProductEventCode =
  | 'AUTOMATION_APP_SEARCH'
  | 'AUTOMATION_APP_REQUEST'
  | 'CHANGELOG_VIEWED'
  | 'INTEGRATION_ADDED'
  | 'MONITORS_ACCESS_REQUESTED'
  | 'MONITOR_CREATED'
  | 'MONITOR_DELETED'
  | 'MONITOR_EVENT_ALL_EVENTS_REDIRECT'
  | 'MONITOR_EVENT_OBS_REDIRECT'
  | 'MONITOR_EVENT_PAYMENTS_REDIRECT'
  | 'MONITOR_EVENT_SET_NOTIF_CLICKED'
  | 'MONITOR_EVENT_TAB_VIEWED'
  | 'MONITOR_EVENT_VIEWED'
  | 'MONITOR_NOTIFICATION_HINT'
  | 'MONITOR_UPDATED'
  | 'MONITORS_PRODUCT_ACCESSED'
  | 'PAYMENTS_LIST_CSV_EXPORT'
  | 'PAYMENTS_REFUND'
  | 'RECON_ADD_CONNECTION_ATTEMPT'
  | 'RECON_ADD_CONNECTION_SUCCESS'
  | 'RECON_BATCH_EXPORTED'
  | 'RECON_BATCH_UPDATE_ACKNOWLEDGED'
  | 'RECON_BATCH_UPDATED_REQUESTED'
  | 'RECON_BATCH_VIEWED'
  | 'RECON_CONNECTION_DELETED'
  | 'RECON_DATE_FILTER_APPLIED'
  | 'RECON_FILTERS_DRAWER_OPENED'
  | 'RECON_LOAD_MORE_TRANSACTIONS'
  | 'RECON_LOAD_MORE_CONNECTIONS'
  | 'RECON_QUICK_FILTER_APPLIED'
  | 'RECON_TRANSACTION_PAYMENT_ID_CLICKED'
  | 'RECON_TRANSACTION_VIEWED'
  | 'WF_TEMPLATE_ACCESSED'
  | 'WORKFLOW_APP_ADDED'
  | 'WORKFLOW_CANVAS_INTERACTION'
  | 'WORKFLOW_EXPORTED'
  | 'WORKFLOW_IMPORTED'
  | 'WORKFLOW_RUN_BLOCK_VIEWED'
  | 'WORKFLOW_TEMPLATE_SEARCH'
  | 'OBS_DASHBOARD_ACCESSED'
  | 'WORKFLOW_DRAFT_CREATED'
  | 'OBS_CHART_PAYMENTS_ACCESSED'
  | 'HOME_TREND_BLOCK_CLICKED'
  | 'HOME_PAYMENT_SERVICE_CARD_CLICKED'
  | 'HOME_ACTIVATED_PAYMENT_SERVICE_BLOCK_CLICKED'
  | 'HOME_FEEDBACK_BUTTON_CLICKED'
  | 'PAYMENT_SERVICE_ACCESS_REQUESTED'
  | 'PAYMENT_SERVICE_LEARN_MORE_ITEM_CLICKED'
  | 'OBS_CHART_CATEGORY_SELECT_CLICKED'
  | 'OBS_CHART_CATEGORY_VISIBILITY_CHANGED'
  | 'OBS_DASHBOARD_FILTERS_APPLIED'
  | 'OBS_CHART_IMAGE_EXPORTED'
  | 'OBS_CHART_IMAGE_EXPORTER_CLICKED'
  | 'PAYMENT_SERVICE_TAB_ACCESSED'
  | 'PAYMENT_SERVICE_CONFIGURATION_PUBLISHED'
  | 'PAYMENT_SERVICE_PAYMENTS_REDIRECTED';

/**
 * Interaction event types
 */
export type CanvasInteractionEventType =
  | 'APP_ADDED'
  | 'APP_OPENED'
  | 'APP_DROPPED'
  | 'ACTION_ADDED'
  | 'ACTION_DROPPED'
  | 'MINIMAP_OPENED'
  | 'MINIMAP_CLOSED'
  | 'ROUTE_COLLAPSED'
  | 'ROUTE_EXPANDED'
  | 'ALL_ROUTES_COLLAPSED'
  | 'ALL_ROUTES_EXPANDED'
  | 'ACTIONS_TAB_OPENED'
  | 'TRIGGERS_TAB_OPENED'
  | 'ROUTE_ISOLATED'
  | 'WORKFLOW_SPLIT_DUPLICATED';

/**
 * Run block tab types
 */
export type RunBlockTabType =
  | 'Error'
  | 'Inputs'
  | 'Timeline'
  | 'Outputs'
  | 'Requests'
  | 'Conditions'
  | 'Splits';

export type RunBlockViewedPayload = {
  tab: RunBlockTabType;
  application_id?: string;
  action_id?: string;
};

type MonitorEventPayload = Partial<
  Pick<
    PostMonitor,
    | 'aggregationWindow'
    | 'cubejsFilters'
    | 'cubejsMeasure'
    | 'minNumSamples'
    | 'thresholdDirection'
    | 'thresholdValue'
    | 'type'
  >
> & {
  trigger: string;
};

type WORKFLOW_IMPORT_PAYLOAD = {
  mode: 'IMPORT';
  imported_count: number;
};

type WORKFLOW_OVERWRITE_DRAFT_PAYLOAD = {
  mode: 'OVERWRITE_DRAFT';
  origin_workflow_id: string;
};

type ReconBatchPayload = {
  transactions_count: number;
  processor: string;
  currency: string;
};

type ObsDashboardAccessedPayload = {
  dashboard: string;
  tab: string;
  filters: Record<string, string>[];
  currencyConversion?: string;
  groupBy?: string;
};

type ObsChartPaymentsAccessedPayload = {
  dashboard?: string;
  chart: string;
  paymentsCount: number;
};

type ObsChartCategorySelectClickedPayload = {
  chart: string;
  dashboard: string;
  tab: string;
};

type ObsChartCategoryVisibilityChangedPayload = {
  chart: string;
  dashboard: string;
  tab: string;
  category: string;
  visibility: boolean;
};

type ObsDashboardFiltersAppliedPayload = {
  filters: {
    field: string;
    type: string;
    metadataKey?: string;
  }[];
  source: 'FILTER_DRAWER';
};

type ObsChartImageExporterClickedPayload = {
  chart: string;
  dashboard: string;
  tab: string;
};

export type ObsChartImageExportType = 'COPY' | 'DOWNLOAD';

type ObsChartImageExportedPayload = {
  chart: string;
  dashboard: string;
  tab: string;
  type: ObsChartImageExportType;
};

type PaymentServiceTabAccessedPayload = {
  tab: string;
  id: string;
};

type PaymentServiceConfigurationPublishedPayload = {
  id: string;
  config: NetworkTokenizationConfig;
};

export type ReconEventOrigin = 'LIST' | 'DETAILS';
/**
 * Payload map for product events
 */
export type ProductEventPayload<T extends ProductEventCode> = {
  AUTOMATION_APP_SEARCH: {
    search_string: string;
    source:
      | 'MARKETPLACE'
      | 'WORKFLOW_ACTIONS'
      | 'WORKFLOW_TRIGGERS'
      | 'WORKFLOW_APPS';
    results: number;
  };
  CHANGELOG_VIEWED: never;
  INTEGRATION_ADDED: {
    application_id: string;
    instance_name: string;
    category: string;
  };
  MONITORS_ACCESS_REQUESTED: never;
  MONITOR_CREATED: MonitorEventPayload & {
    recommendation_applied: boolean;
  };
  MONITOR_DELETED: MonitorEventPayload;
  MONITOR_EVENT_SET_NOTIF_CLICKED: never;
  MONITOR_EVENT_OBS_REDIRECT: never;
  MONITOR_EVENT_PAYMENTS_REDIRECT: never;
  MONITOR_EVENT_TAB_VIEWED: {
    tab: 'payments' | 'runs' | 'recent_events' | 'webhooks';
  };
  MONITOR_EVENT_ALL_EVENTS_REDIRECT: never;
  MONITOR_EVENT_VIEWED: {
    measureKey: string;
  };
  MONITOR_NOTIFICATION_HINT: {
    items: 'workflows' | 'webhooks';
  };
  MONITOR_UPDATED: MonitorEventPayload;
  MONITORS_PRODUCT_ACCESSED: {
    value: 'upsell';
  };
  PAYMENTS_LIST_CSV_EXPORT: {
    filters?: Record<string, string>;
    count: number;
  };
  PAYMENTS_REFUND: {
    origin: 'DASHBOARD';
    fullAmount: boolean;
  };
  RECON_ADD_CONNECTION_ATTEMPT: never;
  RECON_ADD_CONNECTION_SUCCESS: never;
  RECON_BATCH_EXPORTED: ReconBatchPayload & {
    origin: ReconEventOrigin;
  };
  RECON_BATCH_UPDATED_REQUESTED: ReconBatchPayload & {
    origin: ReconEventOrigin;
  };
  RECON_BATCH_UPDATE_ACKNOWLEDGED: ReconBatchPayload & {
    conflicts_number: number;
    reprocessing_status: BatchReprocessingStatus;
  };
  RECON_BATCH_VIEWED: ReconBatchPayload;
  RECON_CONNECTION_DELETED: never;
  RECON_FILTERS_DRAWER_OPENED: never;
  RECON_DATE_FILTER_APPLIED: never;
  RECON_LOAD_MORE_TRANSACTIONS: never;
  RECON_LOAD_MORE_CONNECTIONS: never;
  RECON_QUICK_FILTER_APPLIED: {
    filterName: string;
  };
  RECON_TRANSACTION_PAYMENT_ID_CLICKED: never;
  RECON_TRANSACTION_VIEWED: {
    processor_transaction_id: string;
    type: ReconciliationTransactionType;
  };
  WF_TEMPLATE_ACCESSED: never;
  AUTOMATION_APP_REQUEST: {
    request: string;
    company: string;
    user_id: string;
  };
  WORKFLOW_APP_ADDED: {
    source: 'WORKFLOW_APPS' | 'CANVAS_BLOCK_SETTINGS';
    application_id: string;
  };
  WORKFLOW_CANVAS_INTERACTION: {
    event_type: CanvasInteractionEventType;
    /**
     * Application ID
     */
    app?: string;
    action?: string;
  };
  WORKFLOW_IMPORTED: WORKFLOW_IMPORT_PAYLOAD | WORKFLOW_OVERWRITE_DRAFT_PAYLOAD;
  WORKFLOW_EXPORTED: {
    source: 'WORKFLOW_LIST' | 'WORKFLOW_CANVAS';
    version: number;
    status: WorkflowSource['status'];
  };
  WORKFLOW_RUN_BLOCK_VIEWED: RunBlockViewedPayload;
  WORKFLOW_TEMPLATE_SEARCH: {
    search_string: string;
    results: number;
  };
  WORKFLOW_DRAFT_CREATED: {
    reset_existing_draft: boolean;
    version: number;
    status: WorkflowStatus;
  };
  OBS_DASHBOARD_ACCESSED: ObsDashboardAccessedPayload;
  OBS_CHART_PAYMENTS_ACCESSED: ObsChartPaymentsAccessedPayload;
  HOME_TREND_BLOCK_CLICKED: {
    id: string;
  };
  HOME_FEEDBACK_BUTTON_CLICKED: never;
  HOME_PAYMENT_SERVICE_CARD_CLICKED: {
    id: string;
  };
  HOME_ACTIVATED_PAYMENT_SERVICE_BLOCK_CLICKED: {
    id: string;
  };
  PAYMENT_SERVICE_ACCESS_REQUESTED: {
    id: string;
  };
  PAYMENT_SERVICE_LEARN_MORE_ITEM_CLICKED: {
    id: string;
    link: string;
  };
  OBS_CHART_CATEGORY_SELECT_CLICKED: ObsChartCategorySelectClickedPayload;
  OBS_CHART_CATEGORY_VISIBILITY_CHANGED: ObsChartCategoryVisibilityChangedPayload;
  OBS_DASHBOARD_FILTERS_APPLIED: ObsDashboardFiltersAppliedPayload;
  OBS_CHART_IMAGE_EXPORTED: ObsChartImageExportedPayload;
  OBS_CHART_IMAGE_EXPORTER_CLICKED: ObsChartImageExporterClickedPayload;
  PAYMENT_SERVICE_TAB_ACCESSED: PaymentServiceTabAccessedPayload;
  PAYMENT_SERVICE_CONFIGURATION_PUBLISHED: PaymentServiceConfigurationPublishedPayload;
  PAYMENT_SERVICE_PAYMENTS_REDIRECTED: {
    id: string;
  };
}[T];

/**
 * Product event type
 */
export type ProductEvent<T extends ProductEventCode> = {
  event_code: T;
  correlation_id?: string;
  payload?: ProductEventPayload<T>;
};

/**
 * Send a product event to the server
 */
export const sendProductEvent = <T extends ProductEventCode>(
  body: ProductEvent<T>,
) => {
  post(
    `${accountsApiUrl}${PRODUCT_EVENTS}`,
    { body: JSON.stringify(body) },
    { auth: true },
  );

  if (isStaging() || isDevelopment()) {
    console.warn('Product event dispatched', body);
  }
};

/**
 * When tracking a pageview-like event, use one of these hooks to ensure the event is only sent once
 */
export const usePageviewEvent = (eventCode: ProductEventCode) => {
  useEffect(() => {
    sendProductEvent({ event_code: eventCode });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
export const usePageviewEventWithPayload = <T extends ProductEventCode>(
  eventCode: T,
  eventData?: Omit<ProductEvent<T>, 'event_code'>,
) => {
  const [eventDispatched, setEventDispatched] = useState(false);

  if (eventDispatched) {
    return;
  }

  if (eventData) {
    sendProductEvent({
      event_code: eventCode,
      ...eventData,
    });
    setEventDispatched(true);
  }
};
