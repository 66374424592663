import { upperFirst } from 'lodash-es';
import { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components';

import Icon, { SupportedIconName } from 'LEGACY/components/Icon';

import Theme from '../theme';
import Text, { TextSize } from './Text';
import { getSentimentColors } from '../_utils';
import Space from '../layout/Space';

type OwnProps = {
  children?: ReactNode;
  style?: CSSProperties;
  styl?: TextSize;
  inline?: boolean;
  onClick?: JSX.IntrinsicElements['div']['onClick'];
  disabled?: boolean;
  active?: boolean;
  activeStyl?: {
    border?: 'top' | 'left' | 'right' | 'bottom';
    bold?: boolean; // @defaults to true if `active: true` and no other activeStyl is set
  };
  forwardedAs?: string;
  sentiment?: Sentiment;
};

const StyledText = styled(Text)<{
  forwardedAs?: string;
  $disabled?: boolean;
  active?: boolean;
}>`
  ${(props) =>
    props.forwardedAs === 'button'
      ? `background: transparent;
        border: none;
        padding: 0;`
      : ''}

  ${(props) => props.$disabled && !props.active && `opacity: 0.4;`}
`;

export default function Link(props: OwnProps) {
  const { active, activeStyl, inline, sentiment } = props;
  const sentimentAdjusted = sentiment === 'neutral' ? 'labelIco' : sentiment;

  const InnerLink = (
    <StyledText
      styl='regular'
      bold={active ? (activeStyl ? activeStyl.bold : true) : false}
      {...props}
      forwardedAs={props.forwardedAs as any}
      onClick={props.disabled ? undefined : props.onClick}
      interactive
      sentiment={
        props.disabled ? 'labelIco' : (sentimentAdjusted ?? 'interactive')
      }
      $disabled={props.disabled}
    />
  );

  if (active && activeStyl?.border) {
    return (
      <div
        style={{
          display: inline ? 'inline-block' : 'block',
          ...(active && activeStyl?.border
            ? {
                [`border${upperFirst(activeStyl?.border)}`]: `2px solid ${
                  getSentimentColors(
                    props.disabled
                      ? 'labelIco'
                      : sentimentAdjusted || 'interactive',
                  ).fg
                }`,
              }
            : {}),
        }}
      >
        {InnerLink}
      </div>
    );
  }

  return InnerLink;
}

type LinkWithIconProps = {
  text: string;
  icon: ReactNode | SupportedIconName;
  alignRight?: boolean;
  onClick?: JSX.IntrinsicElements['div']['onClick'];
  color?: string;
  bold?: boolean;
  disabled?: boolean;
  sentiment?: Sentiment;
  style?: CSSProperties;
  forwardedAs?: string;
  type?: 'button' | 'a';
  active?: boolean;
};

export function LinkWithIcon(props: LinkWithIconProps) {
  const { text, icon, alignRight, color, ...otherProps } = props;

  const TextWrapper = <span key='textWrapper'>{text}</span>;
  const Divider = <Space key='divider' of='row4' inline />;
  const IconAdjusted =
    typeof icon === 'string' ? (
      <Icon
        key='icon'
        name={icon as SupportedIconName}
        size={16}
        color={color}
        sentiment={
          otherProps?.sentiment ??
          (otherProps.disabled ? 'labelIco' : 'interactive')
        }
      />
    ) : (
      icon
    );

  const children = alignRight
    ? [TextWrapper, Divider, IconAdjusted]
    : [IconAdjusted, Divider, TextWrapper];

  return (
    <Link
      {...otherProps}
      style={{
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
        ...(otherProps.style ?? {}),
        ...(color ? { color } : {}),
      }}
    >
      {children}
    </Link>
  );
}

type Sentiment =
  | 'positive'
  | 'negative'
  | 'attentive'
  | 'neutral'
  | 'labelIco'
  | 'interactive';

export function LinkIcon(props: {
  icon: ReactNode | SupportedIconName;
  iconProps?: Record<string, unknown>;
  onClick?: JSX.IntrinsicElements['div']['onClick'];
  color?: string;
  style?: CSSProperties;
  sentiment?: Sentiment;
}) {
  const { icon, iconProps = {}, color, ...otherProps } = props;

  const IconAdjusted =
    typeof icon === 'string' ? (
      <Icon
        name={icon as SupportedIconName}
        size={16}
        color={color}
        sentiment={otherProps?.sentiment ?? 'interactive'}
        {...iconProps}
      />
    ) : (
      icon
    );

  const children = IconAdjusted;

  return (
    <Link
      {...otherProps}
      style={{
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
        ...(otherProps?.style ?? {}),
        ...(color ? { color } : {}),
      }}
    >
      {children}
    </Link>
  );
}

type SpecificLink = {
  text?: string;
  onClick?: JSX.IntrinsicElements['div']['onClick'];
  color?: string;
  disabled?: boolean;
  style?: CSSProperties;
};

export function BackLink(props: SpecificLink) {
  const { text, ...otherProps } = props;

  if (!text || text === '') {
    return (
      <LinkIcon
        {...otherProps}
        data-testid='component-backlink'
        icon='ArrowLeft'
      />
    );
  }

  return (
    <LinkWithIcon
      {...otherProps}
      data-testid='component-backlink'
      icon='ArrowLeft'
      text={text ?? 'Go back'}
    />
  );
}

export function DetailsLink(props: SpecificLink) {
  const { text, ...otherProps } = props;

  if (!text || text === '') {
    return <LinkIcon {...otherProps} icon='FileText' />;
  }

  return <LinkWithIcon {...otherProps} icon='FileText' text={text} />;
}

export function EditLink(props: SpecificLink) {
  const { text, ...otherProps } = props;

  if (!text || text === '') {
    return <LinkIcon {...otherProps} icon='Edit' />;
  }

  return <LinkWithIcon {...otherProps} icon='Edit' text={text} />;
}

export type DeleteLinkProps = {
  text?: string;
  onDelete?: (evt?: any) => void;
  onCancel?: (evt?: any) => void;
  status?: 'pending' | 'loading' | 'error' | 'success';
  errorContent?: any;
  icon?: any;
  styl?: 'delete' | 'revoke' | 'cancel' | 'archive';
  confirmationDialogProps?: any;
  skipConfirm?: boolean;
  disabled?: boolean;
};

const NativeLinkRaw = styled.a`
  color: ${Theme.colorInteraction};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    color: ${Theme.colorInteractionHover};
  }
`;

export function NativeLink(props) {
  return <NativeLinkRaw {...props} rel='noopener noreferrer' />;
}
