import {
  Dialog,
  Input,
  Button,
  Col,
  Switch,
  Select,
  Row,
} from '@primer-io/goat';
import { ReactNode, useState } from 'react';

import {
  API_ENV_VARS,
  canOverrideEnvVariables,
  OVERRIDABLE_ENV_VARS,
} from 'SRC/config';

const ENVIRONMENTS = ['dev', 'staging'];

type Props = {
  children: ReactNode;
};

export const AppEnvironmentConfig = ({ children }: Props) => {
  const [variables, setVariables] = useState<{ key: string; value: string }[]>(
    OVERRIDABLE_ENV_VARS.map((key) => ({
      key,
      value: localStorage.getItem(key) ?? '',
    })),
  );
  const [showDialog, setShowDialog] = useState(false);
  const [detailedView, setDetailedView] = useState(false);
  const [selectedEnv, setSelectedEnv] = useState<string | null>(() => {
    const apiEnvVars = variables.filter((variable) =>
      API_ENV_VARS.some((key) => key === variable.key),
    );
    return (
      ENVIRONMENTS.find((env) => {
        const regexp = new RegExp(`api\\.${env}\\.(core|data)`);
        return apiEnvVars.every(({ value }) => regexp.test(value));
      }) ?? null
    );
  });

  if (!canOverrideEnvVariables) {
    return null;
  }

  const handleEnvChange = (env?: string | null) => {
    if (!env) {
      return;
    }
    setSelectedEnv(env);
    setVariables((prev) => {
      const newVariables = [...prev];
      newVariables.forEach((variable, index) => {
        if (API_ENV_VARS.some((key) => key === variable.key)) {
          newVariables[index] = {
            key: variable.key,
            value: variable.value.replace(
              /api\.(dev|staging)\.(core|data)/,
              `api.${env}.$2`,
            ),
          };
        }
      });
      return newVariables;
    });
  };

  const onSubmit = () => {
    variables.forEach((variable) => {
      localStorage.setItem(variable.key, variable.value);
    });
    window.location.reload();
  };

  const clear = () => {
    variables.forEach((variable) => {
      localStorage.removeItem(variable.key);
    });
    window.location.reload();
  };

  return (
    <Dialog.Root open={showDialog} onOpenChange={setShowDialog}>
      <Dialog.Trigger as='div'>{children}</Dialog.Trigger>
      <Dialog.Content closeLabel='Close Dialog'>
        <Dialog.Header>Change environment variables</Dialog.Header>
        <Switch
          checked={detailedView}
          onChange={() => setDetailedView((val) => !val)}
        >
          Detailed
        </Switch>
        <Dialog.Body key={selectedEnv}>
          <Col gap={2}>
            <Col>
              <label>Environment</label>
              <Select
                value={selectedEnv}
                searchFilter={null}
                labelToNode={(i) => i}
                valueToString={(i) => i ?? ''}
                options={ENVIRONMENTS}
                onChange={handleEnvChange}
              >
                <Select.Trigger />
                <Select.Content>
                  <Select.List />
                </Select.Content>
              </Select>
            </Col>
            {variables
              .filter(
                (variable) =>
                  detailedView ||
                  !API_ENV_VARS.some((key) => key === variable.key),
              )
              .map((variable, index) => (
                <Col key={variable.key}>
                  <label>{variable.key}</label>
                  <Input.Root>
                    <Input.Text
                      required
                      defaultValue={variable.value}
                      onChange={(event) => {
                        if (
                          variable.key === 'x-primer-branch Header' &&
                          event.target.value
                        ) {
                          event.target.value = event.target.value.replace(
                            '/',
                            '-',
                          );
                        }
                        setVariables((prev) => {
                          const newVariables = [...prev];
                          newVariables[index] = {
                            key: variable.key,
                            value: event.target.value,
                          };
                          return newVariables;
                        });
                      }}
                      onBlur={(event) => {
                        if (
                          variable.key === 'x-primer-branch Header' &&
                          event.target.value
                        ) {
                          handleEnvChange('dev');
                        }
                      }}
                    />
                  </Input.Root>
                </Col>
              ))}
            <Row gap={2} justify='end'>
              <Button onClick={clear} type='button' variant='danger'>
                Clear and reload
              </Button>
              <Button onClick={onSubmit} type='submit'>
                Save and reload
              </Button>
            </Row>
          </Col>
        </Dialog.Body>
      </Dialog.Content>
    </Dialog.Root>
  );
};
