import { Icon, Sidebar } from '@primer-io/goat';
import {
  Activity,
  ActivitySolid,
  BookOpen,
  ChartColumn,
  ChartColumnSolid,
  CodeClose,
  CodeCloseSolid,
  CreditCard,
  CreditCardSolid,
  Home,
  HomeSolid,
  Integrations,
  IntegrationsSolid,
  Megaphone,
  MegaphoneSolid,
  ShoppingCart,
  ShoppingCartSolid,
  Tally,
  TallySolid,
  Workflow,
  WorkflowSolid,
} from '@primer-io/goat-icons';
import { FC } from 'react';

import { AppSidebarLink } from 'SRC/components/AppLayout/components/AppSidebarLink';
import { BeamerAlert } from 'SRC/components/BeamerAlert/BeamerAlert';
import { linkTo } from 'SRC/components/RoutesMain/routing';
import { sendProductEvent } from 'SRC/fetch/api/productEvents';
import { useFeatureFlags } from 'SRC/hooks/useFeatureFlags';
import { useFetchObsMe } from 'SRC/hooks/useFetchObs/useFetchObsMe';
import { useIdentity } from 'SRC/hooks/useIdentity';

export const NavRoutes: FC<React.PropsWithChildren<unknown>> = () => {
  const { reconciliation: isReconciliationEnabled } = useFeatureFlags();

  const { user } = useIdentity();

  const { data } = useFetchObsMe();

  return (
    <>
      <Sidebar.Group label=''>
        <AppSidebarLink
          to='root'
          requiredPermission={null as never}
          icon={Home}
          iconActive={HomeSolid}
        >
          Home
        </AppSidebarLink>
        {user?.canAccessWorkflowsV1 && user?.canAccessChangelogs && (
          <AppSidebarLink
            to={linkTo.changelog()}
            requiredPermission='workflows'
            icon={Megaphone}
            iconActive={MegaphoneSolid}
          >
            Changelog
            <BeamerAlert />
          </AppSidebarLink>
        )}
      </Sidebar.Group>

      <Sidebar.Group label=''>
        <AppSidebarLink
          to='payments'
          icon={CreditCard}
          iconActive={CreditCardSolid}
        >
          Payments
        </AppSidebarLink>
        {user?.canAccessWorkflowsV1 ? (
          <AppSidebarLink
            to='workflowsV1'
            requiredPermission='workflows'
            icon={Workflow}
            iconActive={WorkflowSolid}
          >
            Workflows
          </AppSidebarLink>
        ) : null}
        <AppSidebarLink
          to='integrations'
          requiredPermission='integrations'
          icon={Integrations}
          iconActive={IntegrationsSolid}
        >
          Integrations
        </AppSidebarLink>

        <AppSidebarLink
          to='checkout'
          icon={ShoppingCart}
          iconActive={ShoppingCartSolid}
        >
          Checkout
        </AppSidebarLink>
      </Sidebar.Group>

      <Sidebar.Group label=''>
        <AppSidebarLink
          to={linkTo.observability()}
          requiredPermission='observability'
          icon={ChartColumn}
          iconActive={ChartColumnSolid}
        >
          Observability
        </AppSidebarLink>
        <AppSidebarLink
          to={linkTo.monitors()}
          requiredPermission='monitors'
          icon={Activity}
          iconActive={ActivitySolid}
          onClick={() => {
            if (data?.monitorsPlan) {
              return;
            }

            sendProductEvent({
              event_code: 'MONITORS_PRODUCT_ACCESSED',
              payload: {
                value: 'upsell',
              },
            });
          }}
        >
          Monitors
        </AppSidebarLink>
        {isReconciliationEnabled && (
          <AppSidebarLink
            to='reconciliation'
            icon={Tally}
            iconActive={TallySolid}
          >
            Reconciliation
          </AppSidebarLink>
        )}
      </Sidebar.Group>

      <div>
        <AppSidebarLink
          to={linkTo.developers({
            activeTab: 'apiKeys',
          })}
          requiredPermission='developers'
          icon={CodeClose}
          iconActive={CodeCloseSolid}
        >
          Developers
        </AppSidebarLink>

        <Sidebar.Item href='https://primer.io/docs/api' target='_blank'>
          <Icon as={BookOpen} />
          API reference
        </Sidebar.Item>
      </div>
    </>
  );
};
