import { MutationFetcher } from 'swr/mutation';

import { ChartType } from 'MODULES/observability/utils/chart';
import { OperatorEnum } from 'MODULES/universal-filters/utils/operators';
import { apiUrl } from 'SRC/config';
import { get, post } from 'SRC/fetch/fetch';

export const MeasureFormatEnum = {
  PERCENT: 'percent',
  PERCENTAGE: 'percentage', // backward compatibility
  COUNT: 'count',
  CURRENCY: 'currency',
} as const;
export type MeasureFormatEnum =
  (typeof MeasureFormatEnum)[keyof typeof MeasureFormatEnum];

export interface CubeJSKeyConfig {
  id: string;
  cubejsKey: string;
  name: string;
  description?: string;
  increaseIsPositive?: boolean;
  format?: MeasureFormatEnum;
}

export interface Series {
  id?: string;
  name?: string;
  chartType: ChartType;
  chartTypeSettings?: Record<string, any>;
  yAxis: 'LEFT' | 'RIGHT';
  measures: (CubeJSKeyConfig & {
    cubejsMeta?: {
      drilldownMeasure?: string;
    };
  })[];
  dimensions: CubeJSKeyConfig[];
}

export interface SizePosition {
  x: number;
  y: number;
  w: number;
  h: number;
}

export type Granularity =
  | 'W/O GROUPING'
  | 'second'
  | 'minute'
  | 'hour'
  | 'day'
  | 'week'
  | 'month'
  | 'quarter'
  | 'year';

export interface ChartConfig {
  id?: string;
  name: string;
  description?: string;
  modified?: string;
  startDate?: string;
  endDate?: string;
  series: Series[];
  filters?: FilterValues[];
  granularity?: Granularity;
  currency?: string;
  summary?: {
    id: string;
    measure: CubeJSKeyConfig;
    description: string;
  };
  sizePosition?: SizePosition;
  sortingDimension?: CubeJSKeyConfig;
  eventCode: string | null;
  routePath: string | null;
}

export interface DashboardTab {
  id: string;
  name: string;
  charts: ChartConfig[];
}

export interface Dashboard {
  id: string;
  name: string;
  description?: string;
  slug?: string;
  modified?: string;
  tabs?: DashboardTab[];
  curated: boolean;
  planType: 'PRO' | 'LITE';
}

export type CubejsMeasure = {
  id: string;
  name: string;
  cubejsKey: string;
  format?: 'percent' | 'currency';
};

export const FilterTypeEnum = {
  STRING: 'string',
  NUMBER: 'number',
  BOOLEAN: 'boolean',
  CURRENCY: 'currency',
} as const;
export type FilterTypeEnum =
  (typeof FilterTypeEnum)[keyof typeof FilterTypeEnum];

export type FilterConfig = {
  id: string;
  key: string;
  cubejsKey: string;
  cubejsKeys?: [string, string];
  name: string;
  isQuickFilter?: boolean;
  assetsType?: 'ICON' | 'STATUS';
  displayPosition?: number;
  description: string;
  type: FilterTypeEnum;
  search: null | 'STATIC' | 'ASYNC';
};

export type FilterValues = {
  filter: FilterConfig;
  filterKey?: string;
  operator: OperatorEnum;
  values?: boolean | string[];
};

export type ObsMerchantAccountMe = {
  planType?: 'PRO' | 'LITE';
  monitorsPlan: 'PRO' | null;
  planCost?: number;
  planCostCurrency?: string;
  userEvents?: ObsUpsellEventEnum[];
};

export const ObsUpsellEventEnum = {
  REQUEST_UPGRADE: 'REQUEST_UPGRADE',
  CANCEL_UPGRADE: 'CANCEL_UPGRADE',
  DISMISSED_UPGRADE_BANNER: 'DISMISSED_UPGRADE_BANNER',
  DISMISSED_RECOMMENDED_MONITORS_MODAL: 'DISMISSED_RECOMMENDED_MONITORS_MODAL',
} as const;
export type ObsUpsellEventEnum =
  (typeof ObsUpsellEventEnum)[keyof typeof ObsUpsellEventEnum];

type ResponseType<ID> = ID extends string ? Dashboard : Dashboard[];
export const OBSERVABILITY_DASHBOARDS = '/observability/dashboards';
export const OBSERVABILITY_FILTERS = '/observability/cubejs/filters';
export const OBSERVABILITY_MEASURES = '/observability/cubejs/measures';
export const OBSERVABILITY_CUBE_JS_JWT = '/observability/auth/cubejs';
export const OBSERVABILITY_ACCOUNTS = '/observability/merchant-accounts';
export const OBSERVABILITY_EVENTS = '/observability/events';

export function fetchObsMe() {
  return get<ObsMerchantAccountMe>(
    apiUrl + OBSERVABILITY_ACCOUNTS + '/me',
    {},
    { auth: true },
  );
}

export const postUpgradeRequest: MutationFetcher<
  unknown,
  string,
  undefined
> = () =>
  post(apiUrl + OBSERVABILITY_ACCOUNTS + '/upgrade-plan', {}, { auth: true });

export const postUpsellEvent: MutationFetcher<
  unknown,
  string,
  { type: ObsUpsellEventEnum }
> = (_url, { arg }) =>
  post(
    apiUrl + OBSERVABILITY_EVENTS,
    { body: JSON.stringify(arg) },
    { auth: true },
  );

export function fetchDashboards<ID>(
  id?: ID,
  queryParams?: Record<string, string>,
): Promise<ResponseType<ID>> {
  const queryString = new URLSearchParams(queryParams).toString();

  return get<ResponseType<ID>>(
    apiUrl +
      OBSERVABILITY_DASHBOARDS +
      (id ? `/${id}` : '') +
      (queryString ? `?${queryString}` : ''),
    {},
    { auth: true },
  );
}

export const fetchFilters = () => {
  return get<FilterConfig[]>(
    apiUrl + OBSERVABILITY_FILTERS,
    {},
    { auth: true },
  );
};
export const fetchMeasures = ({ anomaly }: { anomaly: boolean }) => {
  return get<CubejsMeasure[]>(
    apiUrl +
      OBSERVABILITY_MEASURES +
      (anomaly ? '?anomaly_monitor_metric=true' : ''),
    {},
    { auth: true },
  );
};
export const fetchCubeJsJwt = () => {
  return get<{ token: string }>(
    apiUrl + OBSERVABILITY_CUBE_JS_JWT,
    {},
    { auth: true },
  );
};
