import { createContext, useContext } from 'react';

import { noop } from 'UTILS/noop';

import { Identity } from './types';
import { isTwoFactorProtected } from './utils';

export const defaultIdentityContextValue = {
  loggedIn: false,
  logIn: noop,
  logTwoFactorIn: noop,
  logTransientIn: noop,
  logOut: noop,
  isTwoFactorProtected,
  user: null,
  company: null,
  refreshUser: noop,
  canDo: () => false,
  token: null,
  scopes: null,
};

export const IdentityContext = createContext<Identity>(
  defaultIdentityContextValue,
);

export function useIdentity() {
  const identityContext = useContext(IdentityContext);

  if (!identityContext) {
    throw new Error('useIdentity must be used within a IdentityProvider');
  }
  return identityContext;
}
