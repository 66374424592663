import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Card from 'LEGACY/components/Card';
import EmptyState from 'LEGACY/components/EmptyState';
import Icon from 'LEGACY/components/Icon';
import Space from 'LEGACY/layout/Space';
import Theme from 'LEGACY/theme';
import { SectionTitle } from 'LEGACY/typography/Title';
import { linkTo } from 'SRC/components/RoutesMain/routing';

import { commonCardWidth, commonCardHeight } from './constants';
import ResetPasswordForm from '../forms/ResetPasswordForm';
import type { OwnProps as ResetPasswordFormProps } from '../forms/ResetPasswordForm';

type OwnProps = {
  status:
    | 'loadingRequest'
    | 'requestFound'
    | 'requestExpired'
    | 'requestNotFound'
    | 'requestAlreadyAccepted';
  formProps?: ResetPasswordFormProps;
};

const CenteredOverlay = styled.div`
  background: ${Theme.colorLight};
  position: absolute;
  left: 10%;
  top: 0px;
  width: 80%;
  height: 100%;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default function ResetPasswordCard(props: OwnProps) {
  const { formProps, status } = props;

  return (
    <Card
      style={{
        width: commonCardWidth,
        minHeight: status === 'requestFound' ? undefined : commonCardHeight,
        position: 'relative',
      }}
      styl='breathable'
    >
      {status === 'requestFound' && (
        <>
          <TitleWrapper>
            <SectionTitle>Change password</SectionTitle>
            <Icon name='Key' sentiment='branding' size={60} />
          </TitleWrapper>
          <Space of='col12' />
          <ResetPasswordForm {...formProps} />
        </>
      )}

      {status === 'loadingRequest' && (
        <CenteredOverlay>
          <EmptyState
            title='Just a moment'
            description='Processing...'
            iconName='Activity'
            bare
          />
        </CenteredOverlay>
      )}

      {status === 'requestNotFound' && (
        <CenteredOverlay>
          <EmptyState
            title='Broken link'
            description={
              <span>
                Please check the request link or
                <br />
                try to request a{' '}
                <Link to={linkTo.forgotPassword()}>password reset</Link> again.
              </span>
            }
            iconName='Frown'
            bare
          />
        </CenteredOverlay>
      )}

      {status === 'requestExpired' && (
        <CenteredOverlay>
          <EmptyState
            title='Link expired'
            description={
              <span>
                Try to request a{' '}
                <Link to={linkTo.forgotPassword()}>password reset</Link> again.
              </span>
            }
            iconName='Clock'
            bare
          />
        </CenteredOverlay>
      )}

      {status === 'requestAlreadyAccepted' && (
        <CenteredOverlay>
          <EmptyState
            title='Link already used'
            description={
              <span>
                You can <Link to={linkTo.login()}>log in</Link> or request
                <br /> a{' '}
                <Link to={linkTo.forgotPassword()}>password reset</Link> again.
              </span>
            }
            iconName='CheckCircle'
            bare
          />
        </CenteredOverlay>
      )}
    </Card>
  );
}
