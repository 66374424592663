import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Callout from 'LEGACY/components/Callout';
import Card from 'LEGACY/components/Card';
import Icon from 'LEGACY/components/Icon';
import Space from 'LEGACY/layout/Space';
import { BackLink } from 'LEGACY/typography/Link';
import { SectionTitle } from 'LEGACY/typography/Title';
import { linkTo } from 'SRC/components/RoutesMain/routing';

import { commonCardWidth } from './constants';
import type { OwnProps as ForgotPasswordFormProps } from '../forms/ForgotPasswordForm';
import ForgotPasswordForm from '../forms/ForgotPasswordForm';

type OwnProps = {
  status: 'idle' | 'pending' | 'loading' | 'error' | 'success';
  formProps?: ForgotPasswordFormProps;
  usedEmail?: string;
};

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default function ForgotPasswordCard(props: OwnProps) {
  const { formProps, status, usedEmail } = props;

  return (
    <Card
      style={{
        width: commonCardWidth,
        position: 'relative',
      }}
      styl='breathable'
    >
      <Link to={linkTo.login()}>
        <BackLink text='Login' />
      </Link>
      <Space of='col4' />
      <TitleWrapper>
        <SectionTitle>Forgot password</SectionTitle>
        <Icon
          name={status === 'success' ? 'Check' : 'Key'}
          sentiment='branding'
          size={60}
        />
      </TitleWrapper>
      <Space of='col12' />
      {status !== 'success' && (
        <ForgotPasswordForm
          {...formProps}
          loading={status === 'loading'}
          externalError={
            status === 'error'
              ? 'Something went wrong, please try again'
              : undefined
          }
        />
      )}

      {status === 'success' && (
        <Callout sentiment='neutral'>
          An email has been sent to {usedEmail} with further instructions.
        </Callout>
      )}
    </Card>
  );
}
