import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';

import { Company, User } from 'SRC/hooks/useIdentity/context/types';

const getUserDataForLaunchDarkly = (
  data: User | null,
  company: Company | null,
) => {
  if (!data) {
    return { key: 'user' };
  }
  return {
    kind: 'multi',
    user: {
      key: data.id,
      name: data.firstName,
      email: data.email,
      sessionPrimerAccountId: data.sessionPrimerAccountId,
      accountRoles: data.accountRoles,
    },
    company: {
      ...(company
        ? {
            key: company.id,
            name: company.companyName,
          }
        : {}),
    },
  } as const;
};

export const useLdClientIdentify = (
  user: User | null,
  company: Company | null,
) => {
  const ldClient = useLDClient();
  const stringifiedUser = JSON.stringify(user);
  const stringifiedCompany = JSON.stringify(company);

  useEffect(() => {
    if (ldClient) {
      ldClient.identify(getUserDataForLaunchDarkly(user, company));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringifiedUser, stringifiedCompany]);
};
