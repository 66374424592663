import {
  Button,
  Card,
  Field,
  Form,
  Icon,
  Input,
  Label,
  Validation,
} from '@primer-io/goat';
import { Settings } from '@primer-io/goat-icons';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { GoogleIcon } from 'LEGACY/components/Icon';
import { useGetOAuthLink } from 'MODULES/auth/api/oauth';
import { AppEnvironmentConfig } from 'SRC/components/AppLayout/components/AppEnvironmentConfig';
import { pathTo } from 'SRC/components/RoutesMain/routing';
import { appEnv } from 'SRC/config';
import { useLocalStorage } from 'SRC/hooks/useStorage';

import { useAuthOptions } from '../api/auth';
import { Header, OrSeparator } from '../components';

const isLocal = appEnv === 'local';
const defaultUsername = isLocal ? 'john.appleseed@primerapi.com' : '';

const StyledCard = styled(Card)`
  width: 360px;
`;

export function Login() {
  const navigate = useNavigate();
  const [lastLogin] = useLocalStorage('last-login', {
    username: defaultUsername,
  });

  const { trigger: getAuthOptions, ...authOptionsMutation } = useAuthOptions();
  const { trigger: getOAuthLink, ...oAuthLinkMutation } = useGetOAuthLink();

  return (
    <div>
      <StyledCard>
        <Header>Welcome back</Header>

        <Form
          onSubmit={async (_, { username }: { username: string }) => {
            const response = await getAuthOptions({
              initiator: 'LOGIN',
              userEmail: username,
            });

            if (!response) {
              return;
            }
            if (response.redirectUrl) {
              return window.location.assign(response.redirectUrl);
            }
            navigate(pathTo.loginPassword, { state: { username } });
          }}
        >
          <Field>
            <Label>
              Email address
              <Input.Root>
                <Input.Text
                  autoComplete='username'
                  autoFocus
                  data-testid='input-username'
                  defaultValue={lastLogin?.username}
                  name='username'
                  required
                />
              </Input.Root>
            </Label>
            <Validation if='valueMissing'>Required</Validation>
          </Field>

          <Button
            data-testid='button-submit'
            disabled={authOptionsMutation.isMutating}
            loading={authOptionsMutation.isMutating}
            type='submit'
          >
            Continue
          </Button>
        </Form>

        <OrSeparator />
        <Button
          onClick={() =>
            getOAuthLink({ initiator: 'LOGIN' }).then(
              (r) => r && window.location.assign(r.redirectUrl),
            )
          }
          disabled={oAuthLinkMutation.isMutating || !!oAuthLinkMutation.data}
          loading={oAuthLinkMutation.isMutating || !!oAuthLinkMutation.data}
          variant='outlined'
        >
          <GoogleIcon size={16} />
          Log in with Google
        </Button>
        <AppEnvironmentConfig>
          <Button variant='outlined'>
            <Icon as={Settings} />
            Environment config
          </Button>
        </AppEnvironmentConfig>
      </StyledCard>
    </div>
  );
}
