import {
  forwardRef,
  useState,
  useRef,
  useEffect,
  useImperativeHandle,
} from 'react';
import styled from 'styled-components';

import Helpers from 'LEGACY/form/Helpers';
import Input from 'LEGACY/form/Input';
import Theme from 'LEGACY/theme';
import Link from 'LEGACY/typography/Link';

import FormInput from './FormInput';
import type { InputProps } from './FormInput';

type OwnProps = InputProps & {
  helpers?: string[];
  styl?: unknown;

  /**
   * @default true
   */
  defaultCanToggleVisibility?: boolean;

  /**
   * @default visible
   */
  defaultVisibility?: 'visible' | 'hidden';

  visibleValue?: string;
};

const Grid = styled.div<{ numberOfExtras: number }>`
  display: grid;
  grid-template-columns: ${(props) =>
    props.numberOfExtras === 2 ? '1fr 1fr' : '1fr'};
  row-gap: ${Theme.paddingColumn12};
  align-items: start;
  width: 100%;
`;

const ToggleWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
`;

const FormPassword = forwardRef((props: OwnProps, ref) => {
  const {
    helpers,
    defaultCanToggleVisibility = true,
    defaultVisibility = 'hidden',
    visibleValue,
    ...otherProps
  } = props;

  const passwordRef = useRef<HTMLInputElement>();
  const forcedVisibility = useRef(false);
  const [visibility, setVisibility] = useState(defaultVisibility);
  const numberOfExtras = +!!helpers + +defaultCanToggleVisibility; // implicit casting booleans to numbers
  let extras;

  useImperativeHandle(ref, () => ({
    focus: () => passwordRef.current?.focus(),
  }));

  useEffect(() => {
    const timeIntervalId = setInterval(() => {
      if (
        forcedVisibility.current ||
        navigator?.userAgent?.includes('Firefox/')
      ) {
        return;
      }

      try {
        if (
          document.querySelector(
            `input[name=${otherProps.name}]:-webkit-autofill`,
          )
        ) {
          forcedVisibility.current = true;
          setVisibility('hidden');
        }
      } catch {}
    }, 100);

    return () => clearInterval(timeIntervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (numberOfExtras) {
    const helpersComponent = helpers ? (
      <Helpers helpMessages={helpers} />
    ) : undefined;
    const toggleComponent = defaultCanToggleVisibility ? (
      <ToggleWrapper>
        <Link
          styl='footnote'
          onClick={() =>
            setVisibility(visibility === 'visible' ? 'hidden' : 'visible')
          }
        >
          {visibility === 'visible' ? 'Hide password' : 'Show password'}
        </Link>
      </ToggleWrapper>
    ) : undefined;

    extras = (
      <Grid numberOfExtras={numberOfExtras}>
        {helpersComponent}
        {toggleComponent}
      </Grid>
    );
  }

  return (
    <div style={{ position: 'relative' }}>
      <FormInput
        {...otherProps}
        ref={passwordRef}
        extraContent={extras}
        type='password'
        style={
          visibility === 'visible'
            ? {
                color: 'transparent',
                fontFamily: Theme.fontFamilyCode,
              }
            : { fontFamily: Theme.fontFamilyCode }
        }
        isCustomPassword
        isCustomPasswordVisibility={visibility}
      />
      {visibility === 'visible' && (
        <Input
          isNaked
          value={visibleValue}
          onFocus={() => passwordRef.current!.focus()}
          onClick={() => passwordRef.current!.focus()}
          style={{
            width: '90%',
            background: 'transparent',
            position: 'absolute',
            left: '1px',
            top: '23px',
            fontFamily: Theme.fontFamilyCode,
          }}
          styl={otherProps.styl}
        />
      )}
    </div>
  );
});

export default FormPassword;
