import { MutationFetcher } from 'swr/mutation';

import { accountsApiUrl } from 'SRC/config';
import { get, post } from 'SRC/fetch/fetch';

export const FORGOT_PASSWORD_KEY = 'forgot-password';
export const postForgotPassword: MutationFetcher<
  Record<string, unknown>, // Should be "{}"
  string,
  { userEmail: string }
> = (_url, { arg: { userEmail } }) =>
  post(accountsApiUrl + `/forgot-password`, {
    body: JSON.stringify({ userEmail }),
  });

export const FORGOT_PASSWORD_TOKEN_KEY = 'forgot-password/:token';
export const getForgotPasswordToken = (token: string) =>
  get<{
    mfaEnabledOnAccount: boolean;
    userEmail: string;
  }>(accountsApiUrl + `/forgot-password/${token}`, {});

export const RESET_PASSWORD_KEY = 'forgot-password/:token';
export const postResetPassword: MutationFetcher<
  unknown,
  string,
  { newPassword: string; mfaTotp: string; token: string }
> = (_url, { arg: { token, ...rest } }) => {
  return post(
    accountsApiUrl + `/forgot-password/${token}`,
    { body: JSON.stringify(rest) },
    { auth: true },
  );
};
