import styled from 'styled-components';

import Icon from 'LEGACY/components/Icon';
import List from 'LEGACY/layout/List';
import Theme from 'LEGACY/theme';
import { Label } from 'LEGACY/typography/Text';

const Row = styled.div<{ interactive?: boolean }>`
  align-items: center;
  border-bottom: 1px solid ${Theme.colorNeutralMg};
  display: grid;
  grid-template-columns: 0.85fr 1fr minmax(40px, 0.15fr);
  gap: ${Theme.paddingRow32};
  padding: ${Theme.paddingRow16};

  ${(props) =>
    props.interactive &&
    `cursor: pointer;
    &:hover {
      background-color: ${Theme.colorCellHover};
    }`}
`;

const StyledIcon = styled(Icon)`
  justify-self: center;
`;

interface MerchantEntity {
  id: string;
  name: string;
  url?: string;
}

export interface MerchantListProps {
  currentMerchantId?: string;
  merchants: MerchantEntity[];
  onChoose: (id: string) => void;
}

export const MerchantList = ({
  currentMerchantId,
  merchants,
  onChoose,
}: MerchantListProps) => (
  <List>
    <Row>
      <Label bold>Merchant name</Label>
      <Label bold>Account ID</Label>
      <Label bold align='center'>
        Log in
      </Label>
    </Row>
    {merchants.map((merchant) => (
      <Row
        key={merchant.id}
        interactive
        onClick={() =>
          merchant.id !== currentMerchantId && onChoose(merchant.id)
        }
      >
        <Label wrap sentiment='regular'>
          {merchant.name}
        </Label>
        <Label wrap>{merchant.id}</Label>
        <StyledIcon name='LogIn' color={Theme.colorDark} size={24} />
      </Row>
    ))}
  </List>
);
