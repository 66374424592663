import { MutationFetcher } from 'swr/mutation';

import { accountsApiUrl } from 'SRC/config';
import { post, get } from 'SRC/fetch/fetch';
import { Company } from 'SRC/hooks/useIdentity';

export const AUTH_OPTIONS = 'auth/options';
export const SSO_CALLBACK = 'sso/callback';

type AuthOptionsPayload =
  | { initiator: 'LOGIN'; userEmail: string }
  | { initiator: 'INVITE'; inviteCode: string; userEmail: string };

type AuthOptionsResponse = {
  redirectUrl: string | null;
};

export type SsoLoginResponse = {
  accessToken: string;
  isInternalUser: boolean;
  scopes: string[];
  primerAccounts: Company[];
};

export const postAuthOptions: MutationFetcher<
  AuthOptionsResponse,
  string,
  AuthOptionsPayload
> = (_url, { arg }) => {
  return post(
    `${accountsApiUrl}/${AUTH_OPTIONS}`,
    {
      body: JSON.stringify(arg),
    },
    { auth: false },
  );
};

export const getForwardSso = (action: string, search: string) => {
  return get<SsoLoginResponse>(
    `${accountsApiUrl}/${SSO_CALLBACK}/${action}${search}`,
    {},
    { auth: false },
  );
};
