import { CSSProperties, forwardRef } from 'react';
// eslint-disable-next-line import/no-namespace
import * as FeatherIcon from 'react-feather';

import type { Sentiment } from 'LEGACY/_utils';
import { getSentimentColors } from 'LEGACY/_utils';
import Theme from 'LEGACY/theme';

export type InternalIcon =
  | 'Internal/Empty'
  | 'Internal/Loading'
  | 'Internal/Range'
  | 'Equals'
  | 'NotEquals'
  | 'GreaterThan'
  | 'GreaterThanOrEqual'
  | 'LessThan'
  | 'LessThanOrEqual'
  | 'Array'
  | 'Empty'
  | 'NotEmpty'
  | 'SystemAdjust'
  | 'Internal/OneOf'
  | 'Internal/NotOneOf'
  | 'Internal/Contains'
  | 'Internal/DoesNotContain'
  | 'Internal/Bank'
  | 'Internal/ChevronUpDown'
  | 'Internal/Wallet'
  | 'Internal/Google'
  | 'Internal/GoogleDisabled'
  | 'Internal/ConditionIcon';

export type SupportedIconName = keyof typeof FeatherIcon | InternalIcon;

const defaultNamedIconSize = 20;

type ImageIconProps = {
  src?: string;
  alt?: string;
  /**
   * @default 16px
   */
  size?: number | string;
  style?: CSSProperties;
  name?: never;
  color?: string;
};

type NamedIconProps = {
  name: SupportedIconName;

  /**
   * @default labelIco
   */
  sentiment?: Sentiment;

  /**
   * @default 20
   */
  size?: number;

  fill?: string;
  stroke?: string;

  /**
   * Allows to nudge icon on Y axis if needed
   *
   * @example 1 → would mean 1px (move down)
   * @example -1 → would mean -1px (move up)
   */
  nudge?: number;

  /**
   * Force custom color
   */
  color?: string;
  onClick?: JSX.IntrinsicElements['div']['onClick'];
  style?: CSSProperties;
  width?: number;
  height?: number;
};

type IconProps = ImageIconProps | NamedIconProps;

/** ************************************************************************************************
 * NAMED VARIANT
 **************************************************************************************************/
export const supportedIconNames = Object.keys(
  FeatherIcon,
) as SupportedIconName[];

const NamedIcon = forwardRef((props: NamedIconProps, ref) => {
  const {
    size,
    name,
    sentiment = 'labelIco',
    nudge,
    color,
    onClick,
    ...otherProps
  } = props;

  const sizeAdjusted = size || defaultNamedIconSize;

  if (!FeatherIcon[name] || name === 'Internal/Empty') {
    if (!FeatherIcon[name] && name !== 'Internal/Empty') {
      console.error(`Trying to use unsupported icon name ${name}`);
    }
    return (
      <div
        style={{
          width: `${sizeAdjusted}px`,
          height: `${sizeAdjusted}px`,
          background: name === 'Internal/Empty' ? 'transparent' : 'red',
        }}
      />
    );
  }

  const sentimentColors = getSentimentColors(sentiment);
  const colorToUse =
    color ??
    (sentimentColors
      ? (sentimentColors.fg ?? sentimentColors.mg ?? sentimentColors.bg)
      : 'red');

  const Component = FeatherIcon[name];

  let style = otherProps?.style ?? {};

  if (nudge) {
    style = {
      ...style,
      position: 'relative',
      top: `${nudge}px`,
    };
  }

  if (onClick) {
    style = {
      ...style,
      cursor: 'pointer',
    };
  }

  // Intentional position of {...otherProps} so that color can be overwritten manually if needed
  return (
    <Component
      ref={ref}
      color={colorToUse}
      onClick={onClick}
      {...otherProps}
      style={style}
      size={sizeAdjusted}
    />
  );
});

/** ************************************************************************************************
 * IMAGE VARIANT
 **************************************************************************************************/
const ImageIcon = forwardRef<HTMLImageElement, ImageIconProps>((props, ref) => {
  const { size, src, alt, ...otherProps } = props;

  // Image icon
  const sizeAdjusted = size || '16px';

  return (
    <img
      ref={ref}
      {...otherProps}
      src={src}
      style={{
        ...(otherProps.style || {}),
        width: sizeAdjusted,
        height: sizeAdjusted,
        display: 'inline-block',
      }}
      alt={alt}
    />
  );
});

/** ************************************************************************************************
 * ROUTING COMPONENT
 **************************************************************************************************/
const Icon = forwardRef<HTMLImageElement, IconProps>((props, ref) => {
  const { name } = props;

  // Named icon takes precedence
  if (name) {
    if (name === 'Internal/Range') {
      return <BetweenIcon {...props} />;
    }

    // FIXME all these need to have `Internal/` prefix!
    if (name === 'Equals') {
      return <EqualsIcon {...props} />;
    }

    // FIXME all these need to have `Internal/` prefix!
    if (name === 'NotEquals') {
      return <NotEqualIcon {...props} />;
    }

    // FIXME all these need to have `Internal/` prefix!
    if (name === 'GreaterThan' || name === 'GreaterThanOrEqual') {
      return <GreaterThanIcon {...props} />;
    }

    // FIXME all these need to have `Internal/` prefix!
    if (name === 'LessThan' || name === 'LessThanOrEqual') {
      return <LessThanIcon {...props} />;
    }

    // FIXME all these need to have `Internal/` prefix!
    if (name === 'Array') {
      return <ArrayIcon {...props} />;
    }

    if (name === 'SystemAdjust') {
      return <SystemAdjustIcon {...props} />;
    }

    if (name === 'Internal/Bank') {
      return <BankIcon {...props} />;
    }

    if (name === 'Internal/OneOf') {
      return <OneOfIcon {...props} />;
    }

    if (name === 'Internal/NotOneOf') {
      return <NotOneOfIcon {...props} />;
    }

    if (name === 'Internal/Contains') {
      return <ContainsIcon {...props} />;
    }

    if (name === 'Internal/DoesNotContain') {
      return <DoesNotContainIcon {...props} />;
    }

    if (name === 'Internal/ChevronUpDown') {
      return <ChevronUpDownIcon {...props} />;
    }

    if (name === 'Internal/Wallet') {
      return <WalletIcon {...props} />;
    }

    if (name === 'Internal/Google') {
      return <GoogleIcon {...props} />;
    }

    if (name === 'Internal/GoogleDisabled') {
      return <GoogleDisabledIcon {...props} />;
    }

    if (name === 'Internal/ConditionIcon') {
      return <ConditionIcon {...props} />;
    }

    return <NamedIcon {...props} ref={ref} />;
  }

  return <ImageIcon {...props} ref={ref} />;
});

export function UnknownIcon({ size, ...otherProps }: ImageIconProps) {
  return (
    <ImageIcon
      {...otherProps}
      size={size}
      src='/icon/square/unknown.svg'
      alt='icon-unknown'
    />
  );
}

export function EqualsIcon({ size, color }: SvgProps) {
  return (
    <svg width={size} height={size} viewBox='0 0 24 24' fill={color}>
      <path d='M5 9C4.73478 9 4.48043 9.10536 4.29289 9.29289C4.10536 9.48043 4 9.73478 4 10C4 10.2652 4.10536 10.5196 4.29289 10.7071C4.48043 10.8946 4.73478 11 5 11H19C19.2652 11 19.5196 10.8946 19.7071 10.7071C19.8946 10.5196 20 10.2652 20 10C20 9.73478 19.8946 9.48043 19.7071 9.29289C19.5196 9.10536 19.2652 9 19 9H5Z' />
      <path d='M5 13C4.73478 13 4.48043 13.1054 4.29289 13.2929C4.10536 13.4804 4 13.7348 4 14C4 14.2652 4.10536 14.5196 4.29289 14.7071C4.48043 14.8946 4.73478 15 5 15H19C19.2652 15 19.5196 14.8946 19.7071 14.7071C19.8946 14.5196 20 14.2652 20 14C20 13.7348 19.8946 13.4804 19.7071 13.2929C19.5196 13.1054 19.2652 13 19 13H5Z' />
    </svg>
  );
}

export function NotEqualIcon({ size, color }: SvgProps) {
  return (
    <svg width={size} height={size} viewBox='0 0 24 24' fill={color}>
      <path d='M5.5 8.99951C5.23478 8.99951 4.98043 9.10487 4.79289 9.29241C4.60536 9.47994 4.5 9.7343 4.5 9.99951C4.5 10.2647 4.60536 10.5191 4.79289 10.7066C4.98043 10.8942 5.23478 10.9995 5.5 10.9995H19.5C19.7652 10.9995 20.0196 10.8942 20.2071 10.7066C20.3946 10.5191 20.5 10.2647 20.5 9.99951C20.5 9.7343 20.3946 9.47994 20.2071 9.29241C20.0196 9.10487 19.7652 8.99951 19.5 8.99951H5.5Z' />
      <path d='M5.5 12.9995C5.23478 12.9995 4.98043 13.1049 4.79289 13.2924C4.60536 13.4799 4.5 13.7343 4.5 13.9995C4.5 14.2647 4.60536 14.5191 4.79289 14.7066C4.98043 14.8942 5.23478 14.9995 5.5 14.9995H19.5C19.7652 14.9995 20.0196 14.8942 20.2071 14.7066C20.3946 14.5191 20.5 14.2647 20.5 13.9995C20.5 13.7343 20.3946 13.4799 20.2071 13.2924C20.0196 13.1049 19.7652 12.9995 19.5 12.9995H5.5Z' />
      <path d='M8 17.4907C7.86739 17.7203 7.83146 17.9933 7.9001 18.2495C7.96874 18.5057 8.13634 18.7241 8.36603 18.8567C8.59571 18.9893 8.86866 19.0252 9.12484 18.9566C9.38102 18.8879 9.59944 18.7203 9.73205 18.4907L16.7321 6.36631C16.8647 6.13662 16.9006 5.86367 16.832 5.60749C16.7633 5.35131 16.5957 5.13289 16.366 5.00028C16.1363 4.86767 15.8634 4.83174 15.6072 4.90038C15.351 4.96902 15.1326 5.13662 15 5.36631L8 17.4907Z' />
    </svg>
  );
}

export function GreaterThanIcon({ size, color }: SvgProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.24809 15L14.7481 10L7.24809 5.00002C7.1496 4.93436 7.065 4.84994 6.99914 4.75159C6.93327 4.65323 6.88742 4.54287 6.8642 4.4268C6.84099 4.31072 6.84087 4.19122 6.86384 4.07509C6.88681 3.95897 6.93243 3.84851 6.99809 3.75002C7.06375 3.65153 7.14817 3.56694 7.24652 3.50107C7.34487 3.4352 7.45524 3.38935 7.57131 3.36614C7.68739 3.34292 7.80689 3.3428 7.92302 3.36577C8.03914 3.38874 8.1496 3.43436 8.24809 3.50002L16.7501 9.16802C16.887 9.25934 16.9993 9.38306 17.077 9.5282C17.1547 9.67334 17.1953 9.83541 17.1953 10C17.1953 10.1646 17.1547 10.3267 17.077 10.4718C16.9993 10.617 16.887 10.7407 16.7501 10.832L8.24809 16.5C8.1496 16.5657 8.03914 16.6113 7.92302 16.6343C7.80689 16.6572 7.68739 16.6571 7.57131 16.6339C7.45524 16.6107 7.34487 16.5648 7.24652 16.499C7.14817 16.4331 7.06375 16.3485 6.99809 16.25C6.93243 16.1515 6.88681 16.0411 6.86384 15.9249C6.84087 15.8088 6.84099 15.6893 6.8642 15.5732C6.88742 15.4572 6.93327 15.3468 6.99914 15.2485C7.065 15.1501 7.1496 15.0657 7.24809 15Z'
        fill={color}
      />
      <path
        d='M7 20.998H17C17.2652 20.998 17.5196 20.8927 17.7071 20.7052C17.8946 20.5176 18 20.2633 18 19.998C18 19.7328 17.8946 19.4785 17.7071 19.2909C17.5196 19.1034 17.2652 18.998 17 18.998H7C6.73478 18.998 6.48043 19.1034 6.29289 19.2909C6.10536 19.4785 6 19.7328 6 19.998C6 20.2633 6.10536 20.5176 6.29289 20.7052C6.48043 20.8927 6.73478 20.998 7 20.998Z'
        fill={color}
      />
    </svg>
  );
}

export function LessThanIcon({ size, color }: SvgProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.7519 15L9.25191 10L16.7519 5.00002C16.8504 4.93436 16.935 4.84994 17.0009 4.75159C17.0667 4.65323 17.1126 4.54287 17.1358 4.4268C17.159 4.31072 17.1591 4.19122 17.1362 4.07509C17.1132 3.95897 17.0676 3.84851 17.0019 3.75002C16.9363 3.65153 16.8518 3.56694 16.7535 3.50107C16.6551 3.4352 16.5448 3.38935 16.4287 3.36614C16.3126 3.34292 16.1931 3.3428 16.077 3.36577C15.9609 3.38874 15.8504 3.43436 15.7519 3.50002L7.24991 9.16802C7.11295 9.25934 7.00066 9.38306 6.92299 9.5282C6.84532 9.67334 6.80469 9.83541 6.80469 10C6.80469 10.1646 6.84532 10.3267 6.92299 10.4718C7.00066 10.617 7.11295 10.7407 7.24991 10.832L15.7519 16.5C15.8504 16.5657 15.9609 16.6113 16.077 16.6343C16.1931 16.6572 16.3126 16.6571 16.4287 16.6339C16.5448 16.6107 16.6551 16.5648 16.7535 16.499C16.8518 16.4331 16.9363 16.3485 17.0019 16.25C17.0676 16.1515 17.1132 16.0411 17.1362 15.9249C17.1591 15.8088 17.159 15.6893 17.1358 15.5732C17.1126 15.4572 17.0667 15.3468 17.0009 15.2485C16.935 15.1501 16.8504 15.0657 16.7519 15Z'
        fill={color}
      />
      <path
        d='M17 20.998H7C6.73478 20.998 6.48043 20.8927 6.29289 20.7052C6.10536 20.5176 6 20.2633 6 19.998C6 19.7328 6.10536 19.4785 6.29289 19.2909C6.48043 19.1034 6.73478 18.998 7 18.998H17C17.2652 18.998 17.5196 19.1034 17.7071 19.2909C17.8946 19.4785 18 19.7328 18 19.998C18 20.2633 17.8946 20.5176 17.7071 20.7052C17.5196 20.8927 17.2652 20.998 17 20.998Z'
        fill={color}
      />
    </svg>
  );
}

export function OneOfIcon({ size, color }: SvgProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.18432 2.18436C2.33435 2.03433 2.53783 1.95005 2.75 1.95005H4.25C4.6366 1.95005 4.95 1.63665 4.95 1.25005C4.95 0.863449 4.6366 0.550049 4.25 0.550049H2.75C2.16653 0.550049 1.60695 0.781834 1.19437 1.19441C0.781788 1.60699 0.550003 2.16657 0.550003 2.75005V13.25C0.550003 13.8335 0.781788 14.3931 1.19437 14.8057C1.60695 15.2183 2.16653 15.45 2.75 15.45H4.25C4.6366 15.45 4.95 15.1366 4.95 14.75C4.95 14.3634 4.6366 14.05 4.25 14.05H2.75C2.53783 14.05 2.33435 13.9658 2.18432 13.8157C2.03429 13.6657 1.95 13.4622 1.95 13.25V2.75005C1.95 2.53788 2.03429 2.33439 2.18432 2.18436Z'
        fill={color}
      />
      <path
        d='M11.75 0.550049C11.3634 0.550049 11.05 0.863449 11.05 1.25005C11.05 1.63665 11.3634 1.95005 11.75 1.95005H13.25C13.4622 1.95005 13.6657 2.03433 13.8157 2.18436C13.9657 2.33439 14.05 2.53788 14.05 2.75005V13.25C14.05 13.4622 13.9657 13.6657 13.8157 13.8157C13.6657 13.9658 13.4622 14.05 13.25 14.05H11.75C11.3634 14.05 11.05 14.3634 11.05 14.75C11.05 15.1366 11.3634 15.45 11.75 15.45H13.25C13.8335 15.45 14.3931 15.2183 14.8056 14.8057C15.2182 14.3931 15.45 13.8335 15.45 13.25V2.75005C15.45 2.16657 15.2182 1.60699 14.8056 1.19441C14.3931 0.781834 13.8335 0.550049 13.25 0.550049H11.75Z'
        fill={color}
      />
    </svg>
  );
}

export function NotOneOfIcon({ size, color }: SvgProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.18436 2.18412C2.33439 2.03409 2.53788 1.9498 2.75005 1.9498H4.25005C4.63665 1.9498 4.95005 1.6364 4.95005 1.2498C4.95005 0.863205 4.63665 0.549805 4.25005 0.549805H2.75005C2.16657 0.549805 1.60699 0.781589 1.19441 1.19417C0.781834 1.60675 0.550049 2.16633 0.550049 2.7498V13.2498C0.550049 13.8333 0.781834 14.3929 1.19441 14.8054C1.60699 15.218 2.16657 15.4498 2.75005 15.4498H4.25005C4.63665 15.4498 4.95005 15.1364 4.95005 14.7498C4.95005 14.3632 4.63665 14.0498 4.25005 14.0498H2.75005C2.53788 14.0498 2.33439 13.9655 2.18436 13.8155C2.03433 13.6655 1.95005 13.462 1.95005 13.2498V2.7498C1.95005 2.53763 2.03433 2.33415 2.18436 2.18412Z'
        fill={color}
      />
      <path
        d='M11.75 0.549805C11.3634 0.549805 11.05 0.863205 11.05 1.2498C11.05 1.6364 11.3634 1.9498 11.75 1.9498H13.25C13.4622 1.9498 13.6657 2.03409 13.8157 2.18412C13.9658 2.33415 14.05 2.53763 14.05 2.7498V13.2498C14.05 13.462 13.9658 13.6655 13.8157 13.8155C13.6657 13.9655 13.4622 14.0498 13.25 14.0498H11.75C11.3634 14.0498 11.05 14.3632 11.05 14.7498C11.05 15.1364 11.3634 15.4498 11.75 15.4498H13.25C13.8335 15.4498 14.3931 15.218 14.8057 14.8054C15.2183 14.3929 15.45 13.8333 15.45 13.2498V2.7498C15.45 2.16633 15.2183 1.60675 14.8057 1.19417C14.3931 0.78159 13.8335 0.549805 13.25 0.549805H11.75Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.4243 5.42409C11.6586 5.18978 11.6586 4.80988 11.4243 4.57556C11.19 4.34125 10.8101 4.34125 10.5758 4.57556L8.00005 7.1513L5.42431 4.57556C5.19 4.34125 4.8101 4.34125 4.57579 4.57556C4.34147 4.80988 4.34147 5.18978 4.57579 5.42409L7.15152 7.99983L4.57579 10.5756C4.34147 10.8099 4.34147 11.1898 4.57579 11.4241C4.8101 11.6584 5.19 11.6584 5.42431 11.4241L8.00005 8.84836L10.5758 11.4241C10.8101 11.6584 11.19 11.6584 11.4243 11.4241C11.6586 11.1898 11.6586 10.8099 11.4243 10.5756L8.84858 7.99983L11.4243 5.42409Z'
        fill={color}
      />
    </svg>
  );
}

export function ContainsIcon({ size, color }: SvgProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 12 7'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.4515 2.099L1.45153 2.09898C1.86073 1.86315 2.24846 1.63969 2.76569 1.5047C3.40073 1.33896 4.24171 1.31965 5.42439 1.82652C7.23273 2.60153 8.50528 2.78532 9.44617 2.62015C10.4302 2.4474 10.9388 1.91228 11.233 1.56683C11.4837 1.27252 11.4483 0.830715 11.154 0.580044C10.8597 0.329373 10.4179 0.364757 10.1672 0.659075C9.96144 0.900689 9.72003 1.15066 9.2041 1.24123C8.645 1.33938 7.66755 1.26473 5.97588 0.539721C4.52865 -0.0805271 3.36963 -0.0998173 2.41215 0.150075C1.69167 0.33811 1.08412 0.690624 0.657958 0.937894L0.657949 0.9379C0.549843 1.00063 0.453409 1.05658 0.369765 1.10136C0.0289317 1.28381 -0.0994562 1.70803 0.0830032 2.04886C0.265462 2.38969 0.689675 2.51808 1.03051 2.33562C1.17692 2.25724 1.3154 2.17743 1.4515 2.099ZM1.45149 6.09901L1.45153 6.09898C1.86073 5.86315 2.24846 5.63969 2.76569 5.5047C3.40073 5.33896 4.24171 5.31965 5.42439 5.82652C7.23273 6.60153 8.50528 6.78532 9.44617 6.62015C10.4302 6.4474 10.9388 5.91228 11.233 5.56683C11.4837 5.27252 11.4483 4.83071 11.154 4.58004C10.8597 4.32937 10.4179 4.36476 10.1672 4.65908C9.96144 4.90069 9.72003 5.15066 9.2041 5.24123C8.645 5.33938 7.66755 5.26473 5.97588 4.53972C4.52865 3.91947 3.36963 3.90018 2.41215 4.15007C1.69167 4.33811 1.08412 4.69062 0.657958 4.93789L0.657925 4.93791C0.549829 5.00063 0.453403 5.05658 0.369765 5.10136C0.0289317 5.28381 -0.0994562 5.70803 0.0830032 6.04886C0.265462 6.3897 0.689675 6.51808 1.03051 6.33562C1.17692 6.25725 1.31539 6.17744 1.45149 6.09901Z'
        fill={color}
      />
    </svg>
  );
}

export function DoesNotContainIcon({ size, color }: SvgProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 13 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.45158 5.09879C2.86078 4.86295 3.2485 4.6395 3.76574 4.5045C4.40077 4.33877 5.24176 4.31946 6.42444 4.82633C8.23278 5.60133 9.50533 5.78512 10.4462 5.61995C11.4303 5.4472 11.9389 4.91209 12.2331 4.56664C12.4838 4.27232 12.4484 3.83052 12.1541 3.57985C11.8597 3.32918 11.4179 3.36456 11.1673 3.65888C10.9615 3.90049 10.7201 4.15047 10.2042 4.24104C9.64505 4.33919 8.6676 4.26453 6.97593 3.53953C5.5287 2.91928 4.36968 2.89999 3.4122 3.14988C2.69172 3.33791 2.08417 3.69043 1.65801 3.9377C1.5499 4.00042 1.45346 4.05638 1.36981 4.10116C1.02898 4.28362 0.900592 4.70783 1.08305 5.04867C1.26551 5.3895 1.68972 5.51789 2.03056 5.33543C2.17697 5.25705 2.31548 5.17722 2.45158 5.09879Z'
        fill={color}
      />
      <path
        d='M2.45158 9.09879C2.86078 8.86295 3.2485 8.6395 3.76574 8.5045C4.40077 8.33877 5.24176 8.31946 6.42444 8.82633C8.23278 9.60133 9.50533 9.78512 10.4462 9.61995C11.4303 9.4472 11.9389 8.91209 12.2331 8.56664C12.4838 8.27232 12.4484 7.83052 12.1541 7.57985C11.8597 7.32918 11.4179 7.36456 11.1673 7.65888C10.9615 7.90049 10.7201 8.15047 10.2042 8.24104C9.64505 8.33919 8.6676 8.26453 6.97593 7.53953C5.5287 6.91928 4.36968 6.89999 3.4122 7.14988C2.69172 7.33791 2.08417 7.69043 1.65801 7.9377C1.54991 8.00042 1.45345 8.05639 1.36981 8.10116C1.02898 8.28362 0.900592 8.70783 1.08305 9.04867C1.26551 9.3895 1.68972 9.51789 2.03056 9.33543C2.17696 9.25705 2.31548 9.17722 2.45158 9.09879Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.495 0.50483C12.7684 0.778197 12.7684 1.22141 12.495 1.49478L1.99502 11.9948C1.72166 12.2681 1.27844 12.2681 1.00507 11.9948C0.731707 11.7214 0.731707 11.2782 1.00507 11.0048L11.5051 0.50483C11.7784 0.231463 12.2217 0.231463 12.495 0.50483Z'
        fill={color}
      />
    </svg>
  );
}

export function ArrayIcon({ size, color }: SvgProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.625 3H4.14706C3.51356 3 3 3.51356 3 4.14706V19.8529C3 20.4864 3.51356 21 4.14706 21H8.625'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
      />
      <path
        d='M15.375 3H19.8529C20.4864 3 21 3.51356 21 4.14706V19.8529C21 20.4864 20.4864 21 19.8529 21H15.375'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
      />
    </svg>
  );
}

type SvgProps = {
  size?: number;
  color?: string;
};

export function BetweenIcon({ size, color }: SvgProps) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
    >
      <path d='M4 4V20' stroke={color} strokeWidth='2' strokeLinecap='round' />
      <path d='M20 4V20' stroke={color} strokeWidth='2' strokeLinecap='round' />
      <circle cx='12' cy='12' r='4' fill={color} />
    </svg>
  );
}

export function BankIcon({ size, color }: SvgProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 21 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18.3333 9.02344C18.9669 9.02344 19.2315 8.24805 18.7168 7.89062L10.8848 2.46484C10.773 2.3868 10.6379 2.34473 10.4993 2.34473C10.3606 2.34473 10.2256 2.3868 10.1137 2.46484L2.28179 7.89062C1.76705 8.24609 2.0316 9.02344 2.66734 9.02344H3.93677V16.3281H2.46021C2.36998 16.3281 2.29615 16.3984 2.29615 16.4844V17.5C2.29615 17.5859 2.36998 17.6563 2.46021 17.6563H18.5383C18.6286 17.6563 18.7024 17.5859 18.7024 17.5V16.4844C18.7024 16.3984 18.6286 16.3281 18.5383 16.3281H17.0618V9.02344H18.3333ZM10.4993 3.8418L16.0589 7.69336H4.9396L10.4993 3.8418ZM5.41333 9.02344H7.81275V16.3281H5.41333V9.02344ZM9.28931 9.02344H11.6887V16.3281H9.28931V9.02344ZM15.5852 16.3281H13.1653V9.02344H15.5852V16.3281Z'
        fill={color}
      />
    </svg>
  );
}

export function ChevronUpDownIcon({ size, color }: SvgProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4 10L7.5 13.5L11 10'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11 5.5L7.5 2L4 5.5'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

export function WalletIcon({ size, color }: SvgProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M20 12V8H6C5.46957 8 4.96086 7.78929 4.58579 7.41421C4.21071 7.03914 4 6.53043 4 6C4 4.9 4.9 4 6 4H18V8'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4 6V18C4 19.1 4.9 20 6 20H20V16'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18 12C17.4696 12 16.9609 12.2107 16.5858 12.5858C16.2107 12.9609 16 13.4696 16 14C16 15.1 16.9 16 18 16H22V12H18Z'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

export function GoogleIcon({ size }: SvgProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.0255 8.42008C15.0255 7.92611 14.9858 7.43213 14.9063 6.94824H8.1582V9.7407H12.0242C11.8652 10.6379 11.3484 11.4444 10.5931 11.9485V13.7631H12.8987C14.2503 12.5029 15.0255 10.6379 15.0255 8.42008Z'
        fill='#4285F4'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.15787 15.5173C10.0859 15.5173 11.7158 14.8722 12.8984 13.7632L10.5927 11.9486C9.94675 12.3922 9.12187 12.6442 8.15787 12.6442C6.28948 12.6442 4.7093 11.3639 4.14282 9.65015H1.76758V11.5252C2.98004 13.9749 5.45466 15.5173 8.15787 15.5173Z'
        fill='#34A853'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.14297 9.65007C3.84482 8.75285 3.84482 7.77498 4.14297 6.86769V5.00269H1.76773C0.74409 7.04915 0.74409 9.46861 1.76773 11.5151L4.14297 9.65007Z'
        fill='#FBBC04'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.15786 3.87355C9.1815 3.85339 10.1654 4.24655 10.9008 4.96231L12.9481 2.8856C11.6462 1.65571 9.9368 0.980277 8.15786 1.00044C5.45466 1.00044 2.98004 2.55293 1.76758 5.00263L4.14282 6.87772C4.70929 5.15385 6.28947 3.87355 8.15786 3.87355Z'
        fill='#EA4335'
      />
    </svg>
  );
}

export function SystemAdjustIcon({
  size,
  onClick,
  className,
}: SvgProps & {
  onClick?: JSX.IntrinsicElements['div']['onClick'];
  className?: string;
}) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      onClick={onClick as any}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18 8.25H11.25'
        stroke='#757575'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.5 15.75H6.75'
        stroke='#757575'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.75 18C16.9926 18 18 16.9926 18 15.75C18 14.5074 16.9926 13.5 15.75 13.5C14.5074 13.5 13.5 14.5074 13.5 15.75C13.5 16.9926 14.5074 18 15.75 18Z'
        stroke='#757575'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.25 10.5C9.49264 10.5 10.5 9.49264 10.5 8.25C10.5 7.00736 9.49264 6 8.25 6C7.00736 6 6 7.00736 6 8.25C6 9.49264 7.00736 10.5 8.25 10.5Z'
        stroke='#757575'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

export function GoogleDisabledIcon({ size }: SvgProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 17 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.5255 8.42008C15.5255 7.92611 15.4858 7.43213 15.4063 6.94824H8.6582V9.7407H12.5242C12.3652 10.6379 11.8484 11.4444 11.0931 11.9485V13.7631H13.3987C14.7503 12.5029 15.5255 10.6379 15.5255 8.42008Z'
        fill={Theme.colorLabelIco}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.65787 15.5173C10.5859 15.5173 12.2158 14.8722 13.3984 13.7632L11.0927 11.9486C10.4467 12.3922 9.62187 12.6442 8.65787 12.6442C6.78948 12.6442 5.2093 11.3639 4.64282 9.65015H2.26758V11.5252C3.48004 13.9749 5.95466 15.5173 8.65787 15.5173Z'
        fill={Theme.colorLabelIco}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.64297 9.65007C4.34482 8.75285 4.34482 7.77498 4.64297 6.86769V5.00269H2.26773C1.24409 7.04915 1.24409 9.46861 2.26773 11.5151L4.64297 9.65007Z'
        fill={Theme.colorLabelIco}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.65786 3.87355C9.6815 3.85339 10.6654 4.24655 11.4008 4.96231L13.4481 2.8856C12.1462 1.65571 10.4368 0.980277 8.65786 1.00044C5.95466 1.00044 3.48004 2.55293 2.26758 5.00263L4.64282 6.87772C5.20929 5.15385 6.78947 3.87355 8.65786 3.87355Z'
        fill={Theme.colorLabelIco}
      />
    </svg>
  );
}

export function ConditionIcon({ size, color }: SvgProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 17 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.43359 3.93335V9.93335'
        stroke={color}
        strokeWidth='1.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.4336 6.93335C12.262 6.93335 12.9336 6.26178 12.9336 5.43335C12.9336 4.60492 12.262 3.93335 11.4336 3.93335C10.6052 3.93335 9.93359 4.60492 9.93359 5.43335C9.93359 6.26178 10.6052 6.93335 11.4336 6.93335Z'
        stroke={color}
        strokeWidth='1.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.43359 12.9333C6.26202 12.9333 6.93359 12.2618 6.93359 11.4333C6.93359 10.6049 6.26202 9.93335 5.43359 9.93335C4.60517 9.93335 3.93359 10.6049 3.93359 11.4333C3.93359 12.2618 4.60517 12.9333 5.43359 12.9333Z'
        stroke={color}
        strokeWidth='1.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.4336 6.93335C11.4336 8.12682 10.9595 9.27142 10.1156 10.1153C9.27166 10.9592 8.12707 11.4333 6.93359 11.4333'
        stroke={color}
        strokeWidth='1.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

///////////////////////////////////////////////////////////////////////////////////////////////////

export default Icon;
