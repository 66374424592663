import { create } from 'zustand';

import { ConnectionDefinition } from 'SRC/fetch/api/integrations/definitions';
import { ID_PREFIXES } from 'SRC/hooks/useConnectionsInfo/config/mappings';
import { ConnectionDataMapped } from 'SRC/hooks/useConnectionsInfo/config/types';
import { formatConnections } from 'SRC/hooks/useConnectionsInfo/utils/formatConnections';

interface ConnectionsState {
  connectionList: ConnectionDataMapped[];
  connectionsById: Map<string, ConnectionDataMapped>;
  setData: (initialData: ConnectionDefinition[]) => void;
  getById: (id: string | null | undefined) => ConnectionDataMapped | null;
  getIsBetaConnection: (id: string | null) => boolean;
}

export const useConnectionsStore = create<ConnectionsState>((set, get) => ({
  connectionList: [],
  connectionsById: new Map(),

  setData: (newData) => {
    const { connectionList, connectionsById } = formatConnections(newData);
    set({ connectionList, connectionsById });
  },

  getById: (id) => {
    if (!id) {
      return null;
    }

    const uppercaseId = id.toUpperCase();
    const connectionsById = get().connectionsById;

    // Find a prefix that matches anywhere in the uppercaseId using regex
    const foundPrefix = ID_PREFIXES.find((prefix) => {
      const regex = new RegExp(prefix); // Create a regex object for the prefix
      return regex.test(uppercaseId); // Test if uppercaseId contains the prefix
    });

    if (foundPrefix) {
      return connectionsById.get(foundPrefix) || null;
    }

    return connectionsById.get(uppercaseId) || null;
  },
  getIsBetaConnection: (id: string | null) => {
    return get().getById(id)?.isBeta || false;
  },
}));
