import { Checkbox, Field, Label } from '@primer-io/goat';
import { Controller } from 'react-hook-form';
import styled from 'styled-components';

import Errors from 'LEGACY/form/Errors';

const RootStyled = styled.div`
  flex: 1;
`;

type OptionsProps = {
  name: string;
  label?: any;
  required?: boolean | string;
  disabled?: boolean;
  readOnly?: boolean;
  style?: any;
  defaultValue?: any;
  onChange: (newValue: boolean) => void;
};

export default function FormCheckbox(props: OptionsProps) {
  const {
    name,
    required,
    disabled,
    style,
    label,
    defaultValue,
    onChange: onChangeProp,
  } = props;

  let validate = {};
  if (required) {
    validate = {
      required: (value) => {
        return value.length ? true : 'Please check this checkbox';
      },
    };
  }

  return (
    <Controller
      defaultValue={defaultValue}
      name={name}
      rules={{ validate }}
      render={({
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        field: { onChange, value, ref, ...restField },
        fieldState: { error },
        formState: { isSubmitting },
      }) => {
        const onClick = isSubmitting
          ? undefined
          : () => {
              const newValue = !value;
              onChangeProp?.(newValue);
              onChange(newValue);
            };

        return (
          <RootStyled>
            <Field>
              <Label>
                <Checkbox
                  value={value}
                  disabled={disabled}
                  checked={value}
                  onChange={onClick}
                  style={style}
                  {...restField}
                />
                {label}
              </Label>
              {error && <Errors errorMessages={[error.message!]} />}
            </Field>
          </RootStyled>
        );
      }}
    />
  );
}
